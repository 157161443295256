// src/jwtHelper.ts
import jwt_decode from "jwt-decode";
var JwtHelper = class {
};
JwtHelper.getUserInfo = (accessToken) => {
  const decoded = jwt_decode(accessToken);
  return decoded;
};
JwtHelper.getInterviewId = (accessToken) => {
  var _a;
  const decoded = jwt_decode(accessToken);
  return (_a = decoded["https://hasura.io/jwt/claims"]) == null ? void 0 : _a["x-hasura-interview-id"];
};
JwtHelper.isTokenValid = (accessToken) => {
  if (!accessToken)
    return false;
  const decoded = jwt_decode(accessToken);
  return !!(decoded.exp && decoded.exp > Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3));
};
JwtHelper.getCandidate = (accessToken) => {
  var _a, _b, _c, _d;
  const decoded = jwt_decode(accessToken);
  const candidate = {
    email: (_a = decoded.candidate) == null ? void 0 : _a.email,
    fullname: (_b = decoded.candidate) == null ? void 0 : _b.fullname,
    id: (_c = decoded["https://hasura.io/jwt/claims"]) == null ? void 0 : _c["x-hasura-candidate-id"],
    submissionId: (_d = decoded["https://hasura.io/jwt/claims"]) == null ? void 0 : _d["x-hasura-recruitment-form-submission-id"]
  };
  return candidate;
};
export {
  JwtHelper
};
