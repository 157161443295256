import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsers, useGetUsersCount } from "../../../service/Users/getUser";

import { ISolidButton } from "../../../components/SolidButton/SolidButton.type";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import { NSContextMenuType } from "../../../components/ContextMenu/ContextMenu.type";
import { NSEmptyViewType } from "../../../components/EmptyView/EmptyView.type";
// eslint-disable-next-line import/no-named-as-default
import i18n from "../../../library/i18next";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";
import User from "../../../components/User/User";
import dayjs from "dayjs";
import { NSTableListStyle } from "../../../components/TableList/TableList.style";
import ContextMenuHOC from "../../../components/ContextMenuHOC/ContextMenuHOC";
import { User_Role_Name_Enum } from "../../../gql/graphql";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import HistoryTooltip from "../../../components/HistoryTooltip/HistoryTooltip";
import { NSTeamStyle } from "../Team.style";

const useTeamVm = () => {
  const [searchString, setSearchString] = useState("");

  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });

  const { data: userListItems, rest } = useGetUsers(
    pagination?.limit,
    pagination?.start,
    `%${searchString}%`
  );
  const { data: usersCountData } = useGetUsersCount();

  const usersCount = useMemo(
    () => usersCountData?.user_aggregate.aggregate?.count ?? 0,
    [usersCountData]
  );

  const isLoading = useMemo(() => rest.isLoading, [rest.isLoading]);
  const contextMenuItems: NSContextMenuType.IContextMenuItem[] = [
    {
      iconName: "Download",
      label: i18n.t("general.projects"),
    },
  ];
  const headerList = [
    i18n.t("team.user"),
    i18n.t("team.userRole"),
    i18n.t("general.department"),
    i18n.t("general.createdAt"),
  ];

  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: searchString,
  };
  const navigate = useNavigate();
  const breadcrumb: NSBreadcrumbType.IBreadcrumb = {
    pageName: i18n.t("general.team"),
  };

  const dashboardHeaderButton: ISolidButton = {
    label: i18n.t("team.inviteUser"),
    onClick: () => navigate("invite-member"),
    variant: "label-w-icon-prefix",
    prefixIcon: "UserAdd",
  };

  const onClickEmptyViewButton = useCallback(() => {
    setSearchString("");
  }, []);

  const emptyView: NSEmptyViewType.IEmptyView = {
    buttonLabel: i18n.t("general.tryAgain"),
    errorMessage: i18n.t("general.emptyViewErrorMessage"),
    onClick: onClickEmptyViewButton,
  };

  const userRoleOptions = [
    {
      id: User_Role_Name_Enum.Companyadmin,
      label: i18n.t("forms.companyAdmin"),
    },
    {
      id: User_Role_Name_Enum.Departmentadmin,
      label: i18n.t("forms.departmentAdmin"),
    },
    {
      id: User_Role_Name_Enum.Departmentviewer,
      label: i18n.t("forms.departmentViewer"),
    },
  ];

  const mapUserRoles = (
    data: { user_role_name: string; user_id: string }[]
  ) => {
    return (
      (data
        .map((user) => {
          const role = userRoleOptions.find(
            (roleOption) => roleOption.id === user.user_role_name
          );
          return role ? { id: user.user_id, label: role.label } : null;
        })
        .filter(Boolean) as NSDropdown.IDropdownItem[]) ?? []
    );
  };

  const capitalize = (str: string) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const tableItem = useMemo(() => {
    const items = userListItems?.user.map((userItem, index) => ({
      id: index.toString(),
      informations: [
        {
          id: "1",
          information: (
            <User
              avatarUrl={userItem.avatar ?? ""}
              name={`${capitalize(userItem.name)} ${capitalize(
                userItem.surname
              )}`}
              email={userItem.email}
              isAvatarCircle
            />
          ),
        },
        {
          id: "3",
          information: mapUserRoles(userItem.user_roles).map((x) => x.label),
        },
        {
          id: "4",
          information:
            userItem.client_users.length === 0 ||
            userItem.client_users.filter((i) => i.client?.client_name)
              .length === 0 ? null : (
              <HistoryTooltip
                id={`departmentsTooltip${userItem.id}`}
                label={`${
                  userItem.client_users.filter((i) => i.client?.client_name)
                    .length
                } ${i18n.t("general.department")}`}
              >
                <NSTeamStyle.TooltipContainer>
                  {userItem.client_users
                    .filter((i) => i.client?.client_name)
                    .map((i, index) => (
                      <NSTeamStyle.DepartmentItem key={index}>
                        <NSTeamStyle.DepartmentName>
                          {i.client.client_name}
                        </NSTeamStyle.DepartmentName>
                      </NSTeamStyle.DepartmentItem>
                    ))}
                </NSTeamStyle.TooltipContainer>
              </HistoryTooltip>
            ),
        },
        {
          id: "5",
          information: dayjs(userItem.created_at).format(
            "DD MMM YYYY [at] hh:mm"
          ),
        },
        {
          id: "6",
          information: (
            <NSTableListStyle.ContextMenuColumn>
              {contextMenuItems && contextMenuItems.length > 0 && (
                <ContextMenuHOC
                  id={userItem.id}
                  type="withIcon"
                  items={contextMenuItems}
                />
              )}
            </NSTableListStyle.ContextMenuColumn>
          ),
        },
      ],
    }));

    return items;
  }, [userListItems?.user]);

  return {
    contextMenuItems,
    headerList,
    dashboardHeaderButton,
    searchInput,
    emptyView,
    tableItem,
    breadcrumb,
    userListItems,
    isLoading,
    usersCount,
    pagination,
    setPagination,
  };
};

export default useTeamVm;
