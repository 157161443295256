import "../public/fonts.css";
import "react-datepicker/dist/react-datepicker.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "./context/AuthContext/AuthContext";
import GlobalStyle from "./style/globalStyle";
import { defaultTheme } from "./style/theme";
import { router } from "./routes/router";
import useAuthService from "./service/authService/useAuthService";
import "ui/dist/index.css";
import { PermissionProvider } from "./context/PermissionContext/PermissionContext";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <QueryClientProvider client={client}>
        <GlobalStyle />
        <AuthProvider authService={useAuthService}>
          <PermissionProvider>
            <RouterProvider router={router} />
          </PermissionProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
