import Modal2 from "../../../components/Modal/Modal2";
import { NSTeamStyle } from "../Team.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextInput from "../../../components/TextInput/TextInput";
import i18n from "../../../library/i18next";
import useInviteMemberVm from "./Invite-MemberVM";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { Controller } from "react-hook-form";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { RoleBasedGuard } from "../../../components/RoleBasedGuard/RoleBasedGuard";
import { PermissionsEnum } from "../../../permissions/roles";
import CustomTooltip from "../../../components/Tooltip/Tooltip";

function InviteMember() {
  const inviteMemberVM = useInviteMemberVm();

  const roleDescriptions = [
    {
      roleName: i18n.t("forms.companyAdmin"),
      title: i18n.t("team.companyAdminTooltipTitle"),
      details: [
        i18n.t("team.companyAdminTooltipDescItem1"),
        i18n.t("team.companyAdminTooltipDescItem2"),
        i18n.t("team.companyAdminTooltipDescItem3"),
      ],
    },
    {
      roleName: i18n.t("forms.departmentAdmin"),
      title: i18n.t("team.departmentAdminTooltipTitle"),
      details: [
        i18n.t("team.departmentAdminTooltipDescItem1"),
        i18n.t("team.departmentAdminTooltipDescItem2"),
        i18n.t("team.departmentAdminTooltipDescItem3"),
      ],
    },
    {
      roleName: i18n.t("forms.departmentViewer"),
      title: i18n.t("team.departmentViewerTooltipTitle"),
      details: [
        i18n.t("team.departmentViewerTooltipDescItem1"),
        i18n.t("team.departmentViewerTooltipDescItem2"),
        i18n.t("team.departmentViewerTooltipDescItem3"),
      ],
    },
  ];

  return (
    <Modal2 onClose={inviteMemberVM.closeHandler} isFullWidth={false}>
      <NSTeamStyle.ModalContainer>
        <NSTeamStyle.ModalTitle>
          {i18n.t("team.inviteUsertoTeam")}
        </NSTeamStyle.ModalTitle>
        <NSTeamStyle.ModalLabel>
          {i18n.t("team.inviteUsertoTeamDescription")}
        </NSTeamStyle.ModalLabel>
        <NSTeamStyle.InviteMemberForm onSubmit={inviteMemberVM.handleSubmit()}>
          <Controller
            name="memberName"
            control={inviteMemberVM.control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="memberName"
                placeholder={i18n.t("forms.name")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="memberSurname"
            control={inviteMemberVM.control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="memberSurname"
                placeholder={i18n.t("forms.surname")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="emailAddress"
            control={inviteMemberVM.control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="emailAddress"
                placeholder={i18n.t("forms.emailAddress")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="department"
            control={inviteMemberVM.control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  placeholder={i18n.t("forms.department")}
                  options={inviteMemberVM.departmentOptions ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  selectedDropdownItem={defaultValues?.department}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <RoleBasedGuard permissionKey={PermissionsEnum.CREATE_DEPARTMENT}>
            <NSTeamStyle.LinkButtonContainer>
              <LinkButton
                type="button"
                label={i18n.t("general.createNewDepartment")}
                onClick={inviteMemberVM.handleClickCreateDepartment}
              />
            </NSTeamStyle.LinkButtonContainer>
          </RoleBasedGuard>
          <Controller
            name="userRole"
            control={inviteMemberVM.control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  placeholder={i18n.t("team.userRole")}
                  options={inviteMemberVM.userRoleOptions ?? []}
                  selectedDropdownItem={defaultValues?.userRole}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <NSTeamStyle.TooltipContainer>
            <CustomTooltip id="userRolesTooltip" clickable>
              {roleDescriptions.map((role, index) => (
                <NSTeamStyle.TooltipContent key={index}>
                  <NSTeamStyle.TooltipRoleName>
                    {role.roleName}
                  </NSTeamStyle.TooltipRoleName>
                  <NSTeamStyle.TooltipTitle>
                    {role.title}
                  </NSTeamStyle.TooltipTitle>
                  <NSTeamStyle.TooltipList>
                    {role.details.map((detail, idx) => (
                      <NSTeamStyle.TooltipListItem key={idx}>
                        {detail}
                      </NSTeamStyle.TooltipListItem>
                    ))}
                  </NSTeamStyle.TooltipList>
                </NSTeamStyle.TooltipContent>
              ))}
            </CustomTooltip>
          </NSTeamStyle.TooltipContainer>
          <NSTeamStyle.RoleDescriptionText
            data-tooltip-id="userRolesTooltip"
            className="tooltip-trigger"
          >
            {i18n.t("team.tooltipText")}
          </NSTeamStyle.RoleDescriptionText>
          <SolidButton
            type="submit"
            label={i18n.t("team.addNewUser")}
            fullWidth
          />
        </NSTeamStyle.InviteMemberForm>
      </NSTeamStyle.ModalContainer>
    </Modal2>
  );
}

export default InviteMember;
