import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import { NSTableListType } from "../../../../components/TableList/TableList.type";
import {
  GetRecruitmentFormScoresByProjectIdDocument,
  GetVideoInterviewScoresByProjectIdDocument,
  Order_By,
  SearchDocument,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import { useGraphQLQuery } from "../../../../hooks/useGraphQL";
import HeatmapBadge from "./Badge/HeatmapBadge";
import { NSHeatmapStyle } from "./Heatmap.style";
import i18n from "../../../../library/i18next";
import { useCallback, useMemo, useState } from "react";
import CustomTooltip from "../../../../components/Tooltip/Tooltip";
import {
  DEFAULT_PAGINATION_OPTIONS,
  PAGINATION_DEFAULT_LIMIT,
} from "../../../../utils/globalConstants";
import candidateStatusHelper from "../../../../utils/candidateStatusHelper";
import dayjs from "dayjs";
import { excelExporter } from "../../../../utils/excelExporter";
import { getLevel } from "../../../../utils/getLevelHelper";
import { RoleBasedGuard } from "../../../../components/RoleBasedGuard/RoleBasedGuard";
import { PermissionsEnum } from "../../../../permissions/roles";
import capitalizeFirstLetterHelper from "../../../../utils/capitalizeFirstLetterHelper";
import { downloadInterviewReport } from "../../../../utils/downloadHelper";
import useGenerateInterviewReport from "../../../../service/VideoInterview/generateInterviewReport";
import { NSCandidateListType } from "../Candidate-List/Candidate-List.type";
import StatusBadge from "../../../../components/StatusBadge/StatusBadge";
import { NSStatusBadgeType } from "../../../../components/StatusBadge/StatusBadge.type";
import { NSDropdown } from "../../../../components/Dropdown/Dropdown.type";
import ContextMenuHOC from "../../../../components/ContextMenuHOC/ContextMenuHOC";
import useGenerateBatchInterviewReports from "../../../../service/VideoInterview/generateBatchInterviewReports";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";

enum InterviewTypeEnum {
  Competency = 1,
  EnglishTest = 2,
  RecruitmentForm = 3,
}
enum InterviewSortEnum {
  ASC = 1,
  DESC = 2,
}
const interviewTypeOptions = [
  {
    id: InterviewTypeEnum.Competency,
    label: i18n.t("projects.competencyInterview"),
  },
  {
    id: InterviewTypeEnum.EnglishTest,
    label: i18n.t("projects.englishTest"),
  },
  {
    id: InterviewTypeEnum.RecruitmentForm,
    label: i18n.t("projects.recruitmentForm"),
  },
];

const orderOptions = [
  {
    id: InterviewSortEnum.ASC,
    label: i18n.t("projects.scoreAsc"),
  },
  {
    id: InterviewSortEnum.DESC,
    label: i18n.t("projects.scoreDesc"),
  },
];

const useHeatmapVM = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const selectedInterviewType =
    Number(searchParams.get("selectedInterviewType")) ||
    InterviewTypeEnum.Competency;
  const selectedOrder =
    Number(searchParams.get("selectedOrder")) || InterviewSortEnum.ASC;

  const [searchString, setSearchString] = useState<string>("");

  const start = searchParams.get("start");
  const limit = searchParams.get("limit");
  const startValue = parseInt(start!) || 0;
  const limitValue = parseInt(limit!) || PAGINATION_DEFAULT_LIMIT;
  const isValidLimit = DEFAULT_PAGINATION_OPTIONS.some(
    (option) => parseInt(option.label) === limitValue
  );
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({
    start: startValue,
    limit: isValidLimit ? limitValue : PAGINATION_DEFAULT_LIMIT,
  });
  const navigate = useNavigate();
  const { mutateAsync: generateInterviewReport } = useGenerateInterviewReport();
  const { mutateAsync: generateBatchInterviewReports } =
    useGenerateBatchInterviewReports();

  const { data: searchData } = useGraphQLQuery(
    SearchDocument,
    {
      enabled: !!searchString,
    },
    {
      index: "client_candidate",
      query: searchString ?? "",
      limit: pagination.limit,
      offset: pagination.start,
    }
  );

  const clientCandidateIds =
    searchData?.search?.clientCandidate?.map((item) => item?.id) || [];

  const listFilter = {
    video_interview_type: {
      _eq:
        selectedInterviewType === InterviewTypeEnum.Competency
          ? Video_Interview_Type_Enum.CompetencyAssessment
          : Video_Interview_Type_Enum.EnglishAssessment,
    },
    video_interview_statuses: {
      status: { _eq: Video_Interview_Status_Enum.Scored },
    },
    client_candidate_project: {
      _and: [
        { project_id: { _eq: projectId } },
        searchString
          ? {
              client_candidate_id: { _in: clientCandidateIds },
            }
          : {},
      ],
    },
  };

  const {
    data: videoInterviewScores,
    isLoading: isVideoInterviewScoresLoading,
  } = useGraphQLQuery(
    GetVideoInterviewScoresByProjectIdDocument,
    {
      enabled: selectedInterviewType !== InterviewTypeEnum.RecruitmentForm,
    },
    {
      projectId,
      limit: pagination.limit,
      offset: pagination.start,
      orderBy:
        selectedOrder === InterviewSortEnum.ASC ? Order_By.Asc : Order_By.Desc,
      listFilter: listFilter,
    }
  );

  const {
    data: recruitmentFormScores,
    isLoading: isRecruitmentFormScoresLoading,
  } = useGraphQLQuery(
    GetRecruitmentFormScoresByProjectIdDocument,
    {
      enabled: selectedInterviewType === InterviewTypeEnum.RecruitmentForm,
    },
    {
      projectId,
      limit: pagination.limit,
      offset: pagination.start,
      orderBy:
        selectedOrder === InterviewSortEnum.ASC ? Order_By.Asc : Order_By.Desc,
    }
  );

  const heatmapData =
    selectedInterviewType !== InterviewTypeEnum.RecruitmentForm
      ? videoInterviewScores?.video_interview
          ?.filter((interview) => interview.score !== null)
          .map((interview, index) => {
            const candidateName = `${interview.client_candidate_project.client_candidate.name} ${interview.client_candidate_project.client_candidate.surname}`;

            const firstInterviewScores =
              videoInterviewScores?.video_interview?.[0]
                ?.video_interview_criterion_scores ?? [];

            const referenceOrder = firstInterviewScores.map(
              (i) => i.interview_criterion.name
            );

            const reviewMetrics =
              selectedInterviewType === InterviewTypeEnum.Competency
                ? interview.video_interview_questions
                    .flatMap((question) => {
                      const validMetrics =
                        question.review_metric_question.review_metric.review_metric_questions.filter(
                          (rmq) =>
                            rmq.interview_template_review_metric_questions.some(
                              (itrmq) =>
                                itrmq.weight > 0 &&
                                itrmq.interview_template_id ===
                                  interview.interview_template_id
                            )
                        );

                      return validMetrics.map((validMetric) => {
                        const matchedResponse =
                          question.review_metric_question.review_metric_question_responses.find(
                            (response) =>
                              question.video_interview_score.some(
                                (score) =>
                                  score.review_metric_question_response_id ===
                                    response.id && !score.is_ai_score
                              )
                          ) ||
                          question.review_metric_question.review_metric_question_responses.find(
                            (response) =>
                              question.video_interview_score.some(
                                (score) =>
                                  score.review_metric_question_response_id ===
                                  response.id
                              )
                          );

                        const description =
                          question.review_metric_question.review_metric.review_metric_translations.find(
                            (item) =>
                              item.language ===
                              question.review_metric_question.language
                          )?.translation;
                        const score = matchedResponse
                          ? matchedResponse.score
                          : null;
                        const metricId = validMetric.id;

                        return description
                          ? {
                              description,
                              score,
                              metricId,
                            }
                          : null;
                      });
                    })
                    .filter((metric) => metric !== null)
                : interview.video_interview_criterion_scores
                    .map((i, index) => ({
                      description: i.interview_criterion.name,
                      score: i.score,
                      metricId: index,
                    }))
                    .sort(
                      (a, b) =>
                        referenceOrder.indexOf(a.description) -
                        referenceOrder.indexOf(b.description)
                    );
            return {
              id: index,
              interviewId: interview.id,
              candidate: capitalizeFirstLetterHelper(candidateName),
              score: interview.score,
              rank: interview.rank,
              reviewMetrics: reviewMetrics,
              clientCandidateId:
                interview.client_candidate_project.client_candidate.id,
              passingScore: 0,
              completedAt: null,
            };
          })
      : recruitmentFormScores?.recruitment_form_submission.map((form) => {
          const candidateName = `${form.client_candidate_project.client_candidate.name} ${form.client_candidate_project.client_candidate.surname}`;

          return {
            id: form.id,
            candidate: capitalizeFirstLetterHelper(candidateName),
            score: form.score,
            clientCandidateId:
              form.client_candidate_project.client_candidate.id,
            passingScore: form.recruitment_form.passing_score,
            rank: form.rank,
            reviewMetrics: [],
            interviewId: null,
            completedAt: dayjs(form.completed_at).format("DD.MM.YYYY[,] HH:mm"),
          };
        }) || [];

  const handleDownloadReportButton = async (id: string) => {
    return await downloadInterviewReport(id, generateInterviewReport, 10, 3000);
  };

  const allReviewMetrics =
    selectedInterviewType !== InterviewTypeEnum.RecruitmentForm
      ? heatmapData
          ?.flatMap((item) => item?.reviewMetrics || [])
          .filter(
            (metric, index, self) =>
              self.findIndex((m) => m?.description === metric?.description) ===
              index
          )
      : [];

  const handleDownloadRecruitmentFormListButton = async () => {
    const dataForExcel = heatmapData?.map((item) => {
      const score = item.score ?? 0;
      const status =
        score >= item.passingScore
          ? i18n.t("interview.passed")
          : i18n.t("interview.failed");

      return {
        [i18n.t("projects.candidateName")]: item.candidate,
        [i18n.t("projects.scoreText")]: score,
        [i18n.t("projects.status")]: status,
        [i18n.t("interview.completedAt")]: item.completedAt,
      };
    });

    if (dataForExcel && dataForExcel.length > 0) {
      const projectName = "heatmap-data";
      const date = dayjs().format("DD.MM.YYYY[,] HH:mm");

      excelExporter(dataForExcel, `hc-${projectName}-export-${date}.xlsx`);
    } else {
      console.log("No valid data available for download.");
    }
  };

  const handleDownloadBatchInterviewButton = async () => {
    try {
      await generateBatchInterviewReports({
        interviewType:
          selectedInterviewType === InterviewTypeEnum.Competency
            ? Video_Interview_Type_Enum.CompetencyAssessment
            : Video_Interview_Type_Enum.EnglishAssessment,
        projectId: projectId!,
      });

      toast(
        <ToastAlert
          description={i18n.t("projects.downloadBatchActionReportSuccess")}
          type="success"
        />,
        {
          id: "downloadBatchActionReportSuccess",
        }
      );
    } catch (error) {
      toast(
        <ToastAlert
          description={i18n.t("projects.downloadBatchActionReportError")}
          type="error"
        />,
        {
          id: "downloadBatchActionReportError",
        }
      );
    }
  };

  const handleDownloadListButton = async () => {
    const response = videoInterviewScores!;

    const allCandidatesInformationDataForExcel = response?.video_interview.map(
      (interview) => {
        const candidate = interview.client_candidate_project.client_candidate;

        const competencyAssessment =
          interview.video_interview_type ===
          Video_Interview_Type_Enum.CompetencyAssessment
            ? interview
            : null;

        const englishTest =
          interview.video_interview_type ===
          Video_Interview_Type_Enum.EnglishAssessment
            ? interview
            : null;

        const lastUpdateDate =
          interview.client_candidate_project.video_interviews[0]
            ?.video_interview_statuses[0]?.created_at ?? null;

        const reviewMetricsData = allReviewMetrics?.map((metric) => {
          const matchedMetric =
            selectedInterviewType === InterviewTypeEnum.Competency
              ? interview.video_interview_questions
                  .flatMap((question) => {
                    const validMetrics =
                      question.review_metric_question.review_metric.review_metric_questions.filter(
                        (rmq) =>
                          rmq.interview_template_review_metric_questions.some(
                            (itrmq) =>
                              itrmq.weight > 0 &&
                              itrmq.interview_template_id ===
                                interview.interview_template_id
                          )
                      );

                    return validMetrics.map(() => {
                      const matchedResponse =
                        question.review_metric_question.review_metric_question_responses.find(
                          (response) =>
                            question.video_interview_score.some(
                              (score) =>
                                score.review_metric_question_response_id ===
                                  response.id && !score.is_ai_score
                            )
                        ) ||
                        question.review_metric_question.review_metric_question_responses.find(
                          (response) =>
                            question.video_interview_score.some(
                              (score) =>
                                score.review_metric_question_response_id ===
                                response.id
                            )
                        );

                      const description =
                        question.review_metric_question.review_metric.review_metric_translations.find(
                          (item) =>
                            item.language ===
                            question.review_metric_question.language
                        )?.translation;
                      const score = matchedResponse
                        ? matchedResponse.score
                        : null;

                      return description &&
                        score !== null &&
                        score !== 0 &&
                        description === metric?.description
                        ? { description, score }
                        : null;
                    });
                  })
                  .find((m) => m !== null)
              : interview.video_interview_criterion_scores.find(
                  (i) => i.interview_criterion.name === metric?.description
                );

          return {
            description: metric?.description,
            score: matchedMetric
              ? selectedInterviewType === InterviewTypeEnum.Competency
                ? matchedMetric.score
                : matchedMetric.score
              : 0,
          };
        });

        return {
          name: candidate.name,
          surname: candidate.surname,
          email: candidate.email_address,
          mobileNumber: candidate.mobile_number,
          competencyLastStatus:
            competencyAssessment?.interview_last_status ?? null,
          competencyLastScore: competencyAssessment?.score ?? 0,
          englishTestLastStatus:
            englishTest?.interview_last_status ??
            Video_Interview_Status_Enum.NotSet,
          englishTestLastScore: getLevel(englishTest!?.score) ?? 0,
          candidateStatus:
            interview.client_candidate_project.candidate_resolution,
          score: interview.score ?? 0,
          lastUpdateDate,
          reviewMetricsData,
        };
      }
    );

    const dataForExcel = allCandidatesInformationDataForExcel
      ?.filter((item) => item.competencyLastScore || item.englishTestLastScore)
      .map((item) => {
        const competencyInterviewStatus =
          item.competencyLastStatus === Video_Interview_Status_Enum.Scored
            ? item.competencyLastScore
            : candidateStatusHelper(
                String(item.competencyLastStatus),
                String(item.competencyLastScore)
              ).label;

        const englishTestStatus =
          item.englishTestLastStatus === Video_Interview_Status_Enum.Scored
            ? item.englishTestLastScore
            : candidateStatusHelper(
                String(item.englishTestLastStatus),
                String(item.englishTestLastScore)
              ).label;

        let lastUpdateDate: string | null = null;

        if (item.lastUpdateDate) {
          const parsedDate = dayjs(item.lastUpdateDate);
          if (parsedDate.isValid()) {
            lastUpdateDate = parsedDate.format("DD.MM.YYYY[,] hh:mm");
          }
        }

        const baseData = {
          [i18n.t("projects.candidateName")]: `${item.name} ${item.surname}`,
          [i18n.t("projects.emailAddress")]: item.email,
          [i18n.t("projects.phoneNumber")]: item.mobileNumber,
          ...(item.competencyLastScore
            ? {
                [i18n.t("projects.competencyInterviewStatus")]:
                  competencyInterviewStatus,
              }
            : {}),
          ...(item.englishTestLastScore
            ? { [i18n.t("projects.englishTestStatus")]: englishTestStatus }
            : {}),
          [i18n.t("projects.lastUpdateDate")]: lastUpdateDate,
        };

        const reviewMetricsColumns: Record<string, string> = {};
        allReviewMetrics?.forEach((metric) => {
          const matchedMetric = item.reviewMetricsData?.find(
            (m) => m.description === metric?.description
          );
          reviewMetricsColumns[metric?.description!] = matchedMetric
            ? `%${matchedMetric.score}`
            : "0%";
        });

        return {
          ...baseData,
          ...reviewMetricsColumns,
        };
      });

    if (dataForExcel && dataForExcel.length > 0) {
      const projectName = videoInterviewScores?.video_interview
        .map(
          (interview) => interview.client_candidate_project.project.project_name
        )
        .filter(Boolean)[0];

      const date = dayjs().format("DD.MM.YYYY[,] HH:mm");

      excelExporter(dataForExcel, `hc-${projectName}-export-${date}.xlsx`);
    } else {
      console.log("No valid data available for download.");
    }
  };

  const headerItemList =
    selectedInterviewType !== InterviewTypeEnum.RecruitmentForm
      ? [
          i18n.t("projects.rank"),
          i18n.t("projects.candidateName"),
          i18n.t("projects.scoreText"),
          "",
          ...(allReviewMetrics?.map((metric, index) => (
            <NSHeatmapStyle.IconContainer
              key={index}
              data-tooltip-id={`metric-tooltip-${index}`}
              data-tooltip-content={metric?.description}
              data-tooltip-place="top"
            >
              {metric?.description}
            </NSHeatmapStyle.IconContainer>
          )) || []),
          <RoleBasedGuard permissionKey={PermissionsEnum.DOWNLOAD_HEATMAP_PDF}>
            <NSHeatmapStyle.DownloadListButtonContainer key="download-button">
              <ContextMenuHOC
                fixedMenuPosition="down"
                customButton={
                  <NSHeatmapStyle.DownloadListButton
                    size="small"
                    label={i18n.t("candidate.transactions")}
                  />
                }
                items={[
                  {
                    label: i18n.t("candidate.downloadList"),
                    onClick: handleDownloadListButton,
                  },
                  {
                    label: i18n.t("projects.downloadAllReports"),
                    onClick: handleDownloadBatchInterviewButton,
                  },
                ]}
              />
            </NSHeatmapStyle.DownloadListButtonContainer>
          </RoleBasedGuard>,
        ]
      : [
          i18n.t("projects.rank"),
          i18n.t("projects.candidateName"),
          "",
          i18n.t("projects.scoreText"),
          i18n.t("projects.status"),
          i18n.t("interview.completedAt"),
          <RoleBasedGuard permissionKey={PermissionsEnum.DOWNLOAD_HEATMAP_PDF}>
            <NSHeatmapStyle.DownloadListButtonContainer key="download-button">
              <NSHeatmapStyle.DownloadListButton
                size="small"
                label={i18n.t("candidate.downloadList")}
                onClick={handleDownloadRecruitmentFormListButton}
              />
            </NSHeatmapStyle.DownloadListButtonContainer>
          </RoleBasedGuard>,
        ];

  const heatmapTableData: NSTableListType.ITableListItem[] =
    heatmapData && heatmapData.length > 0
      ? selectedInterviewType === InterviewTypeEnum.RecruitmentForm
        ? heatmapData.map((item) => {
            const isPassed = item.score >= item.passingScore;
            /* const onClickSeeDetailsButton = () => {
              navigate(
                `/projects/detail/${projectId}/heatmap/detail/${item.id}`
              );
            }; */
            return {
              id: item.id.toString(),
              informations: [
                {
                  id: "1",
                  information: item.rank,
                },
                {
                  id: "2",
                  information: item.candidate,
                },
                {
                  id: "3",
                  information: (
                    <NSHeatmapStyle.StyledIcons>
                      {/*  <RoleBasedGuard
                        permissionKey={PermissionsEnum.DOWNLOAD_CANDIDATE_CV}
                      >
                        <span
                          data-tooltip-id={`icon-tooltip-download-${item.id}`}
                          data-tooltip-content={i18n.t(
                            "projects.downloadReport"
                          )}
                          onClick={async () => {
                            await handleDownloadReportButton(item.interviewId);
                          }}
                        >
                          <Icon name="Download" />
                          <CustomTooltip
                            id={`icon-tooltip-download-${item.id}`}
                          >
                            {i18n.t("projects.downloadReport")}
                          </CustomTooltip>
                        </span>
                      </RoleBasedGuard> */}
                      <RoleBasedGuard
                        permissionKey={PermissionsEnum.ACCESS_CANDIDATE_REPORTS}
                      >
                        <span
                          data-tooltip-id={`icon-tooltip-star-${item.id}`}
                          data-tooltip-content={i18n.t(
                            "projects.candidateReport"
                          )}
                          onClick={() => {
                            navigate(
                              `/projects/detail/${projectId}/candidate/recruitment-form-report/${item.id}`,
                              {
                                state: {
                                  projectId,
                                  interviewId: item.interviewId,
                                  hasCameFromHeatmap: true,
                                  selectedInterviewType: selectedInterviewType,
                                },
                              }
                            );
                          }}
                        >
                          <Icon name="NoteSearch" />
                          <CustomTooltip id={`icon-tooltip-star-${item.id}`}>
                            {i18n.t("projects.candidateReport")}
                          </CustomTooltip>
                        </span>
                      </RoleBasedGuard>
                    </NSHeatmapStyle.StyledIcons>
                  ),
                },
                {
                  id: "4",
                  information: <HeatmapBadge score={item.score ?? 0} />,
                },
                {
                  id: "5",
                  information: (
                    <StatusBadge
                      label={
                        isPassed
                          ? i18n.t("interview.passed")
                          : i18n.t("interview.failed")
                      }
                      color={
                        isPassed
                          ? NSStatusBadgeType.dotColor.green
                          : NSStatusBadgeType.dotColor.red
                      }
                    />
                  ),
                },
                {
                  id: "6",
                  information: item.completedAt,
                },
                /* {
                  id: "6",
                  information: (
                    <NSHeatmapStyle.SeeDetailsButton>
                      <LinkButton
                        type="button"
                        label={i18n.t("interview.seeDetails")}
                        onClick={onClickSeeDetailsButton}
                      />
                    </NSHeatmapStyle.SeeDetailsButton>
                  ),
                }, */
              ],
            };
          })
        : heatmapData.map((item) => {
            return {
              id: item.id.toString(),
              informations: [
                {
                  id: "1",
                  information: item.rank,
                },
                {
                  id: "2",
                  information: item.candidate,
                },
                {
                  id: "3",
                  information: <HeatmapBadge score={item.score ?? 0} />,
                },
                {
                  id: "4",
                  information: (
                    <NSHeatmapStyle.StyledIcons>
                      <RoleBasedGuard
                        permissionKey={PermissionsEnum.DOWNLOAD_CANDIDATE_CV}
                      >
                        <span
                          data-tooltip-id={`icon-tooltip-download-${item.id}`}
                          data-tooltip-content={i18n.t(
                            "projects.downloadReport"
                          )}
                          onClick={async () => {
                            await handleDownloadReportButton(item.interviewId);
                          }}
                        >
                          <Icon name="Download" />
                          <CustomTooltip
                            id={`icon-tooltip-download-${item.id}`}
                          >
                            {i18n.t("projects.downloadReport")}
                          </CustomTooltip>
                        </span>
                      </RoleBasedGuard>
                      <RoleBasedGuard
                        permissionKey={PermissionsEnum.ACCESS_CANDIDATE_REPORTS}
                      >
                        <span
                          data-tooltip-id={`icon-tooltip-star-${item.id}`}
                          data-tooltip-content={i18n.t(
                            "projects.candidateReport"
                          )}
                          onClick={() => {
                            navigate(
                              `/projects/detail/${projectId}/candidate/candidate-score-result/${
                                item.interviewId
                              }/${
                                selectedInterviewType ===
                                InterviewTypeEnum.Competency
                                  ? NSCandidateListType.ScorePageBaseType
                                      .COMPETENCY_INTERVIEW
                                  : NSCandidateListType.ScorePageBaseType
                                      .ENGLISH_TEST
                              }/overview`,
                              {
                                state: {
                                  projectId,
                                  interviewId: item.interviewId,
                                  hasCameFromHeatmap: true,
                                  selectedInterviewType: selectedInterviewType,
                                  selectedOrder,
                                },
                              }
                            );
                          }}
                        >
                          <Icon name="NoteSearch" />
                          <CustomTooltip id={`icon-tooltip-star-${item.id}`}>
                            {i18n.t("projects.candidateReport")}
                          </CustomTooltip>
                        </span>
                      </RoleBasedGuard>
                    </NSHeatmapStyle.StyledIcons>
                  ),
                },
                ...item.reviewMetrics
                  .reduce((acc, metric) => {
                    const existingMetric = acc?.find(
                      (m) =>
                        metric !== null && m.description === metric.description
                    );

                    if (existingMetric) {
                      existingMetric.totalScore += metric?.score ?? 0;
                      existingMetric.count += metric?.score !== null ? 1 : 0;
                    } else {
                      acc.push({
                        ...metric,
                        description: metric?.description ?? "",
                        totalScore: metric?.score ?? 0,
                        count: metric?.score !== null ? 1 : 0,
                        metricId: metric?.metricId || 0,
                      });
                    }

                    return acc;
                  }, [] as { description: string; totalScore: number; count: number; metricId: number }[])
                  .map((metric, metricIndex) => ({
                    id: (metricIndex + 5).toString(),
                    information: (
                      <HeatmapBadge
                        withPercentage
                        score={
                          metric.count > 0
                            ? metric.totalScore / metric.count
                            : 0
                        }
                      />
                    ),
                  })),
              ],
            };
          })
      : [];

  const handleSetPagination = useCallback(
    (start: number, limit: number) => {
      setPagination({ start, limit });

      navigate(
        `/projects/detail/${projectId}/heatmap?selectedInterviewType=${selectedInterviewType}&selectedOrder=${selectedOrder}&start=${start}&limit=${limit}`,
        {
          state: { projectId },
        }
      );
    },
    [selectedInterviewType, selectedOrder, projectId]
  );

  const defaultInterviewType = useMemo(
    () => ({
      id: selectedInterviewType,
      label: interviewTypeOptions.find((x) => x.id === selectedInterviewType)
        ?.label,
    }),
    [selectedInterviewType, interviewTypeOptions]
  );

  const defaultSortOption = useMemo(
    () => ({
      id: selectedOrder,
      label: orderOptions.find((x) => x.id === selectedOrder)?.label,
    }),
    [selectedOrder, orderOptions]
  );

  const handleInterviewTypeSelect = (
    _id: number,
    selected: NSDropdown.IDropdownItem | undefined
  ) => {
    const interviewType = (selected?.id as number) ?? 1;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("selectedInterviewType", interviewType.toString());

    const selectedOrder = searchParams.get("selectedOrder") || "1";
    searchParams.set("selectedOrder", selectedOrder);

    navigate(
      `/projects/detail/${projectId}/heatmap?${searchParams.toString()}`,
      { state: { projectId } }
    );
  };

  const handleSortSelect = (
    _id: number,
    selected: NSDropdown.IDropdownItem | undefined
  ) => {
    const newSelectedOrder = (selected?.id as number) ?? InterviewSortEnum.ASC;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("selectedOrder", newSelectedOrder.toString());

    const selectedInterviewType =
      searchParams.get("selectedInterviewType") ||
      String(InterviewTypeEnum.Competency);
    searchParams.set("selectedInterviewType", selectedInterviewType);

    navigate(
      `/projects/detail/${projectId}/heatmap?${searchParams.toString()}`,
      { state: { projectId } }
    );
  };

  return {
    heatmapTableData,
    headerItemList,
    interviewTypeOptions,
    defaultInterviewType,
    defaultSortOption,
    searchString,
    isLoading:
      selectedInterviewType === InterviewTypeEnum.RecruitmentForm
        ? isRecruitmentFormScoresLoading
        : isVideoInterviewScoresLoading,
    totalItemCount:
      selectedInterviewType === InterviewTypeEnum.RecruitmentForm
        ? recruitmentFormScores?.recruitment_form_submission_aggregate.aggregate
            ?.count ?? 0
        : videoInterviewScores?.video_interview_aggregate?.aggregate?.count ??
          0,

    pagination,
    orderOptions,
    handleSortSelect,
    setSearchString,
    handleSetPagination,
    handleInterviewTypeSelect,
  };
};

export default useHeatmapVM;
