import { InsertClientWithUsersDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useInsertClientWithUsers() {
  const { mutateAsync, ...rest } = useGraphQLMutation(
    InsertClientWithUsersDocument
  );

  return {
    mutateAsync,
    rest,
  };
}

export default useInsertClientWithUsers;
