import styled from "styled-components";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSRecruitmentFormReportStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: max-content;
    gap: 12px;
    padding: 24px 32px;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const Content = styled.div`
    min-width: 1370px;
    max-width: 2450px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 16px;
    padding: 0 32px 24px;
    height: 100vh;
  `;

  export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;
  export const RightContent = styled.div`
    padding: 16px;
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    width: 100%; /* Tam genişlik */
    height: 100%; /* Sabit yükseklik */
    overflow: hidden; /* İçerik taşarsa gizle */
  `;

  export const CardContainer = styled.div`
    padding: 0 8px 48px;
    overflow-y: auto; /* Yalnızca dikey kaydırma */
    max-height: 100%; /* Ebeveynin yüksekliğini kullan */

    /* Scrollbar Stil Ayarları */
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: ${(props) =>
        props.theme.colors.neutrals.grey02}; /* Arka plan rengi */
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${(props) =>
        props.theme.colors.primary.primary}; /* Thumb rengi */
      border-radius: 8px;
    }
  `;

  export const RightContentTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;
  export const RightContentSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 16px;
  `;

  export const Navbar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    margin: 0 32px 16px;
    background-color: ${(props) => props.theme.colors.light.light};

    ${NSMainButtonStyle.ButtonLabel} {
      color: ${(props) => props.theme.colors.dark.dark};
    }
  `;

  export const CandidateCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    width: 100%;
  `;

  export const CandidateFullname = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const CandidateInfoItem = styled.div`
    display: flex;
    gap: 4px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const CandidateInfo = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
}
