import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import { NSEmptyViewType } from "../../../components/EmptyView/EmptyView.type";
import { useGetClients } from "../../../service/Clients/getClients";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import { DeleteClientDocument } from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";

const useClientListVm = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });
  const navigate = useNavigate();
  const [deletedClientId, setDeletedClientId] = useState<string>("");
  const {
    clientsInfo: clientListItems,
    aggregate: clientsAggregate,
    rest,
  } = useGetClients(pagination.limit, pagination.start, `%${searchString}%`);

  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: searchString,
  };

  const breadcrumb: NSBreadcrumbType.IBreadcrumb = {
    pageName: i18n.t("general.department"),
  };

  const onClickCard = useCallback(
    (id: string) => {
      navigate(`detail/${id}`, {
        state: {
          clientId: id,
        },
      });
    },
    [navigate]
  );

  const handleSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });
  }, []);

  const onClickEmptyViewButton = useCallback(() => {
    setSearchString("");
  }, []);

  const { mutateAsync: deleteClient } =
    useGraphQLMutation(DeleteClientDocument);

  const onDeleteClient = (id: string) => {
    deleteClient({ id })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("clients.clientDeleteSucces")}
            type="success"
          />,

          {
            id: "deleteClientSuccess",
          }
        );

        navigate(0);
      })

      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("clients.clientDeleteError")}
            type="error"
          />,

          {
            id: "deleteClientError",
          }
        );
      });
  };

  const onClickCloseButton = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const onClickDelete = useCallback((id: string) => {
    setIsDeleteModalOpen(true);
    setDeletedClientId(id);
  }, []);

  const onClickEditButton = useCallback(
    (id: string) => {
      navigate(`edit/${id}`, {
        state: {
          clientId: id,
        },
      });
    },
    [navigate]
  );

  const emptyView: NSEmptyViewType.IEmptyView = {
    buttonLabel: i18n.t("general.tryAgain"),
    errorMessage: i18n.t("general.emptyViewErrorMessage"),
    onClick: onClickEmptyViewButton,
  };
  const isLoading = useMemo(() => rest.isLoading, [rest.isLoading]);

  return {
    clientListItems,
    searchInput,
    breadcrumb,
    emptyView,
    deletedClientId,
    isLoading,
    totalItemCount: clientsAggregate?.aggregate?.count ?? 0,
    pagination,
    isDeleteModalOpen,
    onClickDelete,
    onClickCloseButton,
    onClickCard,
    onClickEditButton,
    handleSetPagination,
    onDeleteClient,
  };
};

export default useClientListVm;
