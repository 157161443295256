import { Logo } from "ui";
import i18n from "../../../library/i18next";
import { NSRecruitmentFormReportStyle } from "./RecruitmentFormReport.style";
import GeneralScore from "../Candidate-Score-Result/ScoreResultOverview/GeneralScore/GeneralScore";
import Modal2 from "../../../components/Modal/Modal2";
import useRecruitmentFormReportVm from "./RecruitmentFormReportVM";
import RecruitmentFormReportCard from "./RecruitmentFormReportCard/RecruitmentFormReportCard";

const RecruitmentFormReport = () => {
  const { recruitmentFormReportData, generalScoreData, closeHandler } =
    useRecruitmentFormReportVm();
  return (
    <Modal2 onClose={closeHandler} isFullWidth>
      <NSRecruitmentFormReportStyle.Container id="content">
        <NSRecruitmentFormReportStyle.Header>
          <Logo type="onlyIcon" />
          {i18n.t("projects.recruitmentForm")}
        </NSRecruitmentFormReportStyle.Header>
        {/*   <NSRecruitmentFormReportStyle.Navbar>
          <RoleBasedGuard
            permissionKey={PermissionsEnum.DOWNLOAD_CANDIDATE_REPORTS}
          >
            <LinkButton
              label={i18n.t("candidate.downloadPDF")}
              variant="label-w-icon-prefix"
              prefixIcon="Download"
              iconColor={colors.secondary.secondary}
            />
          </RoleBasedGuard>
        </NSRecruitmentFormReportStyle.Navbar> */}
        <NSRecruitmentFormReportStyle.Content id="sub-content">
          <NSRecruitmentFormReportStyle.LeftContent>
            <NSRecruitmentFormReportStyle.CandidateCardContainer>
              <NSRecruitmentFormReportStyle.CandidateFullname>
                {recruitmentFormReportData?.[0]?.candidateFullname}
              </NSRecruitmentFormReportStyle.CandidateFullname>
              <NSRecruitmentFormReportStyle.CandidateInfoItem>
                {i18n.t("candidate.email")}:
                <NSRecruitmentFormReportStyle.CandidateInfo>
                  {recruitmentFormReportData?.[0]?.email}
                </NSRecruitmentFormReportStyle.CandidateInfo>
              </NSRecruitmentFormReportStyle.CandidateInfoItem>
              <NSRecruitmentFormReportStyle.CandidateInfoItem>
                {i18n.t("candidate.phone")}:
                <NSRecruitmentFormReportStyle.CandidateInfo>
                  {recruitmentFormReportData?.[0]?.phone}
                </NSRecruitmentFormReportStyle.CandidateInfo>
              </NSRecruitmentFormReportStyle.CandidateInfoItem>
            </NSRecruitmentFormReportStyle.CandidateCardContainer>
            <GeneralScore data={generalScoreData} />
            {/*  <RecruitmentFormDetailsCard
              data={[
                { id: "1", isAdded: true, label: "123214" },
                { id: "2", isAdded: true, label: "123214" },
                { id: "3", isAdded: false, label: "123214" },
                { id: "4", isAdded: false, label: "123214" },
              ]}
            /> */}
          </NSRecruitmentFormReportStyle.LeftContent>
          <NSRecruitmentFormReportStyle.RightContent>
            <NSRecruitmentFormReportStyle.RightContentTitle>
              {recruitmentFormReportData?.[0]?.formInfo?.title}{" "}
              {i18n.t("candidate.formDetail")}
            </NSRecruitmentFormReportStyle.RightContentTitle>
            <NSRecruitmentFormReportStyle.RightContentSubtitle>
              {recruitmentFormReportData?.[0]?.formInfo?.description}
            </NSRecruitmentFormReportStyle.RightContentSubtitle>
            <NSRecruitmentFormReportStyle.CardContainer>
              <RecruitmentFormReportCard
                data={recruitmentFormReportData ?? []}
              />
            </NSRecruitmentFormReportStyle.CardContainer>
          </NSRecruitmentFormReportStyle.RightContent>
        </NSRecruitmentFormReportStyle.Content>
      </NSRecruitmentFormReportStyle.Container>
    </Modal2>
  );
};

export default RecruitmentFormReport;
