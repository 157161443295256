import { useState, useRef, useCallback } from "react";

import AddNewItem from "../AddNewItem/AddNewItem";
import BasicContextMenu from "./BasicContextMenu";
import CascadingContextMenu from "./CascadingContextMenu";
import CheckboxContextMenu from "./CheckboxContextMenu";
import IconContextMenu from "./IconContextMenu";
import { NSContextMenuStyle } from "./ContextMenu.style";
import { NSContextMenuType } from "./ContextMenu.type";
import SearchInput from "../SearchInput/SearchInput";
import SolidButton from "../SolidButton/SolidButton";
import { useDetectClickOutside } from "../../hooks/useDetectClickOutside";

function ContextMenu({
  id,
  children,
  items,
  type = "basic",
  buttonLabel,
  title,
  containAddNewItemButton,
  showSearch,
  isScrollable,
  searchText,
  shouldStopPropagation,
  fixedMenuPosition,
  setSearchTextFunction,
  onContextMenuOpen,
  onAddNewItem,
  onCheckedItems,
}: NSContextMenuType.IContextMenu) {
  const [contextMenuContentVisible, setContextMenuContentVisible] =
    useState<boolean>(false);
  const [menuPosition, setMenuPosition] =
    useState<NSContextMenuType.ContextMenuPosition>("up");
  const [menuContentHeight, setMenuContentHeight] = useState<number>(300);
  const contextMenuContentVisibleChangeHandler = useCallback(
    (status: boolean) => {
      onContextMenuOpen?.(status);
      setContextMenuContentVisible(status);
    },
    []
  );

  const baseRef = useDetectClickOutside({
    onTrigger: () => {
      contextMenuContentVisibleChangeHandler(false);
    },
  });

  const onClickContextMenuButton = () => {
    contextMenuContentVisibleChangeHandler(!contextMenuContentVisible);

    const baseBottomLocation =
      (baseRef.current?.getBoundingClientRect().bottom ?? 0) - 248;

    const contentHeight = contentRef.current?.clientHeight ?? 0;
    setMenuContentHeight(contentHeight);

    if (fixedMenuPosition) {
      setMenuPosition(fixedMenuPosition);
      return;
    }

    const getCalculatedMenuPosition = () =>
      baseBottomLocation > contentHeight
        ? "up"
        : baseBottomLocation > contentHeight / 2
        ? "center"
        : "down";

    setMenuPosition(getCalculatedMenuPosition());
  };

  const contentRef = useRef<HTMLDivElement>(null);

  const divider = (
    <NSContextMenuStyle.DividerContainer>
      <NSContextMenuStyle.Divider />
    </NSContextMenuStyle.DividerContainer>
  );

  const content = {
    basic: (
      <BasicContextMenu
        id={id}
        onClickItem={() => setContextMenuContentVisible(false)}
        items={items}
        divider={divider}
      />
    ),
    withIcon: (
      <IconContextMenu
        id={id}
        items={items}
        divider={divider}
        onClickItem={() => contextMenuContentVisibleChangeHandler(false)}
      />
    ),
    withCheckbox: (
      <CheckboxContextMenu
        onCheckedItems={onCheckedItems}
        items={items}
        divider={divider}
      />
    ),
    cascading: (
      <CascadingContextMenu
        id={id}
        items={items}
        divider={divider}
        onClickItem={() => contextMenuContentVisibleChangeHandler(false)}
      />
    ),
  };

  return (
    <NSContextMenuStyle.ContextMenuBase ref={baseRef}>
      <NSContextMenuStyle.ContextMenuButton
        role="button"
        onClick={(e) => {
          shouldStopPropagation && e.stopPropagation();
          onClickContextMenuButton();
        }}
      >
        {children}
      </NSContextMenuStyle.ContextMenuButton>

      <NSContextMenuStyle.ContextMenuContent
        contentHeight={menuContentHeight}
        menuPosition={menuPosition}
        isVisible={contextMenuContentVisible}
        ref={contentRef}
      >
        {title && (
          <NSContextMenuStyle.MenuTitle>{title}</NSContextMenuStyle.MenuTitle>
        )}

        {showSearch && (
          <NSContextMenuStyle.SearchInputContainer>
            <SearchInput
              value={searchText}
              id="contextMenuSearchInput"
              name="contextMenuSearchInput"
              onChange={setSearchTextFunction}
            />
          </NSContextMenuStyle.SearchInputContainer>
        )}
        <NSContextMenuStyle.ContextMenuItemContent isScrollable={isScrollable}>
          {content[type]}
        </NSContextMenuStyle.ContextMenuItemContent>

        {containAddNewItemButton && (
          <NSContextMenuStyle.AddNewItemContainer>
            <AddNewItem
              type="contextMenu"
              onClick={(newItems) => onAddNewItem?.(newItems)}
              items={items}
            />
          </NSContextMenuStyle.AddNewItemContainer>
        )}

        {buttonLabel && (
          <NSContextMenuStyle.ButtonContainer>
            <SolidButton fullWidth label={buttonLabel} />
          </NSContextMenuStyle.ButtonContainer>
        )}
      </NSContextMenuStyle.ContextMenuContent>
    </NSContextMenuStyle.ContextMenuBase>
  );
}

export default ContextMenu;
