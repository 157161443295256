import { graphql } from "../gql";

const gqUpdateJobDescriptionSkills = graphql(/* GraphQL */ `
  mutation UpdateJobDescriptionSkills(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_description_skills: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionSkillsRating = graphql(/* GraphQL */ `
  mutation UpdateJobDescriptionSkills(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_description_skills: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionEducationType = graphql(/* GraphQL */ `
  mutation UpdateJobDescriptionEducationType(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { academic_department: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionExperienceLevel = graphql(/* GraphQL */ `
  mutation updateJobDescriptionExperienceLevel(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_experience: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionRequirements = graphql(/* GraphQL */ `
  mutation updateJobDescriptionRequirements(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_description_requirements: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionResponsibilities = graphql(/* GraphQL */ `
  mutation updateJobDescriptionResponsibilities(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_description_responsibilities: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionBenefits = graphql(/* GraphQL */ `
  mutation updateJobDescriptionBenefits(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { job_description_benefits: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionSalary = graphql(/* GraphQL */ `
  mutation updateJobDescriptionSalary(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { salary: $values, step: $step }
    ) {
      returning {
        id
      }
    }
  }
`);

const gqUpdateJobDescriptionIntroduction = graphql(/* GraphQL */ `
  mutation updateJobDescriptionIntroduction(
    $id: uuid_comparison_exp
    $values: jsonb
    $step: job_description_step_enum
  ) {
    update_job_description(
      where: { id: $id }
      _set: { step: $step, job_description_intro: $values }
    ) {
      returning {
        id
      }
    }
  }
`);

export {
  gqUpdateJobDescriptionSkills,
  gqUpdateJobDescriptionSkillsRating,
  gqUpdateJobDescriptionEducationType,
  gqUpdateJobDescriptionExperienceLevel,
  gqUpdateJobDescriptionRequirements,
  gqUpdateJobDescriptionResponsibilities,
  gqUpdateJobDescriptionBenefits,
  gqUpdateJobDescriptionSalary,
  gqUpdateJobDescriptionIntroduction,
};
