import styled from "styled-components";

export namespace NSCScoreResultDetailsStyle {
  export const Container = styled.div`
    display: flex;
    gap: 24px;
  `;

  export const ScoreResultDetailItem = styled.div`
  display: flex;
  flex-direction column;
  gap: 8px;
  background: ${(props) => props.theme.colors.primary.tint};
  border-radius: 16px;
  padding: 16px;
  `;

  export const ScoreResultDetail = styled.div<{
    isBackgroundActive?: boolean;
  }>`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${ScoreResultDetailItem} {
      ${(props) =>
        props.isBackgroundActive &&
        `background: ${props.theme.colors.primary.tint} `}
    }
  `;

  export const ScoreResultDetailReviewMetric = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ScoreResultDetailReason = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ScoreResultDetailReasonText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const RecruitmentFormQuestionItem = styled.div`
    display: flex;
    gap: 8px;
  `;

  export const RecruitmentFormQuestionScore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 32px;

    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.primary.dark};
    background: ${(props) => props.theme.colors.light.light};
    border-radius: 32px;
  `;

  export const RecruitmentFormQuestionTitle = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const RecruitmentFormQuestionChoiceContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 16px;
  `;

  export const RecruitmentFormQuestionChoice = styled.div<{
    isActive?: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey08};
    background: ${(props) => props.theme.colors.neutrals.grey02};
    border: 1px solid ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 8px;
    padding: 8px;

    ${(props) =>
      props.isActive &&
      `background: ${props.theme.colors.light.light};
      border-color:${props.theme.colors.primary.primary}; 
      color:${props.theme.colors.primary.primary};`}
  `;

  export const RecruitmentFormQuestionValue = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey08};
    margin-top: 16px;
  `;
}
