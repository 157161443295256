import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import {
  isHLSProvider,
  MediaPlayer,
  MediaProvider,
  MediaProviderAdapter,
  MediaProviderChangeEvent,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { useState } from "react";
import { NSVideoPlayerType } from "./VideoPlayer.type";
import { NSVideoPlayerStyle } from "./VideoPlayer.style";
import i18n from "../../library/i18next";
import Loading from "../Loading/Loading";

function VideoPlayer({
  urls = [],
  title = "",
  subtitle = "",
  isSmall = false,
  authToken,
  accessToken,
  playlistData,
  isLoading,
  error,
  onSelectVideo = () => {},
}: Readonly<NSVideoPlayerType.IVideoPlayer>) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onProviderChange = (
    provider: MediaProviderAdapter | null,
    _event: MediaProviderChangeEvent
  ) => {
    if (isHLSProvider(provider) && provider.config) {
      provider.config = {
        xhrSetup(xhr) {
          xhr.setRequestHeader("Authorization", `Bearer ${authToken}`);
          xhr.setRequestHeader("x-storage-access-token", accessToken);
        },
      };
    }
  };

  const handleVideoSelect = (index: number) => {
    setCurrentIndex(index);
    onSelectVideo?.(index);
  };

  const handleEnded = () => {
    if (currentIndex < urls.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <NSVideoPlayerStyle.Container>
      {playlistData && playlistData[currentIndex]?.question && (
        <NSVideoPlayerStyle.CurrentQuestion>
          <NSVideoPlayerStyle.BoldText>
            {i18n.t("interview.question")}:
          </NSVideoPlayerStyle.BoldText>{" "}
          {playlistData[currentIndex].question}
        </NSVideoPlayerStyle.CurrentQuestion>
      )}

      <NSVideoPlayerStyle.PlayerContainer
        isSmall={isSmall}
        isLoading={!!isLoading}
      >
        <NSVideoPlayerStyle.PlayerWrapper>
          {isLoading ? (
            error ? (
              <NSVideoPlayerStyle.NotFound>
                {i18n.t("interview.loadingVideoError")}
              </NSVideoPlayerStyle.NotFound>
            ) : (
              <Loading />
            )
          ) : (
            <MediaPlayer
              autoPlay
              style={{ borderRadius: isSmall ? "0" : "8px 0 0 8px" }}
              playsInline
              title={title}
              src={urls[0]}
              onEnded={handleEnded}
              onProviderChange={onProviderChange}
            >
              <MediaProvider />
              <DefaultVideoLayout icons={defaultLayoutIcons} />
            </MediaPlayer>
          )}
          {isSmall && (
            <NSVideoPlayerStyle.InformationContainer>
              <NSVideoPlayerStyle.Name>{title}</NSVideoPlayerStyle.Name>
              <NSVideoPlayerStyle.Job>{subtitle}</NSVideoPlayerStyle.Job>
            </NSVideoPlayerStyle.InformationContainer>
          )}
        </NSVideoPlayerStyle.PlayerWrapper>

        {playlistData && playlistData?.length > 0 && !isSmall && (
          <NSVideoPlayerStyle.PlaylistContainer>
            <NSVideoPlayerStyle.PlaylistItemHeader>
              {i18n.t("general.videoList")}
            </NSVideoPlayerStyle.PlaylistItemHeader>
            {playlistData?.map((_item: any, index: number) => (
              <NSVideoPlayerStyle.PlaylistItem
                key={index}
                onClick={() => handleVideoSelect(index)}
                isActive={index === currentIndex}
              >
                <NSVideoPlayerStyle.PlaylistItemTitle>
                  {index + 1}. {i18n.t("interview.question")}
                </NSVideoPlayerStyle.PlaylistItemTitle>
              </NSVideoPlayerStyle.PlaylistItem>
            ))}
          </NSVideoPlayerStyle.PlaylistContainer>
        )}
      </NSVideoPlayerStyle.PlayerContainer>
    </NSVideoPlayerStyle.Container>
  );
}

export default VideoPlayer;
