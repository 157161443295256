import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

import * as Sentry from "@sentry/react";

import Hotjar from "@hotjar/browser";
import React from "react";
import ReactDOM from "react-dom/client";
import { Reset } from "styled-reset";
import posthog from "posthog-js";
import Config from "./config.json";
import App from "./App";
if(Config.REACT_APP_SENTRY_DSN){
Sentry.init({
  dsn: Config.REACT_APP_SENTRY_DSN,
  debug: Config.REACT_ENV === "local",
  environment: Config.REACT_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/.+\.hiringcycle\.ai\//,
        /^https:\/\/.+\.hiringcycle\.ai\/v1\/graphql/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  });
}
if(Config.REACT_APP_POSTHOG_KEY){
  posthog.init(Config.REACT_APP_POSTHOG_KEY, {
    api_host: Config.REACT_APP_POSTHOG_HOST,
  });
}


const hotjarVersion = 6;
Hotjar.init(Number(Config.REACT_APP_HOTJAR_SITE_ID), hotjarVersion);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Reset />
    <App />
  </React.StrictMode>
);
