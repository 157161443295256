// eslint-disable-next-line import/no-extraneous-dependencies
import styled from "styled-components";
import { NSLoadingStyle } from "../Loading/Loading.style";

export namespace NSVideoPlayerStyle {
  export const InformationContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.dark.dark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    height: 56px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Name = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const Job = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

  export const PlayerContainer = styled.div<{
    isSmall: boolean;
    isLoading: boolean;
  }>`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: ${(props) => (props.isSmall ? "unset" : "478px")};
    border-radius: 8px;
    background: ${(props) =>
      props.isLoading ? props.theme.colors.neutrals.grey10 : "transparent"};

    ${NSLoadingStyle.Container} {
      background: transparent;
      height: 100%;

      svg {
        stroke: ${(props) => props.theme.colors.light.light};
      }
    }
  `;

  export const PlayerWrapper = styled.div`
    flex: 3;
    height: 100%;
    position: relative;
  `;

  export const PlaylistContainer = styled.div`
    flex: 1;
    max-width: 250px;
    height: 100%;
    background: ${(props) => props.theme.colors.neutrals.grey10};
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    color: ${(props) => props.theme.colors.light.light};
    border-radius: 0 8px 8px 0;
  `;

  export const PlaylistItem = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    cursor: pointer;
    border-radius: 5px;
    background: ${(props) =>
      props.isActive ? props.theme.colors.primary.primary : "transparent"};
    transition: background 0.2s;

    &:hover {
      background: ${(props) =>
        props.isActive
          ? props.theme.colors.primary.primary
          : props.theme.colors.neutrals.grey05};
    }

    img {
      width: 60px;
      height: 40px;
      border-radius: 5px;
      object-fit: cover;
    }
  `;

  export const PlaylistItemHeader = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    margin-bottom: 8px;
  `;

  export const PlaylistItemTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
  `;

  export const NotFound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.light.light};
  `;

  export const CurrentQuestion = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
  `;

  export const BoldText = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    display: inline-block;
  `;
}
