/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import { useGetClientCandidate } from "../../../../service/ClientsCandidate/getClientCandidate";
import useInsertClientCandidateProject from "../../../../service/Project/insertClientCandidateProjec";
import i18n from "../../../../library/i18next";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import { Client_Candidate_Project_Candidate_List_Status_Enum } from "../../../../gql/graphql";

const useAddCandidateVm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectId } = state;
  const clientId = useMemo(() => state.clientId, [state]);

  const [searchString, setSearchString] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState<
    string | undefined
  >();
  const deferredSearchString = useDeferredValue(searchString);
  const searchInput = {
    id: "candidateSearchId",
    name: "candidateSearchName",
    showPrefix: false,
    label: "Search",
    placeholder: i18n.t("projects.searchNameOrEmail"),
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: deferredSearchString,
    showLabel: searchString !== "" || searchString !== undefined,
  };
  const { data, rest } = useGetClientCandidate(
    useDeferredValue(`%${deferredSearchString}%`),
    clientId
  );
  const { mutateAsync } = useInsertClientCandidateProject();

  const candidateData = useMemo(
    () => data?.client_candidate,
    [data?.client_candidate, rest]
  );
  const isEmpty = useMemo(
    () => data?.client_candidate.length === 0,
    [data?.client_candidate, rest]
  );

  const onSelectCandidate = useCallback((candidateId: string) => {
    setSelectedCandidate(candidateId);
  }, []);

  const onClickCreateCandidate = useCallback(() => {
    navigate(`/projects/detail/${projectId}/create-candidate`, {
      state: { clientId, projectId },
    });
  }, []);

  const onClickAddCandidate = useCallback(() => {
    mutateAsync({
      clientCandidateId: selectedCandidate,
      projectId: state.projectId,
      candidateStatus:
        Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist,
    })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.candidateAddSuccess")}
            type="success"
          />,
          {
            id: "addCandidateSuccess",
          }
        );
        navigate(-1);
      })

      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.candidateAddError")}
            type="error"
          />,
          {
            id: "addCandidateError",
          }
        );
      });
  }, [selectedCandidate, state.projectId]);

  return {
    candidateData,
    searchInput,
    onSelectCandidate,
    selectedCandidate,
    searchString,
    rest,
    isEmpty,
    onClickCreateCandidate,
    onClickAddCandidate,
  };
};

export default useAddCandidateVm;
