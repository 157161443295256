import { NSAvatarType } from "../Avatar/Avatar.type";

export namespace NSUserType {
  export interface IUser {
    name?: string;
    avatarUrl?: string;
    job?: string;
    username?: string;
    date?: Date;
    email?: string;
    isAvatarInLeft?: boolean;
    isAvatarCircle?: boolean;
    avatarSize?: NSAvatarType.AvatarSizeVariant;
    avatarInfoMargin?: AvatarInfoMarginVariant;
    avatarType?: NSAvatarType.AvatarType;
    companyName?: string;
    companyId?: string;
    id?: string;
  }

  export const AvatarInfoMarginTypes = {
    big: "16px",
    small: "8px",
  };

  export type AvatarInfoMarginVariant = "small" | "big";

  export interface IAuth0User {
    email?: string;
    email_verified?: boolean;
    name?: string;
    surname?: string;
    nickname?: string;
    avatar?: string;
    picture?: string;
    sub?: string;
    updated_at?: string;
  }
  export interface IHasuraClaims {
    "x-hasura-allowed-roles": string[];
    "x-hasura-default-role": string;
    "x-hasura-user-id": string;
    "x-hasura-user-company-id": string;
  }
  export interface IDbUser {
    email?: string;
    name?: string;
    surname?: string;
    company_id?: string;
    sub?: string;
    "https://hasura.io/jwt/claims"?: IHasuraClaims;
    avatar?: string;
  }
}
