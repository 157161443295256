import styled from "styled-components";
import { NSVideoPlayerStyle } from "../../../../components/VideoPlayer/VideoPlayer.style";

export namespace NSVideoPreviewStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 32px 28px;
    overflow: scroll;

    ${NSVideoPlayerStyle.Container} {
      top: 215px;
    }
  `;

  export const Content = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    font-family: greycliff-cf, sans-serif;
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    color: ${(props) => props.theme.colors.dark.dark};
    background-color: ${(props) => props.theme.colors.primary.light};
    border-radius: 10px;
    letter-spacing: 0.4px;
  `;

  export const List = styled.ul``;
  export const ListItem = styled.li``;

  export const VideoContainer = styled.div`
    height: 300px;
    width: 1100px;
  `;

  export const QuestionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 250px;
  `;

  export const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
  `;
}
