import { useState, useMemo } from "react";
import Checkbox from "../../../../components/Checkbox/CheckBox";
import Drawer from "../../../../components/Drawer/Drawer";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import i18n from "../../../../library/i18next";
import { NSCScoreResultDrawerStyle } from "./ScoreResultDrawer.style";
import { NSScoreResultDrawerType } from "./ScoreResultDrawer.type";
import DropdownWithCaption from "../../../../components/DropdownWithCaption/DropdownWithCaption";
import { useGetVideoInterviewsByProject } from "../../../../service/ClientsCandidate/getClientCandidate";
import {
  Order_By,
  SearchDocument,
  Video_Interview_Bool_Exp,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import { useGraphQLQuery } from "../../../../hooks/useGraphQL";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProjectDetailContext } from "../../../../context/ProjectDetailContext/ProjectDetailContext";
import capitalizeFirstLetterHelper from "../../../../utils/capitalizeFirstLetterHelper";
import useGetVideoInterview from "../../../../service/VideoInterview/getVideoInterview";
import Loading from "../../../../components/Loading/Loading";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import { NSCandidateListType } from "../../../Project/Detail/Candidate-List/Candidate-List.type";

const options = [
  {
    id: 1,
    label: i18n.t("projects.scoreAsc"),
  },
  {
    id: 2,
    label: i18n.t("projects.scoreDesc"),
  },
];

const ProgressBar = ({ value }: { value: number }) => {
  const colors = {
    0: "hsl(228,71%,98%)",
    25: "hsla(349, 73%, 56%, 1)",
    50: "hsla(54, 84%, 58%, 1)",
    75: "hsla(39, 85%, 59%, 1)",
    100: "hsla(137, 93%, 38%, 1)",
  };

  const getColor = (value: number): string => {
    if (value > 0 && value <= 25) return colors[25];
    if (value > 25 && value <= 50) return colors[50];
    if (value > 50 && value <= 75) return colors[75];
    if (value > 75 && value <= 100) return colors[100];
    return colors[0];
  };

  return (
    <NSCScoreResultDrawerStyle.ProgressBar>
      <NSCScoreResultDrawerStyle.ScoreThumb
        width={value}
        backgroundColor={getColor(value)}
      />
    </NSCScoreResultDrawerStyle.ProgressBar>
  );
};

const CandidateProgress = ({
  name,
  score,
  isDefaultChecked,
  isDisabled,
  onCheck,
}: NSScoreResultDrawerType.ICandidateProgressBar) => {
  const handleCheck = () => {
    if (!isDisabled) {
      onCheck();
    }
  };

  return (
    <NSCScoreResultDrawerStyle.CandidateProgressBar>
      <NSCScoreResultDrawerStyle.Name>{name}</NSCScoreResultDrawerStyle.Name>
      <NSCScoreResultDrawerStyle.ProgressBarContainer>
        <ProgressBar value={score} />
        <NSCScoreResultDrawerStyle.ProgressText>
          <NSCScoreResultDrawerStyle.Bold>
            {score}
          </NSCScoreResultDrawerStyle.Bold>
          /100
        </NSCScoreResultDrawerStyle.ProgressText>
        <div
          style={{
            pointerEvents: isDisabled ? "none" : "auto",
          }}
        >
          <Checkbox checked={!!isDefaultChecked} onClick={handleCheck} />
        </div>
      </NSCScoreResultDrawerStyle.ProgressBarContainer>
    </NSCScoreResultDrawerStyle.CandidateProgressBar>
  );
};

const ScoreResultDrawer = ({
  onClickCloseIcon,
}: NSScoreResultDrawerType.IScoreResultDrawer) => {
  const [searchString, setSearchString] = useState<string>("");
  const [selectedOrder, setSelectedOrder] = useState<number>(1);
  const [checkedInterviewId, setCheckedInterviewId] = useState<string | null>(
    null
  );

  const { checkedVideoStatusItems } = useProjectDetailContext();
  const location = useLocation();

  const navigate = useNavigate();

  const { projectId, interviewId, baseType } = useParams();

  const { data: searchData } = useGraphQLQuery(
    SearchDocument,
    {
      enabled: !!searchString,
    },
    {
      index: "client_candidate",
      query: searchString ?? "",
      limit: 99999,
      offset: 0,
    }
  );

  const clientCandidateIds =
    searchData?.search?.clientCandidate?.map((item) => item?.id) || [];

  const listFilter = useMemo(
    () => ({
      video_interview_type: {
        _eq:
          baseType === NSCandidateListType.ScorePageBaseType.ENGLISH_TEST
            ? Video_Interview_Type_Enum.EnglishAssessment
            : Video_Interview_Type_Enum.CompetencyAssessment,
      },
      client_candidate_project: {
        _and: [
          {
            project_id: { _eq: projectId },
          },
          searchString
            ? {
                client_candidate_id: { _in: clientCandidateIds },
              }
            : {},
        ],
      },
    }),
    [
      checkedVideoStatusItems,
      projectId,
      clientCandidateIds,
      searchString,
      selectedOrder,
    ]
  );
  const { hasCameFromHeatmap, selectedInterviewType } = location.state || {};

  const { data: videoInterview, rest: restVideoInterview } =
    useGetVideoInterview(interviewId ?? "");

  const { data: clientCandidate, rest: restClientCandidate } =
    useGetVideoInterviewsByProject(
      99999,
      0,
      listFilter as Video_Interview_Bool_Exp,
      selectedOrder === 1 ? Order_By.Desc : Order_By.Asc,
      {
        enabled:
          searchString && !searchData?.search?.clientCandidate?.length
            ? false
            : undefined,
      }
    );

  const candidate =
    videoInterview?.video_interview_by_pk?.client_candidate_project
      .client_candidate;

  const candidateScore = videoInterview?.video_interview_by_pk?.score;

  const scoredInterviews = clientCandidate?.video_interview.filter(
    (project) => {
      const hasScoredInterview = project.video_interview_statuses.some(
        (interview) => interview?.status === Video_Interview_Status_Enum.Scored
      );

      const isNotCurrentProject =
        project.client_candidate_project.id !==
        videoInterview?.video_interview_by_pk?.client_candidate_project.id;

      return hasScoredInterview && isNotCurrentProject;
    }
  );

  return (
    <NSCScoreResultDrawerStyle.Container>
      <Drawer
        onClickCloseIcon={onClickCloseIcon}
        isAddedSelfCloseFunction
        title={i18n.t("candidate.compareCandidate")}
      >
        {restVideoInterview.isLoading ? (
          <Loading />
        ) : (
          <>
            <NSCScoreResultDrawerStyle.DrawerContainer>
              <NSCScoreResultDrawerStyle.DrawerContent>
                <NSCScoreResultDrawerStyle.DrawerSubtitle>
                  {i18n.t("candidate.chooseCandidateToCompare")}
                </NSCScoreResultDrawerStyle.DrawerSubtitle>
                <NSCScoreResultDrawerStyle.Candidate>
                  <CandidateProgress
                    name={capitalizeFirstLetterHelper(
                      candidate?.name + " " + candidate?.surname
                    )}
                    score={candidateScore ?? 0}
                    isDefaultChecked
                    isDisabled
                    onCheck={() => {}}
                  />
                </NSCScoreResultDrawerStyle.Candidate>
                <NSCScoreResultDrawerStyle.Inputs>
                  <SearchInput
                    placeholder={i18n.t("general.search")}
                    id={"search"}
                    name={"search"}
                    onChange={(value: string) => {
                      setSearchString(value);
                    }}
                    value={searchString}
                  />
                  <DropdownWithCaption
                    prefixTitle={i18n.t("projects.sortBy")}
                    id={1}
                    options={options}
                    type={"basic"}
                    defaultCheckedItems={[
                      {
                        id: selectedOrder,
                        label: options.find((x) => x.id === selectedOrder)
                          ?.label,
                      },
                    ]}
                    selectedDropdownItem={{
                      id: selectedOrder,
                      label: options.find((x) => x.id === selectedOrder)?.label,
                    }}
                    onSelect={(_id, selected) => {
                      setSelectedOrder((selected?.id as number) ?? 1);
                    }}
                  />
                </NSCScoreResultDrawerStyle.Inputs>
                <NSCScoreResultDrawerStyle.CandidatesContainer>
                  {restClientCandidate.isLoading ? (
                    <Loading />
                  ) : scoredInterviews?.length &&
                    scoredInterviews.length > 0 ? (
                    scoredInterviews?.map((scored) => (
                      <CandidateProgress
                        key={scored.id}
                        name={capitalizeFirstLetterHelper(
                          scored.client_candidate_project.client_candidate
                            .name +
                            " " +
                            scored.client_candidate_project.client_candidate
                              .surname
                        )}
                        score={scored.score}
                        isDefaultChecked={scored.id === checkedInterviewId}
                        isDisabled={
                          !!checkedInterviewId &&
                          scored.id !== checkedInterviewId
                        }
                        onCheck={() =>
                          setCheckedInterviewId(
                            checkedInterviewId === scored.id ? null : scored.id
                          )
                        }
                      />
                    ))
                  ) : (
                    <>{i18n.t("candidate.candidateNotFound")}</>
                  )}
                </NSCScoreResultDrawerStyle.CandidatesContainer>
              </NSCScoreResultDrawerStyle.DrawerContent>
            </NSCScoreResultDrawerStyle.DrawerContainer>
            <NSCScoreResultDrawerStyle.ButtonContainer>
              <SolidButton
                label={i18n.t("candidate.compare")}
                fullWidth
                onClick={() => {
                  navigate(
                    `/projects/detail/${projectId}/candidate/candidate-score-result/${interviewId}/${baseType}/${location.pathname
                      .split("/")
                      .pop()}`,
                    {
                      state: {
                        secondInterviewId: checkedInterviewId,
                        hasCameFromHeatmap,
                        selectedInterviewType,
                      },
                    }
                  );
                }}
                disabled={!checkedInterviewId}
              />
            </NSCScoreResultDrawerStyle.ButtonContainer>
          </>
        )}
      </Drawer>
    </NSCScoreResultDrawerStyle.Container>
  );
};

export default ScoreResultDrawer;
