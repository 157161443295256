import dayjs from "dayjs";
import { Client_Candidate_Project_Note } from "../../gql/graphql";
import { NSNoteListStyle } from "./NoteList.style";
import { NSNoteListType } from "./NoteList.type";
import capitalizeFirstLetterHelper from "../../utils/capitalizeFirstLetterHelper";
import i18n from "../../library/i18next";
import SolidButton from "../SolidButton/SolidButton";
import { RoleBasedGuard } from "../RoleBasedGuard/RoleBasedGuard";
import { PermissionsEnum } from "../../permissions/roles";

function NoteList({
  notes,
  handleClickSeeAllNotes,
  handleClickAddNewNote,
}: Readonly<NSNoteListType.INoteList>) {
  return (
    <NSNoteListStyle.Container>
      <NSNoteListStyle.Title>{i18n.t("projects.notes")}</NSNoteListStyle.Title>
      {notes.map((item: Client_Candidate_Project_Note) => (
        <NSNoteListStyle.NoteListItem key={item.id}>
          <NSNoteListStyle.Note>{item.note}</NSNoteListStyle.Note>
          <NSNoteListStyle.InfoContainer>
            {capitalizeFirstLetterHelper(
              `${item.user.name} ${item.user.surname} `
            )}
            - {dayjs(item.created_at).format("DD.MM.YYYY[,] hh:mm")}
          </NSNoteListStyle.InfoContainer>
        </NSNoteListStyle.NoteListItem>
      ))}
      <RoleBasedGuard permissionKey={PermissionsEnum.ADD_CANDIDATE_NOTE}>
        <NSNoteListStyle.ButtonContainer>
          <SolidButton
            label={i18n.t("projects.seeAllNotes")}
            onClick={handleClickSeeAllNotes}
          />
          <NSNoteListStyle.AddNoteButton
            prefixIcon="Add"
            onClick={handleClickAddNewNote}
            variant="label-w-icon-prefix"
            label={i18n.t("projects.addNewNote")}
          />
        </NSNoteListStyle.ButtonContainer>
      </RoleBasedGuard>
    </NSNoteListStyle.Container>
  );
}

export default NoteList;
