import { useCallback, useMemo } from "react";

import { useGraphQLQuery } from "../../hooks/useGraphQL";
import {
  Department_Role_Name_Enum,
  GetClientsDocument,
  GetClientsDropdownDocument,
} from "../../gql/graphql";
import companySizeHelper from "../../utils/companySizeHelper";

export function useGetClients(
  limit: number,
  offset: number,
  searchString: string
) {
  const { data, ...rest } = useGraphQLQuery(GetClientsDocument, undefined, {
    limit,
    offset,
    searchString,
  });

  const informationText = useCallback(
    (hasMultipleLocation: boolean, employeeCount?: number | null) => {
      let desciption = hasMultipleLocation
        ? "Multi Location, "
        : "Single Location, ";

      desciption += companySizeHelper(employeeCount ?? 1);

      return desciption;
    },
    []
  );

  const clientsInfo = useMemo(
    () =>
      data?.client.map((item) => ({
        departmentName: item.client_name ?? "",
        id: item.id,
        departmentManager: item.client_users.find(
          (i) => i.department_role_name === Department_Role_Name_Enum.Manager
        )?.user,
        isDefault: item.is_default,
      })),
    [data?.client, informationText]
  );

  return {
    aggregate: data?.client_aggregate,
    rest,
    clientsInfo,
  };
}

export function useGetClientDropdown() {
  const { data, ...rest } = useGraphQLQuery(GetClientsDropdownDocument);

  const clients = data?.client.map((clientItem) => ({
    dropdown: {
      id: clientItem.id,
      label: clientItem.client_name,
    },
    isDefault: clientItem.is_default,
  }));

  return {
    clients,
    rest,
  };
}
