import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../library/i18next";
import { PermissionsEnum } from "../../permissions/roles";
import { usePermissions } from "../../context/PermissionContext/PermissionContext";

const useSettingsVm = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const linkToTab: { [key: string]: number } = useMemo(
    () => ({
      profile: 1,
      plans: 2,
      integration: 3,
    }),
    []
  );

  const getActiveTab = () => {
    const urlArray = window.location.href.split("/");
    const activeUrl = urlArray[urlArray.length - 1];
    return linkToTab[activeUrl] ?? 1;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab());

  type urlLinksType = {
    [key: number]: string;
  };

  const urlLinks: urlLinksType = useMemo(
    () => ({
      1: "profile",
      2: "plans",
      3: "integration",
    }),
    []
  );

  const onTabChange = useCallback(
    (tab: number) => {
      setActiveTab(tab);
      navigate(`${urlLinks[tab]}`);
    },
    [navigate, urlLinks]
  );

  const tabItems = [
    {
      id: 1,
      label: i18n.t("settings.profile"),
    },
    {
      id: 2,
      label: i18n.t("settings.plans"),
      permissionKey: PermissionsEnum.VIEW_PLAN,
    },
    {
      id: 3,
      label: i18n.t("settings.integration"),
      permissionKey: PermissionsEnum.INTEGRATION,
    },
  ].filter(
    (item) =>
      !item.permissionKey || permissions.hasPermission(item.permissionKey)
  );

  return {
    tabItems,
    activeTab,
    onTabChange,
  };
};

export default useSettingsVm;
