import { useNavigate, useParams } from "react-router-dom";

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import useGetVideoInterview from "../../../../service/VideoInterview/getVideoInterview";
import useGetVideoInterviewVideos from "../../../../service/VideoInterview/getVideoInterviewVideos";
import i18n from "../../../../library/i18next";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import toast from "react-hot-toast";

const useVideoPreviewVM = () => {
  const { interviewId } = useParams();
  const [videoData, setVideoData] = useState<{
    url: string;
    accessToken: string | null;
    authToken: string;
  }>();
  const [error, setError] = useState<string>("");
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(1);
  const { data } = useGetVideoInterview(interviewId!);

  const navigate = useNavigate();

  const closeHandler = useCallback(() => {
    navigate(-1);
  }, []);

  const onSelectVideo = (index: number) => {
    setActiveQuestionIndex(index + 1);
  };

  const videoFileInfos = useMemo(
    () =>
      data?.video_interview_by_pk?.video_interview_questions
        ?.filter((q) => q.review_metric_question.is_shown_to_candidate)
        ?.map((videoItem) => ({
          interviewId: data?.video_interview_by_pk?.id,
          rmqId: videoItem.review_metric_question.id,
        })) ?? [],
    [data]
  );

  const fetchVideoUrls = async () => {
    try {
      if (videoFileInfos.length === 0) return;

      const selectedVideoFileInfo = videoFileInfos?.[activeQuestionIndex - 1];
      const { videoData, error } = await useGetVideoInterviewVideos([
        selectedVideoFileInfo,
      ]);

      if (error) {
        toast(
          <ToastAlert
            description={i18n.t("interview.loadingVideoError")}
            type="error"
          />,
          { id: "loadingVideoError" }
        );
      }
      setError(error ?? "");
      setVideoData(videoData?.[0] ?? null);
    } catch (err) {
      toast(
        <ToastAlert
          description={i18n.t("interview.loadingVideoError")}
          type="error"
        />,
        { id: "loadingVideoError" }
      );
    }
  };

  useEffect(() => {
    fetchVideoUrls();
  }, [videoFileInfos, activeQuestionIndex]);

  const interviewQuestions =
    data?.video_interview_by_pk?.video_interview_questions;
  return {
    videoData,
    interviewQuestions,
    videoFileInfos,
    error,
    onSelectVideo,
    closeHandler,
  };
};

export default useVideoPreviewVM;
