import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ILinkButton } from "../../../components/LinkButton/LinkButton.type";
import { ISolidButton } from "../../../components/SolidButton/SolidButton.type";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import { NSDashboardHeaderType } from "../../../components/DashboardHeader/DashboardHeader.type";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import i18n from "../../../library/i18next";
import { useGetProjectsDropdown } from "../../../service/Project/getProjects";
import { useProjectDetailContext } from "../../../context/ProjectDetailContext/ProjectDetailContext";
import { usePermissions } from "../../../context/PermissionContext/PermissionContext";
import { PermissionsEnum } from "../../../permissions/roles";

const useProjectDetailVm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const permissions = usePermissions();

  const projectIdFromLocation = state?.projectId;
  const [projectId, setProjectId] = useState(projectIdFromLocation);

  const linkToTab: { [key: string]: number } = useMemo(
    () => ({
      overview: 1,
      interview: 2,
      "job-descriptions": 3,
      applied: 4,
      "long-list": 5,
      "short-list": 6,
      heatmap: 7,
    }),
    []
  );
  const getActiveTab = () => {
    const urlArray = window.location.href.split("/");
    let activeUrl = urlArray[urlArray.length - 1];
    if (activeUrl.includes("?")) {
      activeUrl = activeUrl.split("?")[0];
    }

    const tabId = linkToTab[activeUrl];
    if (!tabId) {
      if (urlArray.includes("detail") && urlArray.length === 4) {
        const projectId = urlArray[urlArray.indexOf("detail") + 1];
        setTimeout(() => {
          window.location.href = `/projects/detail/${projectId}/overview`;
        }, 0);
        return 1;
      }
      return 1;
    }

    return tabId;
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());

  const { projects } = useGetProjectsDropdown();
  const { dropdowns, searchInput } = useProjectDetailContext();

  type urlLinksType = {
    [key: number]: string;
  };

  const urlLinks: urlLinksType = useMemo(
    () => ({
      1: "overview",
      2: "interview",
      3: "job-descriptions",
      4: "applied",
      5: "long-list",
      6: "short-list",
      7: "heatmap",
    }),
    []
  );

  const projectName = useMemo(
    () => projects?.find((i) => i.dropdown.id === projectId)?.dropdown.label,
    [projectId, projects]
  );
  const clientId = useMemo(
    () => projects?.find((i) => i.dropdown.id === projectId)?.clientId,
    [projectId, projects]
  );

  const projectDropdown: NSDropdown.IDropdownItem[] = useMemo(
    () =>
      projects?.map((item) => ({
        label: item.dropdown.label,
        id: item.dropdown.id,
      })) ?? [],
    [projects]
  );

  const addNewItemButton: ILinkButton | null = useMemo(() => {
    if (!permissions.hasPermission(PermissionsEnum.CREATE_PROJECT)) return null;

    return {
      label: i18n.t("general.addNewItem"),
      prefixIcon: "Add",
      variant: "label-w-icon-prefix",
      onClick: () => navigate("/projects/create"),
    };
  }, [permissions, navigate]);

  const breadcrumb: NSBreadcrumbType.IBreadcrumb = useMemo(
    () => ({
      pageName: i18n.t("general.projects"),
      innerPageTitle: projectName,
      dropdown: {
        id: 1,
        containAddNewItemButton: true,
        addNewItemButtonNode: <LinkButton {...addNewItemButton} />,
        type: "basic",
        showHoverIcon: true,
        placeholder: "ProjectName",
        options: projectDropdown,
        title: i18n.t("general.projects"),
        showLabel: false,
        onSelect(_id, selected) {
          setProjectId(selected?.id);

          navigate(`/projects/detail/${selected?.id}/${urlLinks[activeTab]}`, {
            state: { projectId: selected?.id, clientId },
          });
        },
      },
    }),
    [
      activeTab,
      addNewItemButton,
      clientId,
      navigate,
      projectDropdown,
      projectId,
      projectName,
      urlLinks,
    ]
  );

  const generateJDButton: ISolidButton | null = useMemo(() => {
    if (!permissions.hasPermission(PermissionsEnum.CREATE_JOB_POSTING))
      return null;

    return {
      label: i18n.t("projects.generateJobDescription"),
      onClick: () => navigate("/job-description/create", { state }),
    };
  }, [permissions, navigate, state]);

  const dashboardHeaderOverview: NSDashboardHeaderType.IDashboardHeader =
    useMemo(
      () => ({
        buttonType: "solid",
        breadcrumb,
        headerButton: generateJDButton,
      }),
      [breadcrumb, generateJDButton]
    );

  const dashboardHeaderHeatmap: NSDashboardHeaderType.IDashboardHeader =
    useMemo(
      () => ({
        breadcrumb,
      }),
      [breadcrumb]
    );

  const addCandidateButton: NSDropdown.IDropdown = useMemo(
    () => ({
      label: i18n.t("projects.addCandidate"),
      id: 1,
      type: "basic",
      options: [
        { id: 1, label: i18n.t("projects.addCandidate") },
        { id: 2, label: i18n.t("projects.uploadFromExcel") },
      ],
      onSelect: (_, selected) =>
        navigate(
          selected?.id === 1 ? "add-candidate" : "add-candidate-from-excel",
          {
            state: {
              projectId,
              clientId,
            },
          }
        ),
    }),
    [clientId, navigate, projectId]
  );

  const dashboardHeaderLongList: NSDashboardHeaderType.IDashboardHeader =
    useMemo(
      () => ({
        buttonType: "dropdown",
        breadcrumb,
        headerButton: permissions.hasPermission(
          PermissionsEnum.UPLOAD_CANDIDATE
        )
          ? addCandidateButton
          : null,
        searchInput: permissions.hasPermission(
          PermissionsEnum.SEARCH_CANDIDATES
        )
          ? searchInput
          : null,
        dropdowns,
      }),
      [addCandidateButton, breadcrumb, dropdowns, searchInput]
    );

  type dashboardHeaderOptionType = {
    [key: number]: NSDashboardHeaderType.IDashboardHeader;
  };

  const dasboardHeaderOption: dashboardHeaderOptionType = useMemo(
    () => ({
      1: dashboardHeaderOverview,
      2: dashboardHeaderOverview,
      3: dashboardHeaderOverview,
      4: dashboardHeaderLongList,
      5: dashboardHeaderLongList,
      6: dashboardHeaderLongList,
      7: dashboardHeaderHeatmap,
    }),
    [dashboardHeaderLongList, dashboardHeaderOverview, dashboardHeaderHeatmap]
  );

  const onTabChange = useCallback(
    (tab: number) => {
      setActiveTab(tab);

      const currentSearchParams = new URLSearchParams(window.location.search);

      const newUrl = `/projects/detail/${projectId}/${urlLinks[tab]}`;

      const queryParams = currentSearchParams.toString();
      const fullUrl = queryParams ? `${newUrl}?${queryParams}` : newUrl;

      navigate(fullUrl, {
        state: {
          projectId,
          clientId,
        },
      });
    },
    [clientId, navigate, projectId, urlLinks]
  );

  const dasboardHeader = useMemo(
    () => dasboardHeaderOption[activeTab as keyof dashboardHeaderOptionType],
    [activeTab, dasboardHeaderOption]
  );

  const tabItems = [
    {
      id: 1,
      label: i18n.t("projects.overview"),
      permissionKey: PermissionsEnum.VIEW_OVERVIEW_REPORT,
    },
    {
      id: 2,
      label: i18n.t("projects.interview"),
    },
    {
      id: 3,
      label: i18n.t("projects.jobDescriptions"),
      permissionKey: PermissionsEnum.VIEW_JOB_POSTINGS,
      hasSeperator: true,
    },
    {
      id: 4,
      label: i18n.t("projects.applied"),
    },
    {
      id: 5,
      label: i18n.t("projects.longList"),
      permissionKey: PermissionsEnum.VIEW_LONGLIST,
    },
    {
      id: 6,
      label: i18n.t("projects.shortList"),
      permissionKey: PermissionsEnum.VIEW_SHORTLIST,
      hasSeperator: true,
    },
    {
      id: 7,
      label: i18n.t("projects.heatmap"),
      permissionKey: PermissionsEnum.VIEW_HEATMAP,
    },
  ].filter(
    (item) =>
      !item.permissionKey || permissions.hasPermission(item.permissionKey)
  );

  return {
    dasboardHeader,
    searchInput,
    breadcrumb,
    tabItems,
    activeTab,
    projectId,
    onTabChange,
  };
};

export default useProjectDetailVm;
