/* eslint-disable import/no-extraneous-dependencies */

import { useMemo } from "react";

import { useLocation } from "react-router-dom";
import { NSUserType } from "../../components/User/User.type";
import { LOGIN_USER_INFO } from "../../utils/globalConstants";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { useGraphQLQuery } from "../../hooks/useGraphQL";
import { GetUserCompanyDocument } from "../../gql/graphql";

const useHomeVm = () => {
  const userInfo = JSON.parse(window.localStorage.getItem(LOGIN_USER_INFO)!);
  const location = useLocation();

  const { data: companyData, isSuccess: isCompanyDataSuccess } =
    useGraphQLQuery(GetUserCompanyDocument, undefined, {
      id: userInfo.sub,
    });
  const { user } = useAuthContext();

  const activeUser: NSUserType.IUser = useMemo(() => {
    const data = {
      name: `${user.name}  ${user.surname} `,
      avatarUrl: user.avatar ?? "",
      companyName: companyData?.user_by_pk?.company.company_name,
      companyId: companyData?.user_by_pk?.company.id,
    };

    return data;
  }, [userInfo, user]);

  const redirectToProject = () => {
    if (location.pathname === "/") {
      window.location.href = "/projects";
    }
  };

  const isLoading = !isCompanyDataSuccess;

  return {
    activeUser,
    isLoading,
    redirectToProject,
  };
};

export default useHomeVm;
