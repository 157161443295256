import styled from "styled-components";

export namespace NSClientDetailStyle {
  export const Container = styled.div`
    height: 100%;
  `;

  export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 90px);
    padding: 24px 32px;
    border-radius: 16px;
    gap: 32px;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const SecondaryTitle = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    margin-bottom: 8px;
  `;

  export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
  `;

  export const Information = styled.div`
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    :nth-child(3) {
      margin-top: 32px;
    }
  `;

  export const CityName = styled.div``;
  export const CityNameContainer = styled.div`
    display: flex;
    gap: 2px;
  `;

  export const DepartmentManager = styled.div`
    margin-top: 24px;
  `;
}
