import { SubmitHandler, useForm } from "react-hook-form";
import { date, object, string } from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";

import { NSEditProjectType } from "./EditProject.type";

import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetProjectEditInfoDocument,
  GetVideoInterviewByProjectIdDocument,
  Language_Enum,
  UpdateProjectDocument,
} from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import useGetLanguagesDropdown from "../../../service/Languages/getLanguages";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { useGetRolesDropdown } from "../../../service/Roles/getRoles";
import { useThrottle } from "@uidotdev/usehooks";
import useGetInterviewTemplatesDropdown from "../../../service/InterviewTemplates/getInterviewTemplates";
import useHomeVm from "../../Home/HomeVM";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

const useEditProjectVm = () => {
  const { state, search } = useLocation();
  const { projectId } = state;

  const [roleSearchValue, setRoleSearchValue] = useState<string>("");
  const throttledRoleSearchValue = useThrottle(roleSearchValue, 300);
  const searchParams = new URLSearchParams(search);
  const seePreviewTemplates = searchParams.get("seePreviewTemplates");
  const homeVm = useHomeVm();

  const { data: projectData, isSuccess } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    undefined,
    {
      projectId,
    }
  );

  const { data: videoInterviews } = useGraphQLQuery(
    GetVideoInterviewByProjectIdDocument,
    undefined,
    {
      projectId,
    }
  );

  const isInterviewTemplateEditable =
    videoInterviews?.client_candidate_project.every(
      (project) => project.video_interviews.length === 0
    );

  const initialValues = {
    projectName: projectData?.project_by_pk?.project_name ?? "",
    description: projectData?.project_by_pk?.description ?? "",
    /*  users:
      projectData?.project_by_pk?.project_users?.map((i) => ({
        id: i.user_id,
        label: i.user.name + " " + i.user.surname,
      })) ?? [], */
    timePeriod: {
      startDate: projectData?.project_by_pk?.start_date,
      endDate: projectData?.project_by_pk?.end_date,
    },
    language: projectData?.project_by_pk?.language as Language_Enum,
    role: {
      id: projectData?.project_by_pk?.role?.id,
      label: projectData?.project_by_pk?.role?.title,
    },
    interviewTemplate: {
      id: projectData?.project_by_pk?.interview_template?.id,
      label: projectData?.project_by_pk?.interview_template?.template_name,
    },
  };

  // TODO: remove any from type
  const editProjectSchema: NSEditProjectType.IEditProjectFormValues | any =
    object({
      projectName: string().required(i18n.t("forms.projectNameError")),
      /*  client: object({ id: string(), label: string() })
        .required(i18n.t("forms.selectDepartmentError"))
        .default(initialValues.client), */
      /*  users: array(object({ id: string(), label: string() }))
        .required(i18n.t("forms.selectUserError"))
        .default(undefined), */
      role: object({ id: string(), label: string() }).notRequired(),
      timePeriod: object({ startDate: date(), endDate: date() }).notRequired(),
      description: string().notRequired(),
      interviewTemplate: object({
        id: string(),
        label: string(),
      }).notRequired(),
      language: string()
        .oneOf(Object.values(Language_Enum))
        .label(i18n.t("forms.selectLanguageError"))
        .required()
        .default(initialValues.language),
    }).required();

  const {
    handleSubmit,
    formState: { errors },
    setFocus,
    control,
  } = useForm<NSEditProjectType.IEditProjectFormValues>({
    resolver: yupResolver(editProjectSchema),
    shouldFocusError: false,
    defaultValues: initialValues,
    values: initialValues,
  });
  const navigate = useNavigate();
  const { languages: languagesData } = useGetLanguagesDropdown();
  const { roles: rolesData } = useGetRolesDropdown(throttledRoleSearchValue);
  /*   const { data: usersData, rest: usersRest } = useGetUsers(9999, 0, "%%"); */
  const companyId = homeVm.activeUser.companyId ?? "";
  const { interviewTemplates: interviewTemplatesData } =
    useGetInterviewTemplatesDropdown(companyId);

  const languages = useMemo(
    () =>
      languagesData?.map((languageItem) => ({
        id: languageItem.dropdown.id,
        label: languageItem.dropdown.label,
      })),

    [languagesData]
  );

  const interviewTemplates =
    interviewTemplatesData?.map((item) => ({
      id: item.dropdown.id,
      label: item.dropdown.label,
    })) ?? [];

  const roles = useMemo(
    () =>
      rolesData?.map((roleItem) => ({
        id: roleItem.dropdown.id,
        label: roleItem.dropdown.label,
      })),

    [rolesData]
  );

  /* const users = useMemo(
    () =>
      usersData?.user.map((userItem) => ({
        id: userItem.id,
        label: `${userItem.name} ${userItem.surname}`,
      })),
    [usersRest]
  ); */

  const defaultLanguage = useMemo(
    () =>
      ({
        id: initialValues?.language,
        label: initialValues?.language?.toString().toUpperCase(),
      } as NSDropdown.IDropdownItem),
    [initialValues.language]
  );

  const { mutateAsync: editProject } = useGraphQLMutation(
    UpdateProjectDocument
  );

  const isLoading = useMemo(
    () =>
      !isSuccess ||
      projectData === undefined ||
      initialValues.projectName === "",
    [isSuccess, projectData, initialValues.projectName]
  );

  const onSubmit: SubmitHandler<NSEditProjectType.IEditProjectFormValues> =
    useCallback(
      (data, event) => {
        event?.preventDefault();

        const defaultDates = [
          new Date(),
          new Date(new Date().setDate(new Date().getDate() + 56)),
        ];

        /*  const formUsers = [
          ...data.users.map((user) => ({
            user_id: user.id,
            project_id: projectId,
          })),
        ].filter(Boolean); */

        /* const existingClientUsers =
          projectData?.project_by_pk?.project_users.map((i) => i.id) ?? []; */

        editProject({
          end_date: data.timePeriod.endDate ?? defaultDates[1],
          start_date: data.timePeriod.startDate ?? defaultDates[0],
          description: data.description,
          project_name: data.projectName,
          language: data.language as Language_Enum,
          role_id: data.role.id,
          interviewTemplateId: data?.interviewTemplate?.id as number,
          id: projectId,
          /* deletedUserIds: existingClientUsers,
          newUsers: formUsers, */
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectUpdateSuccessMessage")}
                type="success"
              />,

              {
                id: "updateProjectSuccess",
              }
            );

            navigate("/projects");
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectUpdateErrorMessage")}
                type="error"
              />,

              {
                id: "updateeProjectError",
              }
            );
          });
      },
      [editProject, projectId, projectData]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const onRoleSearchChange = (value: string | undefined) => {
    setRoleSearchValue(value ?? "");
  };

  const onClickSeePreviewTemplates = () => {
    navigate(`/projects/edit-project/${projectId}?seePreviewTemplates=true`, {
      state: { projectId: projectId },
    });
  };

  const onClickNotSeePreviewTemplates = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFocus("projectName");
  }, [setFocus]);

  const onCloseDrawer = () => {
    navigate("/projects");
  };

  return {
    formErrors: errors,
    roles,
    isLoading,
    projectData: projectData?.project_by_pk,
    languages,
    control,
    defaultLanguage,
    interviewTemplates,
    seePreviewTemplates,
    isInterviewTemplateEditable,
    /*     users, */
    onCloseDrawer,
    onClickSeePreviewTemplates,
    onClickNotSeePreviewTemplates,
    handleSubmit: submitHandler,
    onRoleSearchChange,
  };
};

export default useEditProjectVm;
