import { useCallback, useDeferredValue, useMemo, useState } from "react";

import { DeleteJobDescriptionDocument } from "../../gql/graphql";
import { NSBreadcrumbType } from "../../components/Breadcrumb/Breadcrumb.type";
import { NSEmptyViewType } from "../../components/EmptyView/EmptyView.type";
import { PAGINATION_DEFAULT_LIMIT } from "../../utils/globalConstants";
import ToastAlert from "../../components/ToastAlert/ToastAlert";
import i18n from "../../library/i18next";
import toast from "react-hot-toast";
import useGetJobDescription from "../../service/JobDescription/getJobDescription";
import { useGraphQLMutation } from "../../hooks/useGraphQL";
import { NSTableListStyle } from "../../components/TableList/TableList.style";
import { NSContextMenuType } from "../../components/ContextMenu/ContextMenu.type";
import { useNavigate } from "react-router-dom";
import ContextMenuHOC from "../../components/ContextMenuHOC/ContextMenuHOC";
import { usePermissions } from "../../context/PermissionContext/PermissionContext";
import { PermissionsEnum } from "../../permissions/roles";
import icons from "../../assets/icons";

const useJobDescripitonVm = () => {
  const [searchString, setSearchString] = useState("");
  const permissions = usePermissions();
  const deferredSearchParam = useDeferredValue({
    title: { _ilike: `%${searchString}%` },
  });
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });

  const {
    jdListItems: jobDescriptionListItems,
    totalItemCount,
    refetch,
    rest,
  } = useGetJobDescription(
    pagination?.limit,
    pagination?.start,
    searchString ? deferredSearchParam : undefined
  );

  const navigate = useNavigate();

  const jobDescriptionListContextMenu: NSContextMenuType.IContextMenuItem[] = [
    ...(permissions.hasPermission(PermissionsEnum.DELETE_JOB_POSTINGS)
      ? [
          {
            iconName: "Trash" as keyof typeof icons,
            label: i18n.t("general.delete"),
            onClick: (_index: any, id: any) => {
              deleteJobDescriptionFunction(id);
            },
          },
        ]
      : []),
    ...(permissions.hasPermission(PermissionsEnum.VIEW_JOB_POSTINGS)
      ? [
          {
            iconName: "News" as keyof typeof icons,
            label: i18n.t("general.view"),
            onClick: (_index: any, id: any) => {
              const jdId = jobDescriptionListItems?.find(
                (job) => job.id === id
              )?.id;
              navigate(`/job-description/detail/${jdId}`, {
                state: { jdId },
              });
            },
          },
        ]
      : []),
  ];

  const jobDescriptionTableListItems = useMemo(() => {
    return jobDescriptionListItems?.map((item) => {
      item?.informations?.push({
        id: item.id,
        information: (
          <NSTableListStyle.ContextMenuColumn>
            {jobDescriptionListContextMenu &&
              jobDescriptionListContextMenu.length > 0 && (
                <ContextMenuHOC
                  id={item.id}
                  type="withIcon"
                  items={jobDescriptionListContextMenu}
                />
              )}
          </NSTableListStyle.ContextMenuColumn>
        ),
      });
      return item;
    });
  }, [jobDescriptionListItems, jobDescriptionListContextMenu]);

  const { mutateAsync: deleteJobDescription } = useGraphQLMutation(
    DeleteJobDescriptionDocument
  );

  const deleteJobDescriptionFunction = useCallback(
    (id?: string) => {
      if (id) {
        deleteJobDescription({ id })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("jd.jdDeleteSuccess")}
                type="success"
              />,

              {
                id: "deleteJDSuccess",
              }
            );
            refetch();
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("jd.jdDeleteError")}
                type="error"
              />,

              {
                id: "deleteJdError",
              }
            );
          });
      }
    },
    [deleteJobDescription, jobDescriptionListItems, refetch]
  );

  const headerList = [
    i18n.t("jd.title"),
    i18n.t("forms.projectName"),
    i18n.t("forms.department"),
    i18n.t("general.createdAt"),
  ];
  const isLoading = useMemo(() => rest.isLoading, [rest.isLoading]);
  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: searchString,
  };

  const breadcrumb: NSBreadcrumbType.IBreadcrumb = {
    pageName: i18n.t("general.jds"),
  };

  const onClickEmptyViewButton = useCallback(() => {
    setSearchString("");
  }, []);

  const emptyView: NSEmptyViewType.IEmptyView = {
    buttonLabel: i18n.t("general.tryAgain"),
    errorMessage: i18n.t("general.emptyViewErrorMessage"),
    onClick: onClickEmptyViewButton,
  };

  return {
    jobDescriptionTableListItems,
    totalItemCount,
    headerList,
    searchInput,
    breadcrumb,
    setPagination,
    isLoading,
    emptyView,
    deleteJobDescriptionFunction,
    pagination,
  };
};

export default useJobDescripitonVm;
