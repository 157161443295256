import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";

export namespace NSTabbedViewStyle {
  export const Container = styled.div`
    width: fit-content;
    height: fit-content;
    background-color: ${(props) => props.theme.colors.light.light};
  `;
  export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: fit-content;
    height: 40px;
  `;

  export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `;

  export const HeaderItem = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    width: max-content;
    height: 32px;
    cursor: pointer;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) =>
      props.selected
        ? props.theme.colors.primary.primary
        : props.theme.colors.neutrals.grey06};
    padding-bottom: 8px;
    border-bottom: ${(props) =>
      props.selected
        ? `2px solid ${props.theme.colors.primary.primary}`
        : `0px solid ${props.theme.colors.primary.primary}`};

    ${NSDropdownStyle.DropdownItemsContainer} {
      width: 220px;
    }
  `;

  export const Separator = styled.div`
    height: 32px;
    width: 1px;
    background-color: #dde0e5;
    margin-bottom: 8px;
  `;

  export const DropdownLabel = styled.div`
    display: flex;
  `;
}
