import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ObjectSchema, object, string } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Department_Role_Name_Enum,
  GetClientDocument,
} from "../../../gql/graphql";
import { useGraphQLQuery } from "../../../hooks/useGraphQL";
import { NSEditClientType } from "./Edit-Client.type";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { useGetUsers } from "../../../service/Users/getUser";
import useGetClientUser from "../../../service/ClientUser/getClientUsers";
import useUpdateClientWithUsers from "../../../service/Clients/updateClientWithUsers";
import { clearFormDataFromLocalStorageHelper } from "../../../utils/clearFormDataFromLocalStorageHelper";
import { saveFormDataToLocalStorageHelper } from "../../../utils/saveFormDataToLocalStorageHelper";
import { getFormDataFromLocalStorageHelper } from "../../../utils/getFormDataFromLocalStorageHelper";

// TODO: change any
const editClientSchema: ObjectSchema<
  NSEditClientType.IEditClientFormValues | any
> = object()
  .shape({
    departmentName: string().required(i18n.t("forms.departmentNameError")),
    /*  departmentUsers: array(object({ id: string(), label: string() }))
      .required(i18n.t("forms.departmentUsersError"))
      .default(undefined), */
  })
  .required();

const useEditClientVm = () => {
  const savedFormData = getFormDataFromLocalStorageHelper(
    "editDepartmentFormData"
  );
  const { state } = useLocation();
  const navigate = useNavigate();
  const clientId = useMemo(() => state.clientId, [state]);
  const {
    data: clientData,
    isSuccess,
    isLoading: clientLoading,
  } = useGraphQLQuery(GetClientDocument, undefined, {
    id: clientId,
  });

  const { data: userListItems } = useGetUsers(9999, 0, `%%`);
  const { data: clientUsers } = useGetClientUser(clientId);
  const { mutateAsync: updateClientWithUsers } = useUpdateClientWithUsers();

  const findInitialValues = (roleType: Department_Role_Name_Enum) => {
    const initialValues = userListItems?.user
      .filter((user) =>
        clientUsers?.client_user
          .filter((i) => i.department_role_name === roleType)
          .some((client) => client.user_id === user.id)
      )
      .map((i) => {
        return {
          id: i.id,
          label: i.name + " " + i.surname,
        };
      });

    return initialValues;
  };

  const initialUsers =
    findInitialValues(Department_Role_Name_Enum.Member)?.filter(Boolean) ?? [];
  const initialManagers =
    findInitialValues(Department_Role_Name_Enum.Manager)?.filter(Boolean) ?? [];

  const initialValues = {
    departmentName: clientData?.client_by_pk?.client_name ?? "",
    departmentManager: initialManagers?.[0],
    departmentUsers: initialUsers ?? [],
  };

  const users = userListItems?.user.map((u) => {
    return {
      label: u.name + " " + u.surname,
      id: u.id,
    };
  });

  const clearFormData = () => {
    clearFormDataFromLocalStorageHelper("editDepartmentFormData");
  };

  const { handleSubmit, control, getValues } =
    useForm<NSEditClientType.IEditClientFormValues>({
      resolver: yupResolver(editClientSchema),
      shouldFocusError: false,
      defaultValues: savedFormData ?? initialValues,
      values: savedFormData ?? initialValues,
    });

  const onSubmit: SubmitHandler<NSEditClientType.IEditClientFormValues> =
    useCallback(
      (data, event) => {
        event?.preventDefault();

        const formUsers = [
          ...(Array.isArray(data?.departmentUsers)
            ? data.departmentUsers.map((user) => ({
                id: user.id,
                role: Department_Role_Name_Enum.Member,
              }))
            : []),
          ...(data?.departmentManager
            ? [
                {
                  id: data.departmentManager.id,
                  role: Department_Role_Name_Enum.Manager,
                },
              ]
            : []),
        ];

        if (formUsers.length === 0) return;

        const existingClientUsers =
          clientUsers?.client_user.map((i) => i.id) ?? [];

        const objects = formUsers?.map((user) => ({
          client_id: clientId,
          user_id: user?.id,
          department_role_name: user?.role,
        }));

        updateClientWithUsers({
          client_id: clientId,
          client_name: data.departmentName,
          new_users: objects,
          delete_user_ids: existingClientUsers,
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.departmentUpdateSuccess")}
                type="success"
              />,
              {
                id: "departmentUpdateSuccess",
              }
            );
            clearFormData();
            navigate(-1);
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.departmentUpdateError")}
                type="error"
              />,
              {
                id: "departmentUpdateError",
              }
            );
          });
      },
      [clientId, updateClientWithUsers, clientUsers]
    );

  const handleClickCreateNewUser = () => {
    saveFormDataToLocalStorageHelper(getValues(), "editDepartmentFormData");

    navigate("/team/invite-member");
  };

  const submitHandler = () => handleSubmit(onSubmit);

  const isLoading = !isSuccess && clientLoading;

  return {
    isLoading,
    control,
    users,
    handleSubmit: submitHandler,
    handleClickCreateNewUser,
    clearFormData,
    navigate,
    getValues,
  };
};

export default useEditClientVm;
