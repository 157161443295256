import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import EmptyView from "../../../components/EmptyView/EmptyView";
import Loading from "../../../components/Loading/Loading";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSTeamStyle } from "../Team.style";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";
import Pagination from "../../../components/Pagination/Pagination";
import TableList from "../../../components/TableList/TableList";
import useTeamVm from "./Team-ListVM";
import { usePermissions } from "../../../context/PermissionContext/PermissionContext";
import { PermissionsEnum } from "../../../permissions/roles";

function TeamList() {
  const teamVM = useTeamVm();
  const permissions = usePermissions();

  return (
    <NSTeamStyle.Container>
      <NSDashboardLayoutStyle.HeaderContainer>
        <DashboardHeader
          headerButton={
            permissions.hasPermission(PermissionsEnum.ADD_MEMBER)
              ? teamVM.dashboardHeaderButton
              : null
          }
          breadcrumb={teamVM.breadcrumb}
          searchInput={teamVM.searchInput}
        />
      </NSDashboardLayoutStyle.HeaderContainer>

      {teamVM.tableItem && teamVM.tableItem.length !== 0 ? (
        <NSTeamStyle.TableContainer>
          {teamVM.isLoading ? (
            <Loading />
          ) : (
            <TableList
              headerItemList={teamVM.headerList}
              tableItemList={teamVM.tableItem}
            />
          )}
        </NSTeamStyle.TableContainer>
      ) : (
        <NSTeamStyle.EmptyContainer>
          <EmptyView
            errorMessage={teamVM.emptyView.errorMessage}
            onClick={teamVM.emptyView.onClick}
            buttonLabel={teamVM.emptyView.buttonLabel}
          />
        </NSTeamStyle.EmptyContainer>
      )}

      {teamVM.usersCount >= PAGINATION_DEFAULT_LIMIT && (
        <NSTeamStyle.PaginationContainer>
          <Pagination
            pagination={teamVM.pagination}
            isLoading={teamVM.isLoading}
            itemCount={teamVM.usersCount}
            updateQueryLimit={(start, limit) =>
              teamVM.setPagination({ start, limit })
            }
          />
        </NSTeamStyle.PaginationContainer>
      )}
    </NSTeamStyle.Container>
  );
}

export default TeamList;
