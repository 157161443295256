import { useNavigate, useParams } from "react-router-dom";
import { useGetRecruitmentFormDetail } from "../../../service/RecruitmentForm/getRecruitmentFormDetail";
import { Recruitment_Form_Question_Type_Enum } from "../../../gql/graphql";
import dayjs from "dayjs";
import capitalizeFirstLetterHelper from "../../../utils/capitalizeFirstLetterHelper";
import { NSGeneralScoreType } from "../Candidate-Score-Result/ScoreResultOverview/GeneralScore/GeneralScore.type";

const useRecruitmentFormReportVm = () => {
  const { submissionId } = useParams();
  const navigate = useNavigate();

  const { data: recruitmentFormData } = useGetRecruitmentFormDetail(
    submissionId!,
    {}
  );

  const recruitmentFormReportData =
    recruitmentFormData?.recruitment_form_submission?.[0]?.recruitment_form.recruitment_form_questions.map(
      (question) => {
        const submissionAnswers =
          recruitmentFormData?.recruitment_form_submission?.[0]?.recruitment_form_submission_answers.filter(
            (answer) => answer.recruitment_form_question_id === question.id
          );

        let answerValue = "";

        const selectedChoiceIds = submissionAnswers
          .filter(
            (answer) => answer.recruitment_form_question_choice_id !== null
          )
          .map((answer) => answer.recruitment_form_question_choice_id);

        submissionAnswers.forEach((answer) => {
          if (answer.recruitment_form_question_choice_id === null) {
            answerValue = answer.value as string;
          }
        });

        const scoreInfo =
          recruitmentFormData?.recruitment_form_submission?.[0]?.recruitment_form_submission_question_scores.find(
            (score) => score.recruitment_form_question_id === question.id
          );
        const weightedScore = scoreInfo ? scoreInfo.weighted_score : 0;

        const selectedChoices =
          question.recruitment_form_question_choices.reduce<
            {
              choice_text: string;
              id: string;
              order_index: number;
              score: number;
            }[]
          >((acc, choice) => {
            if (selectedChoiceIds.includes(choice.id)) {
              acc.push({ ...choice } as any);
            }
            return acc;
          }, []);

        const formattedValue =
          question.form_question_type ===
          Recruitment_Form_Question_Type_Enum.Date
            ? dayjs(answerValue).format("DD/MM/YYYY")
            : answerValue;

        const clientCandidate =
          recruitmentFormData?.recruitment_form_submission?.[0]
            ?.client_candidate_project.client_candidate;

        return {
          id: question.id,
          questionTitle: question.title,
          weight: question.weight,
          totalScore: weightedScore,
          value: formattedValue || String(weightedScore),
          choices: selectedChoices,
          required: question.required,
          isScored:
            question.required &&
            selectedChoices.length > 0 &&
            question.weight !== 0,
          failed: false,
          candidateFullname: capitalizeFirstLetterHelper(
            clientCandidate.name + clientCandidate.surname
          ),
          email: clientCandidate.email_address,
          phone: clientCandidate.mobile_number,
          formInfo:
            recruitmentFormData?.recruitment_form_submission?.[0]
              ?.recruitment_form,
        };
      }
    );

  const generalScoreData: NSGeneralScoreType.IGeneralScore[] =
    recruitmentFormData?.recruitment_form_submission?.length
      ? recruitmentFormData.recruitment_form_submission.map((i) => ({
          id: i.id,
          order: i.rank ?? 0,
          score: i.score,
        }))
      : [];

  const closeHandler = () => {
    navigate(-1);
  };

  return {
    recruitmentFormReportData,
    generalScoreData,
    closeHandler,
  };
};

export default useRecruitmentFormReportVm;
