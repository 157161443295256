import Config from "../config.json";
import LocalStorageHelper from "../utils/localStorageHelper";
import { JwtHelper } from "utils";

/**
 * Gets a storage access token for a specific interview
 * @param interviewId - The ID of the interview to get access for
 * @returns Object containing token or error
 */
export const getStorageAccessToken = async (
  interviewId: string
): Promise<{ token: string | null; error: string | null }> => {
  try {
    const accessToken = LocalStorageHelper.getAuthorizationToken();
    const authHeaders: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (accessToken && JwtHelper.isTokenValid(accessToken)) {
      authHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const API_URL = `${Config.REACT_APP_STORAGE_URL}/access/${interviewId}`;

    const response = await fetch(API_URL, {
      method: "GET",
      headers: authHeaders,
    });

    if (!response.ok) {
      const errorMessage = `Failed to fetch access token. Status: ${response.status}`;
      console.error(errorMessage);
      return { token: null, error: errorMessage };
    }

    const data = await response.json();
    return { token: data.token || null, error: null };
  } catch (error) {
    const errorMessage =
      "Error getting access token: " + (error as Error).message;
    console.error(errorMessage);
    return { token: null, error: errorMessage };
  }
};

/**
 * Downloads a file from a URL with optional headers
 * @param url - The URL to download from
 * @param name - The filename to save as
 * @param headers - Optional headers for the request
 * @returns A promise that resolves when download completes
 */
export const handleDownload = async (
  url: string, 
  name: string, 
  headers?: Record<string, string>
): Promise<boolean> => {
  const response = await fetch(url, {
    headers
  });
  
  if (!response.ok) {
    throw new Error(`Download failed with status: ${response.status}`);
  }
  
  const blob = await response.blob();
  if (blob.size === 0) {
    throw new Error("Downloaded file is empty");
  }
  
  const linkkk = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = linkkk;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(linkkk);
  
  return true;
};

/**
 * Polls for a report to be ready and downloads it
 * @param videoInterviewId - The ID of the video interview
 * @param generateReportFn - Function to generate the report
 * @param maxAttempts - Maximum number of polling attempts
 * @param intervalMs - Interval between polling attempts in milliseconds
 * @returns Promise that resolves when the report is downloaded
 */
export const downloadInterviewReport = async (
  videoInterviewId: string,
  generateReportFn: (params: { videoInterviewId: string }) => Promise<any>,
  maxAttempts = 10,
  intervalMs = 4000
): Promise<boolean> => {
  try {
    // Start report generation
    await generateReportFn({ videoInterviewId });
    
    let attempts = 0;
    
    while (attempts < maxAttempts) {
      try {
        const storageAccessTokenResponse = await getStorageAccessToken(videoInterviewId);
        if (!storageAccessTokenResponse.token) {
          throw new Error("Failed to get storage access token");
        }
        
        const accessToken = LocalStorageHelper.getAuthorizationToken();
        
        // Try to download the report
        await handleDownload(
          `${Config.REACT_APP_STORAGE_URL}/${videoInterviewId}/interview-report.pdf`, 
          "interview-report.pdf", 
          {
            'x-storage-access-token': storageAccessTokenResponse.token,
            'Authorization': `Bearer ${accessToken}`
          }
        );
        
        // If we get here, download succeeded
        return true;
      } catch (error) {
        console.log(`Attempt ${attempts + 1}/${maxAttempts} failed: ${(error as Error).message}`);
        attempts++;
        
        if (attempts >= maxAttempts) {
          throw new Error("Report generation timed out. Please try again later.");
        }
        
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, intervalMs));
      }
    }
    
    return false;
  } catch (error) {
    console.error("Failed to download report:", error);
    throw error;
  }
};