import { User_Role_Name_Enum } from "../gql/graphql";

export enum Roles {
  COMPANYADMIN = User_Role_Name_Enum.Companyadmin,
  DEPARTMENTADMIN = User_Role_Name_Enum.Departmentadmin,
  DEPARTMENTVIEWER = User_Role_Name_Enum.Departmentviewer,
}

export type PermissionKey = keyof typeof PERMISSIONS;

export enum PermissionsEnum {
  // Project Management
  VIEW_PROJECTS = "VIEW_PROJECTS",
  CREATE_PROJECT = "CREATE_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  VIEW_ARCHIVED_PROJECTS = "VIEW_ARCHIVED_PROJECTS",
  VIEW_CANCELLED_PROJECTS = "VIEW_CANCELLED_PROJECTS",
  VIEW_COMPLETED_PROJECTS = "VIEW_COMPLETED_PROJECTS",
  VIEW_PROJECT_STATUS_FILTER = "VIEW_PROJECT_STATUS_FILTER",
  CHANGE_PROJECT_STATUS = "CHANGE_PROJECT_STATUS",

  // Member Management
  ADD_MEMBER = "ADD_MEMBER",
  DELETE_MEMBER = "DELETE_MEMBER",
  LOWER_MEMBER_PERMISSION = "LOWER_MEMBER_PERMISSION",
  RAISE_MEMBER_PERMISSION = "RAISE_MEMBER_PERMISSION",

  // Department Management
  CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
  VIEW_DEPARTMENT = "VIEW_DEPARTMENT",
  EDIT_DEPARTMENT = "EDIT_DEPARTMENT",

  // Job Posting
  CREATE_JOB_POSTING = "CREATE_JOB_POSTING",
  VIEW_JOB_POSTINGS = "VIEW_JOB_POSTINGS",
  DELETE_JOB_POSTINGS = "DELETE_JOB_POSTINGS",

  // Candidate Management
  UPLOAD_CANDIDATE = "UPLOAD_CANDIDATE",
  DELETE_CANDIDATE_HARD = "DELETE_CANDIDATE_HARD",
  DELETE_CANDIDATE_SOFT = "DELETE_CANDIDATE_SOFT",
  VIEW_CANDIDATE_CV = "VIEW_CANDIDATE_CV",
  DOWNLOAD_CANDIDATE_CV = "DOWNLOAD_CANDIDATE_CV",
  EDIT_CANDIDATE_INFO = "EDIT_CANDIDATE_INFO",
  ADD_CANDIDATE_NOTE = "ADD_CANDIDATE_NOTE",
  MOVE_TO_LONGLIST = "MOVE_TO_LONGLIST",
  MOVE_TO_SHORTLIST = "MOVE_TO_SHORTLIST",
  MOVE_TO_APPLICANTS = "MOVE_TO_APPLICANTS",

  // List Management
  VIEW_LONGLIST = "VIEW_LONGLIST",
  VIEW_SHORTLIST = "VIEW_SHORTLIST",
  UPDATE_SHORTLIST_STATUS = "UPDATE_SHORTLIST_STATUS",
  EXPORT_LONGLIST = "EXPORT_LONGLIST",
  EXPORT_SHORTLIST = "EXPORT_SHORTLIST",

  // Interview Management
  CREATE_INTERVIEW = "CREATE_INTERVIEW",
  UPDATE_INTERVIEW_DATETIME = "UPDATE_INTERVIEW_DATETIME",
  UPDATE_INTERVIEW_QUESTIONS = "UPDATE_INTERVIEW_QUESTIONS",
  PREVIEW_INTERVIEW = "PREVIEW_INTERVIEW",
  SEND_INTERVIEW = "SEND_INTERVIEW",

  // Heatmap
  VIEW_HEATMAP = "VIEW_HEATMAP",
  SORT_HEATMAP_CANDIDATES = "SORT_HEATMAP_CANDIDATES",
  DOWNLOAD_HEATMAP_PDF = "DOWNLOAD_HEATMAP_PDF",

  // Reports
  ACCESS_CANDIDATE_REPORTS = "ACCESS_CANDIDATE_REPORTS",
  DOWNLOAD_CANDIDATE_REPORTS = "DOWNLOAD_CANDIDATE_REPORTS",
  VIEW_OVERVIEW_REPORT = "VIEW_OVERVIEW_REPORT",
  RESCORE_INTERVIEW = "RESCORE_INTERVIEW",
  VIEW_INTERVIEW_DETAIL = "VIEW_INTERVIEW_DETAIL",

  // Search
  SEARCH_CANDIDATES = "SEARCH_CANDIDATES",
  SEARCH_PROJECTS = "SEARCH_PROJECTS",

  // Settings & Support
  VIEW_SETTINGS = "VIEW_SETTINGS",
  VIEW_TEAM = "VIEW_TEAM",
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
  VIEW_PLAN = "VIEW_PLAN",
  INTEGRATION = "INTEGRATION",
  SUPPORT = "SUPPORT",
  SEND_TEST = "SEND_TEST",
}

export const PERMISSIONS: Record<PermissionsEnum, Roles[]> = {
  // Project Management
  [PermissionsEnum.VIEW_PROJECTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.CREATE_PROJECT]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],
  [PermissionsEnum.EDIT_PROJECT]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],
  [PermissionsEnum.VIEW_ARCHIVED_PROJECTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_CANCELLED_PROJECTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_COMPLETED_PROJECTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_PROJECT_STATUS_FILTER]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.CHANGE_PROJECT_STATUS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  // Member Management
  [PermissionsEnum.ADD_MEMBER]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],
  [PermissionsEnum.DELETE_MEMBER]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],
  [PermissionsEnum.LOWER_MEMBER_PERMISSION]: [Roles.COMPANYADMIN],
  [PermissionsEnum.RAISE_MEMBER_PERMISSION]: [Roles.COMPANYADMIN],

  // Department Management
  [PermissionsEnum.CREATE_DEPARTMENT]: [Roles.COMPANYADMIN],
  [PermissionsEnum.EDIT_DEPARTMENT]: [Roles.COMPANYADMIN],
  [PermissionsEnum.VIEW_DEPARTMENT]: [Roles.COMPANYADMIN],

  // Job Posting
  [PermissionsEnum.CREATE_JOB_POSTING]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_JOB_POSTINGS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.DELETE_JOB_POSTINGS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  // Candidate Management
  [PermissionsEnum.UPLOAD_CANDIDATE]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.DELETE_CANDIDATE_HARD]: [Roles.COMPANYADMIN],
  [PermissionsEnum.DELETE_CANDIDATE_SOFT]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_CANDIDATE_CV]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.DOWNLOAD_CANDIDATE_CV]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.EDIT_CANDIDATE_INFO]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.ADD_CANDIDATE_NOTE]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.MOVE_TO_LONGLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.MOVE_TO_SHORTLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.MOVE_TO_APPLICANTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  // List Management
  [PermissionsEnum.VIEW_LONGLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.VIEW_SHORTLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.UPDATE_SHORTLIST_STATUS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.EXPORT_LONGLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.EXPORT_SHORTLIST]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  // Interview Management
  [PermissionsEnum.CREATE_INTERVIEW]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.UPDATE_INTERVIEW_DATETIME]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.UPDATE_INTERVIEW_QUESTIONS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  [PermissionsEnum.PREVIEW_INTERVIEW]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.SEND_INTERVIEW]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],

  // Heatmap
  [PermissionsEnum.VIEW_HEATMAP]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.SORT_HEATMAP_CANDIDATES]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.DOWNLOAD_HEATMAP_PDF]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],

  // Reports
  [PermissionsEnum.ACCESS_CANDIDATE_REPORTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.RESCORE_INTERVIEW]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.VIEW_INTERVIEW_DETAIL]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.DOWNLOAD_CANDIDATE_REPORTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
  ],
  [PermissionsEnum.VIEW_OVERVIEW_REPORT]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],

  // Search
  [PermissionsEnum.SEARCH_CANDIDATES]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.SEARCH_PROJECTS]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],

  // Settings & Support
  [PermissionsEnum.VIEW_SETTINGS]: [Roles.COMPANYADMIN],
  [PermissionsEnum.VIEW_TEAM]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.CHANGE_LANGUAGE]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.VIEW_PLAN]: [Roles.COMPANYADMIN],
  [PermissionsEnum.INTEGRATION]: [Roles.COMPANYADMIN],
  [PermissionsEnum.SUPPORT]: [
    Roles.COMPANYADMIN,
    Roles.DEPARTMENTADMIN,
    Roles.DEPARTMENTVIEWER,
  ],
  [PermissionsEnum.SEND_TEST]: [Roles.COMPANYADMIN, Roles.DEPARTMENTADMIN],
};
