import {
  GetRecruitmentFormDetailByCcpIdDocument,
  GetRecruitmentFormDetailDocument,
} from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

export function useGetRecruitmentFormDetail(
  submissionId: string,
  options?: any
) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetRecruitmentFormDetailDocument,
    options,
    {
      submissionId,
    }
  );

  return {
    rest,
    refetch,
    data,
  };
}

export function useGetRecruitmentFormDetailByCcpId(
  ccpId: number,
  options?: any
) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetRecruitmentFormDetailByCcpIdDocument,
    options,
    {
      ccpId,
    }
  );

  return {
    rest,
    refetch,
    data,
  };
}
