import { SubmitHandler, useForm } from "react-hook-form";
import { ObjectSchema, object, string } from "yup";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NSCreateClientType } from "./Create-Client.type";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { useGetUsers } from "../../../service/Users/getUser";
import { Department_Role_Name_Enum } from "../../../gql/graphql";
import useInsertClientWithUsers from "../../../service/Clients/insertClientWithUsers";
import { saveFormDataToLocalStorageHelper } from "../../../utils/saveFormDataToLocalStorageHelper";
import { getFormDataFromLocalStorageHelper } from "../../../utils/getFormDataFromLocalStorageHelper";
import { clearFormDataFromLocalStorageHelper } from "../../../utils/clearFormDataFromLocalStorageHelper";

const createClientSchema: ObjectSchema<
  NSCreateClientType.ICreateClientFormValues | any
> = object({
  departmentName: string().required(i18n.t("forms.departmentNameError")),
  /* departmentUsers: array(object({ id: string(), label: string() }))
    .required(i18n.t("forms.departmentUsersError"))
    .default(undefined), */
}).required();

const useCreateClientVm = () => {
  const savedFormData = getFormDataFromLocalStorageHelper(
    "createDepartmentFormData"
  );

  const { handleSubmit, control, getValues } =
    useForm<NSCreateClientType.ICreateClientFormValues>({
      resolver: yupResolver(createClientSchema),
      shouldFocusError: false,
      defaultValues: savedFormData,
      values: savedFormData,
    });

  const { data: userListItems } = useGetUsers(9999, 0, `%%`);
  const users = userListItems?.user.map((u) => ({
    label: u.name + " " + u.surname,
    id: u.id,
  }));

  const navigate = useNavigate();
  const { mutateAsync: insertClientWithUsers } = useInsertClientWithUsers();

  const clearFormData = () => {
    clearFormDataFromLocalStorageHelper("createDepartmentFormData");
  };

  const onSubmit: SubmitHandler<NSCreateClientType.ICreateClientFormValues> =
    useCallback(
      (data, event) => {
        event?.preventDefault();

        const objects = [];

        if (data.departmentManager?.id) {
          objects.push({
            user_id: data.departmentManager.id,
            department_role_name: Department_Role_Name_Enum.Manager,
          });
        }

        if (Array.isArray(data.departmentUsers)) {
          const userObjects = data.departmentUsers.map((user) => ({
            user_id: user.id,
            department_role_name: Department_Role_Name_Enum.Member,
          }));
          objects.push(...userObjects);
        }

        insertClientWithUsers({
          client_name: data.departmentName,
          data: objects,
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.departmentCreateSuccess")}
                type="success"
              />,
              {
                id: "departmentCreateSuccess",
              }
            );
            clearFormData();
            navigate(-1);
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.departmentCreateError")}
                type="error"
              />,
              {
                id: "departmentCreateError",
              }
            );
          });
      },
      [insertClientWithUsers, navigate]
    );

  const handleClickCreateNewUser = () => {
    saveFormDataToLocalStorageHelper(getValues(), "createDepartmentFormData");
    navigate("/team/invite-member");
  };

  const submitHandler = () => handleSubmit(onSubmit);

  return {
    control,
    users,
    handleSubmit: submitHandler,
    handleClickCreateNewUser,
    clearFormData,
    navigate,
    getValues,
  };
};

export default useCreateClientVm;
