import { UpdateClientWithUsersDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useUpdateClientWithUsers() {
  const { mutateAsync, ...rest } = useGraphQLMutation(
    UpdateClientWithUsersDocument
  );

  return {
    mutateAsync,
    rest,
  };
}

export default useUpdateClientWithUsers;
