import Drawer from "../../../components/Drawer/Drawer";
import { NSCreateClientStyle } from "./Create-Client.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextInput from "../../../components/TextInput/TextInput";
import useCreateClientVm from "./Create-ClientVm";
import i18n from "../../../library/i18next";
import { Controller } from "react-hook-form";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { RoleBasedGuard } from "../../../components/RoleBasedGuard/RoleBasedGuard";
import { PermissionsEnum } from "../../../permissions/roles";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";

export default function CreateClient() {
  const createClientVm = useCreateClientVm();
  const { control } = createClientVm;

  return (
    <Drawer
      isAddedSelfCloseFunction
      onClickCloseIcon={() => {
        createClientVm.clearFormData();
        createClientVm.navigate(-1);
      }}
      title={i18n.t("clients.createNewDepartment")}
    >
      <NSCreateClientStyle.Container>
        <NSCreateClientStyle.CreateClientForm
          onSubmit={createClientVm.handleSubmit()}
        >
          <Controller
            name="departmentName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="departmentName"
                placeholder={i18n.t("forms.departmentName")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="departmentManager"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="basic"
                  placeholder={i18n.t("forms.departmentManager")}
                  options={createClientVm.users ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  selectedDropdownItem={defaultValues?.departmentManager}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="departmentUsers"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="withCheckbox"
                  placeholder={i18n.t("forms.departmentUsers")}
                  options={createClientVm.users ?? []}
                  onCheckedItems={(items) => field.onChange(items)}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  defaultCheckedItems={
                    createClientVm.getValues("departmentUsers") ??
                    (defaultValues?.departmentUsers as NSDropdown.IDropdownItem[])
                  }
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <RoleBasedGuard permissionKey={PermissionsEnum.ADD_MEMBER}>
            <NSCreateClientStyle.LinkButtonContainer>
              <LinkButton
                type="button"
                label={i18n.t("clients.createNewUser")}
                onClick={createClientVm.handleClickCreateNewUser}
              />
            </NSCreateClientStyle.LinkButtonContainer>
          </RoleBasedGuard>

          <SolidButton
            label={i18n.t("general.createDepartment")}
            type="submit"
            fullWidth
          />
        </NSCreateClientStyle.CreateClientForm>
      </NSCreateClientStyle.Container>
    </Drawer>
  );
}
