import styled, { css } from "styled-components";

import { Form } from "react-router-dom";
import { NSDropdownStyle } from "../../../components/Dropdown/Dropdown.style";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSEditClientStyle {
  export const Container = styled.div`
    margin-top: 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    gap: 26px;
    display: flex;
    flex-direction: column;

    ${NSDropdownStyle.DropdownItemsContainer} {
      top: 56px;
    }
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.size};
  `;

  export const Description = styled.div`
    margin-top: -8px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const EditClientForm = styled(Form)`
    gap: 26px;
    display: flex;
    flex-direction: column;
  `;

  export const InputSuffixItem = styled.div<{ isInputNotEmpty?: boolean }>`
    ${(props) =>
      props.isInputNotEmpty &&
      css`
        svg {
          path {
            stroke: ${props.theme.colors.primary.primary};
          }
        }
      `}

    ${NSMainButtonStyle.ButtonBase} {
      width: 24px;
      height: 24px;
      padding: 0;
    }
    svg {
      fill: none;
    }
  `;

  export const AddLocationContainer = styled.div`
    gap: 32px;
  `;
  export const CheckboxContainer = styled.div`
    height: 40px;
    width: 100%;
  `;

  export const LinkButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
  `;
}
