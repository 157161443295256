import React, { createContext, useContext } from "react";
import PermissionManager from "../../permissions/PermissionManager";
import { NSPermissionContextType } from "./PermissionContext.type";
import { useAuthContext } from "../AuthContext/AuthContext";
import { getMappedRole } from "../../utils/getMappedRole";
import { Roles } from "../../permissions/roles";

const PermissionContext = createContext<PermissionManager | null>(null);

export const PermissionProvider: React.FC<
  NSPermissionContextType.PermissionProviderProps
> = ({ children }) => {
  const { userRole } = useAuthContext();
  const mappedRole = getMappedRole(userRole ?? Roles.DEPARTMENTVIEWER);
  const permissionManager = new PermissionManager(mappedRole);

  return (
    <PermissionContext.Provider value={permissionManager}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = (): PermissionManager => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionProvider");
  }
  return context;
};
