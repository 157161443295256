import styled from "styled-components";

export namespace NSRecruitmentFormReportCardStyle {
  export const ScoreResultDetail = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

  export const ScoreResultDetailItem = styled.div`
    display: flex;
    flex-direction column;
    gap: 8px;
    background: ${(props) => props.theme.colors.primary.tint};
    border-radius: 16px;
    padding: 16px;
  `;

  export const RecruitmentFormQuestionItem = styled.div`
    display: flex;
    gap: 16px;
  `;

  export const RecruitmentFormQuestionScore = styled.div<{
    isScored?: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 32px;
    flex-shrink: 0;

    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.light.light};
    background: ${(props) => props.theme.colors.primary.primary};
    border-radius: 32px;

    ${(props) =>
      !props.isScored &&
      `background: ${props.theme.colors.primary.tint04};
     `}
  `;

  export const RecruitmentFormQuestionTitle = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const RecruitmentFormQuestionChoiceContainer = styled.div`
    display: flex;
    gap: 8px;
  `;

  export const RecruitmentFormQuestionChoice = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    background: ${(props) => props.theme.colors.light.light};
    border-radius: 8px;
    padding: 8px;
  `;

  export const RecruitmentFormQuestionValue = styled(
    RecruitmentFormQuestionChoice
  )`
    word-wrap: break-word;
    white-space: normal;
    max-width: max-content;
  `;

  export const RequiredBadge = styled(RecruitmentFormQuestionChoice)`
    background: ${(props) => props.theme.colors.information.blue02};
    color: ${(props) => props.theme.colors.dark.dark};
    width: max-content;
    padding: 8px;
  `;

  export const BadgeContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 8px;
  `;

  export const NotScoredBadge = styled(RequiredBadge)`
    background: ${(props) => props.theme.colors.warning.yellow02};
    gap: 8px;
    padding: 4px 8px;
  `;

  export const FailedBadge = styled(RequiredBadge)`
    background: ${(props) => props.theme.colors.error.red02};
    gap: 8px;
    padding: 4px 8px;

    svg {
      path {
        stroke: ${(props) => props.theme.colors.error.red03};
      }
    }
  `;
}
