import styled from "styled-components";

import { Form } from "react-router-dom";
import { NSDashboardHeaderStyle } from "../../components/DashboardHeader/DashboardHeader.style";
import { NSMainButtonStyle } from "../../components/MainButton/MainButton.style";
import { NSDropdownStyle } from "../../components/Dropdown/Dropdown.style";

export namespace NSTeamStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    ${NSDashboardHeaderStyle.Container} {
      ${NSMainButtonStyle.IconContainer} {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }
  `;

  export const TableContainer = styled.div`
    width: 100%;
    height: 508px;
    display: flex;
    justify-content: flex-end;

    ${NSDropdownStyle.InnerDropdownTitle} {
      border: 0;
    }
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: -24px;
  `;

  export const ModalLabel = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 390px;
    gap: 32px;
  `;

  export const EmptyContainer = styled.div`
    width: 100%;
    height: calc(100% - 90px);
  `;

  export const InviteMemberForm = styled(Form)`
    gap: 24px;
    display: flex;
    flex-direction: column;
  `;

  export const TooltipContainer = styled.div`
    .react-tooltip {
      background-color: ${(props) => props.theme.colors.light.light};
      color: ${(props) => props.theme.colors.dark.dark};
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
      z-index: 10;
    }
  `;

  export const DepartmentItem = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.shadesOfdark.dark06};

    &:last-child {
      border-bottom: 0;
    }
  `;

  export const DepartmentName = styled.div`
    white-space: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const LinkButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    margin: -12px 0 0;
  `;

  export const RoleDescriptionText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    margin: -36px 0 0;
    color: ${(props) => props.theme.colors.primary.primary};
    width: max-content;
  `;

  export const TooltipContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  `;

  export const TooltipRoleName = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const TooltipTitle = styled(TooltipRoleName)`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
  `;

  export const TooltipList = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const TooltipListItem = styled(TooltipTitle)`
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;
}
