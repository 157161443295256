import { useGraphQLQuery } from "../../hooks/useGraphQL";
import { GetClientUserDocument } from "../../gql/graphql";

function useGetClientUser(clientId: string) {
  const { data, ...rest } = useGraphQLQuery(GetClientUserDocument, undefined, {
    clientId,
  });

  return {
    rest,
    data,
  };
}

export default useGetClientUser;
