import styled from "styled-components";

export namespace NSJobDescriptionStyle {
  export const Container = styled.div`
    height: 100%;
  `;

  export const TableContainer = styled.div`
    width: 100%;
    height: 508px;
    display: flex;
    justify-content: flex-end;
  `;

  export const EmptyContainer = styled.div`
    width: 100%;
    height: calc(100% - 90px);
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  `;
}
