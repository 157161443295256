import { useCallback } from "react";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { object, ObjectSchema, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useInviteMember } from "../../../service/Users/inviteMember";
import { NSInviteMemberType } from "./Invite-Member.type";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { useGetClientDropdown } from "../../../service/Clients/getClients";
import { User_Role_Name_Enum } from "../../../gql/graphql";
import { getFormDataFromLocalStorageHelper } from "../../../utils/getFormDataFromLocalStorageHelper";
import { saveFormDataToLocalStorageHelper } from "../../../utils/saveFormDataToLocalStorageHelper";
import { clearFormDataFromLocalStorageHelper } from "../../../utils/clearFormDataFromLocalStorageHelper";

const inviteMemberSchema: ObjectSchema<NSInviteMemberType.IInviteMemberFormValues> =
  object({
    memberName: string().required("Please enter member name"),
    memberSurname: string().required("Please enter member surname"),
    emailAddress: string().required("Please enter email address"),
    department: object({ id: string(), label: string() })
      .required(i18n.t("forms.departmentError"))
      .default(undefined),
    userRole: object({ id: string(), label: string() })
      .required(i18n.t("forms.userRoleError"))
      .default(undefined),
  }).required();

const useInviteMemberVm = () => {
  const savedFormData = getFormDataFromLocalStorageHelper(
    "inviteMemberFormData"
  );
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<NSInviteMemberType.IInviteMemberFormValues>({
    resolver: yupResolver(inviteMemberSchema),
    shouldFocusError: false,
    defaultValues: savedFormData,
    values: savedFormData,
  });

  const clearFormData = () => {
    clearFormDataFromLocalStorageHelper("inviteMemberFormData");
  };

  const navigate = useNavigate();
  const closeHandler = useCallback(() => {
    clearFormData();
    navigate(-1);
  }, [navigate, clearFormData]);

  const { inviteMember } = useInviteMember();
  const { clients: clientsData } = useGetClientDropdown();

  const userRoleOptions = [
    {
      id: User_Role_Name_Enum.Companyadmin,
      label: i18n.t("forms.companyAdmin"),
    },
    {
      id: User_Role_Name_Enum.Departmentadmin,
      label: i18n.t("forms.departmentAdmin"),
    },
    {
      id: User_Role_Name_Enum.Departmentviewer,
      label: i18n.t("forms.departmentViewer"),
    },
  ];

  const departmentOptions = clientsData?.map((clientItem) => ({
    id: clientItem.dropdown.id,
    label: clientItem.dropdown.label,
  }));

  const onSubmit: SubmitHandler<NSInviteMemberType.IInviteMemberFormValues> =
    useCallback(
      async (data, event) => {
        event?.preventDefault();
        await inviteMember({
          email: data.emailAddress,
          name: data.memberName,
          surname: data.memberSurname,
          clientId: data.department.id as string,
          userRole: data.userRole.id as string,
        })
          .then((res) => {
            if (res.inviteMember?.success) {
              toast(
                <ToastAlert
                  description={i18n.t("team.memberInviteSuccess")}
                  type="success"
                />,
                {
                  id: "inviteMemberSuccess",
                }
              );
              clearFormData();
              navigate(-1);
            } else {
              toast(
                <ToastAlert
                  description={i18n.t("team.memberInviteError")}
                  type="error"
                />,
                {
                  id: "inviteMemberError",
                }
              );
            }
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("team.memberInviteError")}
                type="error"
              />,
              {
                id: "inviteMemberError",
              }
            );
          });
      },
      [inviteMember, navigate]
    );

  const handleClickCreateDepartment = () => {
    saveFormDataToLocalStorageHelper(getValues(), "inviteMemberFormData");
    navigate("/clients/create");
  };

  const submitHandler = () => handleSubmit(onSubmit);

  return {
    formErrors: errors,
    control,
    departmentOptions,
    userRoleOptions,
    closeHandler,
    setValue,
    handleSubmit: submitHandler,
    handleClickCreateDepartment,
  };
};

export default useInviteMemberVm;
