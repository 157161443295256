import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { Video_Interview_Status_Enum } from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import useGetVideoInterview from "../../../service/VideoInterview/getVideoInterview";
import useGetVideoInterviewVideos from "../../../service/VideoInterview/getVideoInterviewVideos";
import useInsertVideoInterviewStatus from "../../../service/VideoInterview/insertVideoInterviewStatus";
import capitalizeFirstLetterHelper from "../../../utils/capitalizeFirstLetterHelper";

const useCandidateVideoPreviewVm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const interviewId = useMemo(() => state?.interviewId, [state]);
  const { projectId } = state ?? {};

  const { mutateAsync } = useInsertVideoInterviewStatus();
  const { data } = useGetVideoInterview(interviewId);
  const [videoData, setVideoData] = useState<{
    url: string;
    accessToken: string | null;
    authToken: string;
  }>();
  const [error, setError] = useState<string>("");
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(1);

  const closeHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onClickStartButton = useCallback(() => {
    navigate(
      `/projects/detail/${projectId}/candidate/candidate-reviewer-questions/${interviewId}`,
      {
        state: { projectId, interviewId },
      }
    );
  }, [interviewId, navigate, projectId]);

  const onSelectVideo = (index: number) => {
    setActiveQuestionIndex(index + 1);
  };

  const updateInterviewStatus = useCallback(
    async (
      status: Video_Interview_Status_Enum,
      successKey: string,
      errorKey: string
    ) => {
      try {
        await mutateAsync({ vid: interviewId, status });
        toast(<ToastAlert description={i18n.t(successKey)} type="success" />, {
          id: successKey,
        });
      } catch {
        toast(<ToastAlert description={i18n.t(errorKey)} type="error" />, {
          id: errorKey,
        });
      }
    },
    [interviewId, mutateAsync]
  );

  const onClickInsufficientContent = () =>
    updateInterviewStatus(
      Video_Interview_Status_Enum.InsufficientContent,
      "videoInterview.insufficientContentSuccess",
      "videoInterview.insufficientContentError"
    );

  const onClickInappropriateContent = () =>
    updateInterviewStatus(
      Video_Interview_Status_Enum.InappropriateContent,
      "videoInterview.inappropriateContentSuccess",
      "videoInterview.inappropriateContentError"
    );

  const candidateName = useMemo(() => {
    const candidate =
      data?.video_interview_by_pk?.client_candidate_project?.client_candidate;
    return candidate
      ? capitalizeFirstLetterHelper(`${candidate.name} ${candidate.surname}`)
      : "";
  }, [data]);

  const videoFileInfos = useMemo(
    () =>
      data?.video_interview_by_pk?.video_interview_questions
        ?.filter((q) => q.review_metric_question.is_shown_to_candidate)
        ?.map((videoItem) => ({
          interviewId: data?.video_interview_by_pk?.id,
          rmqId: videoItem.review_metric_question.id,
          question: videoItem.review_metric_question.question,
        })) ?? [],
    [data]
  );

  const fetchVideoUrls = async () => {
    try {
      if (videoFileInfos.length === 0) return;

      const selectedVideoFileInfo = videoFileInfos?.[activeQuestionIndex - 1];
      const { videoData, error } = await useGetVideoInterviewVideos([
        selectedVideoFileInfo,
      ]);

      if (error) {
        toast(
          <ToastAlert
            description={i18n.t("interview.loadingVideoError")}
            type="error"
          />,
          { id: "loadingVideoError" }
        );
      }
      setError(error ?? "");
      setVideoData(videoData?.[0] ?? null);
    } catch (err) {
      toast(
        <ToastAlert
          description={i18n.t("interview.loadingVideoError")}
          type="error"
        />,
        { id: "loadingVideoError" }
      );
    }
  };

  useEffect(() => {
    fetchVideoUrls();
  }, [activeQuestionIndex, videoFileInfos]);

  return {
    description: i18n.t("candidate.description"),
    candidateName,
    videoData,
    videoFileInfos,
    error,
    onClickStartButton,
    closeHandler,
    onClickInappropriateContent,
    onClickInsufficientContent,
    onSelectVideo,
  };
};

export default useCandidateVideoPreviewVm;
