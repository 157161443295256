import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import Modal from "../../../components/Modal/Modal";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import SolidButton from "../../../components/SolidButton/SolidButton";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSClientListStyle } from "../List/Client-List.style";
import { NSClientDetailStyle } from "./Client-Detail.style";
import useClientDetailVm from "./Client-DetailVM";
import i18n from "../../../library/i18next";
import capitalizeFirstLetterHelper from "../../../utils/capitalizeFirstLetterHelper";

export default function ClientDetail() {
  const clientDetailVM = useClientDetailVm();

  return (
    <>
      <NSClientDetailStyle.Container>
        <NSDashboardLayoutStyle.HeaderContainer>
          <DashboardHeader
            breadcrumb={clientDetailVM.breadcrumb}
            contextMenuHOC={clientDetailVM.contextMenuItems}
          />
        </NSDashboardLayoutStyle.HeaderContainer>

        <NSClientDetailStyle.ContentContainer>
          <NSClientDetailStyle.InfoBox>
            <NSClientDetailStyle.SecondaryTitle>
              {i18n.t("forms.departmentName")}
            </NSClientDetailStyle.SecondaryTitle>

            <NSClientDetailStyle.Information>
              {clientDetailVM.clientInfo.departmentName}
            </NSClientDetailStyle.Information>

            {clientDetailVM.clientInfo.departmentManager && (
              <NSClientDetailStyle.DepartmentManager>
                <NSClientDetailStyle.SecondaryTitle>
                  {i18n.t("forms.departmentManager")}
                </NSClientDetailStyle.SecondaryTitle>

                <NSClientDetailStyle.Information>
                  {capitalizeFirstLetterHelper(
                    clientDetailVM.clientInfo.departmentManager?.name +
                      " " +
                      clientDetailVM.clientInfo.departmentManager?.surname
                  )}
                </NSClientDetailStyle.Information>
              </NSClientDetailStyle.DepartmentManager>
            )}
          </NSClientDetailStyle.InfoBox>
        </NSClientDetailStyle.ContentContainer>
      </NSClientDetailStyle.Container>

      <Modal
        isDarkBackgroundActive
        isOpen={clientDetailVM.isDeleteModalOpen}
        onClickCloseButton={clientDetailVM.onClickCloseButton}
      >
        <NSClientListStyle.ModalContainer>
          <NSClientListStyle.ModalTitle>
            {i18n.t("clients.deleteDepartment")}
          </NSClientListStyle.ModalTitle>
          {i18n.t("clients.deleteDepartmentLabel")}
          <NSClientListStyle.ModalButtonContainer>
            <SolidButton
              fullWidth
              label={i18n.t("general.delete")}
              onClick={() => clientDetailVM.onDeleteClient()}
            />

            <OutlinedButton
              fullWidth
              label={i18n.t("general.giveUp")}
              onClick={() => clientDetailVM.onClickCloseButton()}
            />
          </NSClientListStyle.ModalButtonContainer>
        </NSClientListStyle.ModalContainer>
      </Modal>
    </>
  );
}
