import { JwtHelper } from "utils";
import LocalStorageHelper from "../../utils/localStorageHelper";
import Config from "../../config.json";

type IFileInfo = {
  interviewId: string;
  rmqId: number;
};

type IUseGetVideoInterviewVideosResponse = {
  videoData: { url: string; accessToken: string | null; authToken: string }[];
  error: string | null;
};

const getStorageAccessToken = async (
  interviewId: string
): Promise<{ token: string | null; error: string | null }> => {
  try {
    const accessToken = LocalStorageHelper.getAuthorizationToken();
    const authHeaders: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (accessToken && JwtHelper.isTokenValid(accessToken)) {
      authHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const API_URL = `${Config.REACT_APP_STORAGE_URL}/access/${interviewId}`;

    const response = await fetch(API_URL, {
      method: "GET",
      headers: authHeaders,
    });

    if (!response.ok) {
      const errorMessage = `Failed to fetch access token. Status: ${response.status}`;
      console.error(errorMessage);
      return { token: null, error: errorMessage };
    }

    const data = await response.json();
    return { token: data.token || null, error: null };
  } catch (error) {
    const errorMessage =
      "Error getting access token: " + (error as Error).message;
    console.error(errorMessage);
    return { token: null, error: errorMessage };
  }
};

const getVideoUrls = async (
  accessToken: string | null,
  interviewId: string,
  rmqId: number
): Promise<{
  url: string | null;
  accessToken: string | null;
  authToken: string;
  error: string | null;
}> => {
  const authToken = LocalStorageHelper.getAuthorizationToken() ?? "";

  if (!authToken || !JwtHelper.isTokenValid(authToken)) {
    const errorMessage = "No valid auth token provided.";
    console.error(errorMessage);
    return { url: null, accessToken, authToken, error: errorMessage };
  }

  try {
    const videoUrl = `${Config.REACT_APP_STORAGE_URL}/${interviewId}/${rmqId}/master.m3u8`;

    const response = await fetch(videoUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        "x-storage-access-token": accessToken ?? "",
      },
    });

    if (!response.ok) {
      const errorMessage = `Failed to fetch video. Status: ${response.status}`;
      console.error(errorMessage);
      return { url: null, accessToken, authToken, error: errorMessage };
    }

    return { url: videoUrl, accessToken, authToken, error: null };
  } catch (error) {
    const errorMessage =
      "Error fetching video URLs: " + (error as Error).message;
    console.error(errorMessage);
    return { url: null, accessToken, authToken, error: errorMessage };
  }
};

async function useGetVideoInterviewVideos(
  fileInfos: IFileInfo[]
): Promise<IUseGetVideoInterviewVideosResponse> {
  let videoData: {
    url: string;
    accessToken: string | null;
    authToken: string;
  }[] = [];
  let error: string | null = null;

  try {
    for (const { interviewId, rmqId } of fileInfos) {
      const { token: accessToken, error: tokenError } =
        await getStorageAccessToken(interviewId);

      if (tokenError) {
        error = tokenError;
        continue;
      }

      const {
        url,
        accessToken: finalAccessToken,
        authToken,
        error: videoError,
      } = await getVideoUrls(accessToken, interviewId, rmqId);

      if (videoError) {
        error = videoError;
        continue;
      }

      if (url) {
        videoData.push({ url, accessToken: finalAccessToken, authToken });
      }
    }
  } catch (err) {
    console.error("Error in useGetVideoInterviewVideos:", err);
    error = err instanceof Error ? err.message : "An unknown error occurred.";
  }

  return { videoData, error };
}

export default useGetVideoInterviewVideos;
