import { ReactNode } from "react";
import { Video_Interview_Type_Enum } from "../../../../gql/graphql";
import i18n from "../../../../library/i18next";

export namespace NSCandidateListType {
  export type DeleteCandidateResponse = {
    delete_client_candidate_project: {
      returning: number[];
    };
  };
  export interface ICandidateList {
    type: "appliedlist" | "longlist" | "shortlist";
  }

  export interface RowCheckboxStates {
    [key: string]: boolean;
  }

  export const ScorePageBaseType = {
    COMPETENCY_INTERVIEW: "competency-interview",
    ENGLISH_TEST: "english-test",
    RECRUITMENT_FORM: "recruitment-form",
  };

  export const InterviewStatusType = {
    CANDIDATE_IN_PROGRESS: "CANDIDATE_IN_PROGRESS",
    EXPIRED: "EXPIRED",
    INAPPROPRIATE_CONTENT: "INAPPROPRIATE_CONTENT",
    INSUFFICIENT_CONTENT: "INSUFFICIENT_CONTENT",
    IN_REVIEW: "IN_REVIEW",
    LINK_SENT: "LINK_SENT",
    NOT_SET: "NOT_SET",
    SCORED: "SCORED",
    VIDEO_SENT: "VIDEO_SENT",
    CANDIDATE_NOT_INTERESTED: "CANDIDATE_NOT_INTERESTED",
    VIDEO_RECEIVED: "VIDEO_RECEIVED",
    NOT_COMPLETED: "NOT_COMPLETED",
    NO_AI_REVIEW_WANTED: "NO_AI_REVIEW_WANTED",
  };

  export const CandidateResolutionType = {
    CANDIDATE_WITHDREW: "CANDIDATE_WITHDREW",
    DOES_NOT_MEET_CRITERIA: "DOES_NOT_MEET_CRITERIA",
    HIRED: "HIRED",
    INTERVIEW_SUCCESSFUL: "INTERVIEW_SUCCESSFUL",
    INTERVIEW_UNSUCCESSFUL: "INTERVIEW_UNSUCCESSFUL",
    NO_SHOW: "NO_SHOW",
    OFFER_DECLINED: "OFFER_DECLINED",
    OFFER_MADE: "OFFER_MADE",
  };

  export interface IModalContent {
    title: string;
    message: ReactNode;
    actionButtonLabel: string;
    closeButtonLabel: string;
    actionButtonHandler: () => void;
  }

  export enum ITypeOfSelection {
    All = "all",
    Page = "page",
  }

  export const RECRUITMENT_FORM = "RECRUITMENT_FORM";

  export const VIDEO_INTERVIEW_TYPE_LABELS: Record<
    Video_Interview_Type_Enum | string,
    string
  > = {
    [Video_Interview_Type_Enum.CompetencyAssessment]: i18n.t(
      "projects.competencyInterview"
    ),
    [Video_Interview_Type_Enum.EnglishAssessment]: i18n.t(
      "projects.englishTest"
    ),
    [RECRUITMENT_FORM]: i18n.t("projects.recruitmentForm"),
  };
}
