export const saveFormDataToLocalStorageHelper = (
  formData: any,
  key: string
) => {
  try {
    localStorage.setItem(key, JSON.stringify(formData));
  } catch (error) {
    console.log(error);
  }
};
