import { PERMISSIONS, Roles, PermissionsEnum } from "./roles";

class PermissionManager {
  private userRole: Roles;

  constructor(userRole: Roles) {
    this.userRole = userRole;
  }

  hasPermission(permissionKey: PermissionsEnum): boolean {
    if (!(permissionKey in PERMISSIONS)) {
      console.warn(`Permission key "${permissionKey}" not found`);
      return false;
    }
    return PERMISSIONS[permissionKey].includes(this.userRole);
  }

  hasPermissions(permissionKeys: PermissionsEnum[]): boolean {
    return permissionKeys.every((key) => this.hasPermission(key));
  }

  getAllPermissions(): PermissionsEnum[] {
    return Object.entries(PERMISSIONS)
      .filter(([_key, roles]) => roles.includes(this.userRole))
      .map(([permission]) => permission as PermissionsEnum);
  }
}

export default PermissionManager;
