import Icon from "../../../../components/Icon/Icon";
import i18n from "../../../../library/i18next";
import { NSRecruitmentFormReportCardStyle } from "./RecruitmentFormReportCard.style";
import { NSRecruitmentFormReportCardType } from "./RecruitmentFormReportCard.type";

const RecruitmentFormReportCard = ({
  data,
}: NSRecruitmentFormReportCardType.IRecruitmentFormReportCardData) => {
  return (
    <NSRecruitmentFormReportCardStyle.ScoreResultDetail>
      {data &&
        data.length > 0 &&
        data.map((question) => {
          return (
            <NSRecruitmentFormReportCardStyle.ScoreResultDetailItem
              key={question.id}
            >
              <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionItem>
                <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionScore
                  isScored={question.isScored}
                >
                  {question.totalScore}
                </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionScore>
                <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionTitle>
                  {question.questionTitle}
                  {question.choices && question.choices.length > 0 ? (
                    <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionChoiceContainer>
                      {question.choices.map((choice) => {
                        return (
                          <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionChoice>
                            {choice.choice_text}
                          </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionChoice>
                        );
                      })}
                    </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionChoiceContainer>
                  ) : (
                    <NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionValue>
                      {question.value}
                    </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionValue>
                  )}
                  <NSRecruitmentFormReportCardStyle.BadgeContainer>
                    <NSRecruitmentFormReportCardStyle.RequiredBadge>
                      {i18n.t(
                        question.required
                          ? "candidate.required"
                          : "candidate.optional"
                      )}
                    </NSRecruitmentFormReportCardStyle.RequiredBadge>
                    {!question.isScored && (
                      <NSRecruitmentFormReportCardStyle.NotScoredBadge>
                        <Icon name="TriangleWarning" />
                        {i18n.t("candidate.notScored")}
                      </NSRecruitmentFormReportCardStyle.NotScoredBadge>
                    )}
                    {/* {question.failed && (
                      <NSRecruitmentFormReportCardStyle.FailedBadge>
                        <Icon name="TriangleWarning" />
                        {i18n.t("candidate.candidateFailed")}
                      </NSRecruitmentFormReportCardStyle.FailedBadge>
                    )} */}
                  </NSRecruitmentFormReportCardStyle.BadgeContainer>
                </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionTitle>
              </NSRecruitmentFormReportCardStyle.RecruitmentFormQuestionItem>
            </NSRecruitmentFormReportCardStyle.ScoreResultDetailItem>
          );
        })}
    </NSRecruitmentFormReportCardStyle.ScoreResultDetail>
  );
};

export default RecruitmentFormReportCard;
