import i18n from "../../library/i18next";
import capitalizeFirstLetterHelper from "../../utils/capitalizeFirstLetterHelper";
import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import { NSClientInfoCardStyle } from "./ClientInfoCard.style";
import { NSClientInfoCardType } from "./ClientInfoCard.type";

function ClientInfoCard({
  departmentManager,
  departmentName,
  contextMenu,
  onClick,
  isDefault,
}: NSClientInfoCardType.IClientInfoCard) {
  return (
    <NSClientInfoCardStyle.Container onClick={onClick}>
      <NSClientInfoCardStyle.HeaderContainer>
        {contextMenu && (
          <ContextMenuHOC
            items={contextMenu.items}
            onItemClick={contextMenu.onItemClick}
            type={contextMenu.type}
            buttonLabel={contextMenu.buttonLabel}
            isScrollable={contextMenu.isScrollable}
            title={contextMenu.title}
            showSearch={contextMenu.showSearch}
            containAddNewItemButton={contextMenu.containAddNewItemButton}
            onCheckedItems={contextMenu.onCheckedItems}
            onContextMenuOpen={contextMenu.onContextMenuOpen}
            shouldStopPropagation
          >
            <NSClientInfoCardStyle.HeaderLeft>
              {departmentName}
              {isDefault && (
                <NSClientInfoCardStyle.DefaultBadge>
                  {i18n.t("general.default")}
                </NSClientInfoCardStyle.DefaultBadge>
              )}
            </NSClientInfoCardStyle.HeaderLeft>
          </ContextMenuHOC>
        )}
      </NSClientInfoCardStyle.HeaderContainer>
      {departmentManager && (
        <NSClientInfoCardStyle.DepartmentManager>
          <NSClientInfoCardStyle.DepartmentManagerTitle>
            {i18n.t("forms.departmentManager")}
          </NSClientInfoCardStyle.DepartmentManagerTitle>
          <NSClientInfoCardStyle.InnerText>
            {capitalizeFirstLetterHelper(
              departmentManager?.name + " " + departmentManager?.surname
            ) ?? ""}
          </NSClientInfoCardStyle.InnerText>
        </NSClientInfoCardStyle.DepartmentManager>
      )}
    </NSClientInfoCardStyle.Container>
  );
}

export default ClientInfoCard;
