import { SubmitHandler, useForm } from "react-hook-form";
import { date, object, string } from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";

import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { NSCreateProjectType } from "./CreateProject.type";
import { useGetClientDropdown } from "../../../service/Clients/getClients";
import useInsertProject from "../../../service/Project/insertProject";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import {
  Language_Enum,
  UpdateProjectInterviewTemplateDocument,
} from "../../../gql/graphql";
import useGetCitiesDropdown from "../../../service/Cities/getCities";
import useGetLanguagesDropdown from "../../../service/Languages/getLanguages";
import { useGetRolesDropdown } from "../../../service/Roles/getRoles";
import { useThrottle } from "@uidotdev/usehooks";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import useGetInterviewTemplatesDropdown from "../../../service/InterviewTemplates/getInterviewTemplates";
import useHomeVm from "../../Home/HomeVM";

const useCreateProjectVm = () => {
  const [roleSearchValue, setRoleSearchValue] = useState<string>("");
  const [newProjectId, setNewProjectId] = useState<string>("");
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const throttledRoleSearchValue = useThrottle(roleSearchValue, 300);
  const { mutateAsync } = useInsertProject();
  const homeVm = useHomeVm();
  const { clients: clientsData, rest: clientsDataRest } =
    useGetClientDropdown();
  const { cities: citiesData, rest: citiesDataRest } = useGetCitiesDropdown();
  const { languages: languagesData, rest: languagesDataRest } =
    useGetLanguagesDropdown();
  const { roles: rolesData } = useGetRolesDropdown(throttledRoleSearchValue);
  const {
    interviewTemplates: interviewTemplatesData,
    rest: interviewTemplatesDataRest,
  } = useGetInterviewTemplatesDropdown(homeVm.activeUser.companyId!);

  /* const { data: usersData, rest: usersRest } = useGetUsers(9999, 0, "%%"); */
  const searchParams = new URLSearchParams(search);
  const projectId = searchParams.get("projectId");
  const seePreviewTemplates = searchParams.get("seePreviewTemplates");

  const clients = useMemo(
    () =>
      clientsData?.map((clientItem) => ({
        id: clientItem.dropdown.id,
        label: clientItem.dropdown.label,
      })),

    [clientsData]
  );

  const cities = useMemo(
    () =>
      citiesData?.map((cityItem) => ({
        id: cityItem.dropdown.id,
        label: cityItem.dropdown.label,
      })),

    [citiesData]
  );

  const languages = useMemo(
    () =>
      languagesData?.map((languageItem) => ({
        id: languageItem.dropdown.id,
        label: languageItem.dropdown.label,
      })),

    [languagesData]
  );

  const roles = useMemo(
    () =>
      rolesData?.map((roleItem) => ({
        id: roleItem.dropdown.id,
        label: roleItem.dropdown.label,
      })),

    [rolesData]
  );

  const defaultSelectedClient = useMemo(
    () =>
      clients?.find(
        (i) => i.id === clientsData?.find((item) => item.isDefault)?.dropdown.id
      ),

    [clients, clientsData]
  );

  const interviewTemplates = useMemo(
    () =>
      interviewTemplatesData?.map((item) => ({
        id: item.dropdown.id,
        label: item.dropdown.label,
      })),

    [interviewTemplatesData]
  );

  /*  const users = useMemo(
    () =>
      usersData?.user.map((userItem) => ({
        id: userItem.id,
        label: `${userItem.name} ${userItem.surname}`,
      })),
    [usersRest]
  ); */
  const prevPath = state?.prevPath;

  // TODO: remove any from type
  const createProjectSchema:
    | NSCreateProjectType.ICreateProjectFormValues
    | any = object({
    projectName: string().required(i18n.t("forms.projectNameError")),
    client: object({ id: string(), label: string() })
      .required(i18n.t("forms.selectDepartmentError"))
      .default(defaultSelectedClient),
    /* users: array(object({ id: string(), label: string() }))
      .required(i18n.t("forms.selectUserError"))
      .default(undefined), */
    role: object({ id: string(), label: string() })
      .required(i18n.t("forms.selectRoleError"))
      .default(undefined),
    timePeriod: object({ startDate: date(), endDate: date() }).notRequired(),
    description: string().notRequired(),
    interviewTemplate: object({ id: string(), label: string() }).notRequired(),
    language: string()
      .oneOf(Object.values(Language_Enum))
      .label(i18n.t("forms.selectLanguageError"))
      .required()
      .default(Language_Enum.Tr),
  }).required();
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    setFocus,
    control,
  } = useForm<NSCreateProjectType.ICreateProjectFormValues>({
    resolver: yupResolver(createProjectSchema),
    shouldFocusError: false,
  });

  const onSubmit: SubmitHandler<NSCreateProjectType.ICreateProjectFormValues> =
    useCallback(
      (data, event) => {
        /* const selectedUserIds = Array.isArray(data.users)
          ? data.users.map((user) => ({ user_id: user.id }))
          : [];
 */
        const defaultDates = [
          new Date(),
          new Date(new Date().setDate(new Date().getDate() + 56)),
        ];
        event?.preventDefault();
        mutateAsync({
          endDate: data.timePeriod.endDate ?? defaultDates[1],
          startDate: data.timePeriod.startDate ?? defaultDates[0],
          description: data.description,
          projectName: data.projectName,
          clientId: data.client.id,
          language: data.language as Language_Enum,
          role_id: data.role.id,
          interviewTemplateId: seePreviewTemplates
            ? (data.interviewTemplate?.id as number)
            : null,
          /* data: selectedUserIds, */
        })
          .then((res) => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectCreateSuccess")}
                type="success"
              />,

              {
                id: "createprojectSuccess",
              }
            );
            setNewProjectId(res.insert_project?.returning[0].id);
            navigate(
              seePreviewTemplates
                ? `/projects/detail/${res.insert_project?.returning[0].id}/overview`
                : `/projects/create-interview?projectId=${res.insert_project?.returning[0].id}`,
              {
                state: { projectId: res.insert_project?.returning[0].id },
              }
            );
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectCreateError")}
                type="error"
              />,

              {
                id: "createProjectError",
              }
            );
          });
      },
      [mutateAsync, navigate, prevPath, seePreviewTemplates]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const { mutateAsync: updateInterviewTemplate } = useGraphQLMutation(
    UpdateProjectInterviewTemplateDocument
  );

  const onRoleSearchChange = (value: string | undefined) => {
    setRoleSearchValue(value ?? "");
  };

  const onClickSeePreviewTemplates = () => {
    navigate(`/projects/create?seePreviewTemplates=true`, {
      state: { projectId: newProjectId || projectId },
    });
  };

  const onClickNotSeePreviewTemplates = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFocus("projectName");
  }, [setFocus]);

  const onClickPreInterviewModule = (language: Language_Enum) => {
    updateInterviewTemplate({
      id: newProjectId || projectId,
      interview_template_id: language === Language_Enum.En ? 2 : 1,
    })
      .then(() => {
        navigate(`/projects/detail/${newProjectId || projectId}/long-list`, {
          state: { projectId: newProjectId || projectId },
        });
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.projectUpdateError")}
            type="error"
          />,

          {
            id: "projectUpdateError",
          }
        );
      });
  };

  const onClickLongListModule = () => {
    navigate(`/projects/interview?projectId=${newProjectId || projectId}`, {
      state: { projectId: newProjectId || projectId },
    });
  };

  const onClickLanguageTestModule = () => {
    navigate(`/projects/detail/${newProjectId || projectId}/long-list`, {
      state: { projectId: newProjectId || projectId },
    });
  };

  const onClickCreateInterviewLater = () => {
    navigate(`/projects/detail/${newProjectId || projectId}/overview`, {
      state: { projectId: newProjectId || projectId },
    });
  };

  const closeDrawer = () => {
    navigate("/projects");
  };

  const onClickCloseCreateInterviewModal = () => {
    navigate(-1);
  };

  const isLoading =
    clientsDataRest.isLoading ||
    citiesDataRest.isLoading ||
    languagesDataRest.isLoading ||
    interviewTemplatesDataRest.isLoading;

  return {
    formErrors: errors,
    cities,
    roles,
    clients,
    /*    users, */
    languages,
    defaultSelectedClient,
    isLoading,
    interviewTemplates,
    control,
    seePreviewTemplates,
    closeDrawer,
    onClickSeePreviewTemplates,
    onClickNotSeePreviewTemplates,
    setValue,
    handleSubmit: submitHandler,
    onClickPreInterviewModule,
    onClickLongListModule,
    onClickCreateInterviewLater,
    onRoleSearchChange,
    onClickCloseCreateInterviewModal,
    onClickLanguageTestModule,
    getValues,
  };
};

export default useCreateProjectVm;
