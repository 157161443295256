import styled from "styled-components";

export namespace NSGeneralScoreStyle {
  export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 16px 24px;
  `;

  export const Header = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    text-align: left;
    width: 100%;
  `;

  export const Content = styled.div`
    display: flex;
    margin-top: 12px;
  `;

  export const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const Text = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const Bold = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    padding: 0 4px;
  `;
}
