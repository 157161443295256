import styled from "styled-components";
import colors from "../../style/colors";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSProjectCardStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    font-family: greycliff-cf, sans-serif;
    background-color: ${colors.light.light};
    width: 100%;
    height: 100%;
    padding: 22px;
    border-radius: 16px;
    border: 2px solid ${colors.light.light};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &:hover {
      border-color: ${colors.primary.primary};
    }
  `;

  export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${colors.dark.dark};

    ${NSMainButtonStyle.ButtonBase} {
      margin-right: 0;
    }
  `;

  export const InnerText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${colors.neutrals.grey06};
    margin-bottom: 4px;
    padding-right: 20px;
    width: 100%;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  export const ProjectName = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${colors.dark.dark};
  `;

  export const AdditionalInfoContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `;

  export const AdditionalInfo = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${colors.dark.dark};
    padding-right: 20px;
  `;

  export const AdditionalInfoLine = styled.div`
    margin-right: 3px;
  `;

  export const Deadline = styled.div`
    color: ${colors.neutrals.grey05};
  `;

  export const AvatarContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  `;
}
