import Drawer from "../../../components/Drawer/Drawer";
import Loading from "../../../components/Loading/Loading";
import { NSEditClientStyle } from "./Edit-Client.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextInput from "../../../components/TextInput/TextInput";
import useEditClientVm from "./Edit-ClientVm";
import i18n from "../../../library/i18next";
import { Controller } from "react-hook-form";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { RoleBasedGuard } from "../../../components/RoleBasedGuard/RoleBasedGuard";
import { PermissionsEnum } from "../../../permissions/roles";

export default function EditClient() {
  const editClientVm = useEditClientVm();
  const { control } = editClientVm;

  return editClientVm.isLoading ? (
    <Loading />
  ) : (
    <Drawer
      isAddedSelfCloseFunction
      onClickCloseIcon={() => {
        editClientVm.clearFormData();
        editClientVm.navigate(-1);
      }}
      title={i18n.t("clients.editDepartment")}
    >
      <NSEditClientStyle.Container>
        <NSEditClientStyle.EditClientForm
          onSubmit={editClientVm.handleSubmit()}
        >
          <Controller
            name="departmentName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="departmentName"
                placeholder={i18n.t("forms.departmentName")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="departmentManager"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="basic"
                  placeholder={i18n.t("forms.departmentManager")}
                  options={editClientVm.users ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  onCheckedItems={(items) => field.onChange(items)}
                  selectedDropdownItem={defaultValues?.departmentManager}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="departmentUsers"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="withCheckbox"
                  placeholder={i18n.t("forms.departmentUsers")}
                  options={editClientVm.users ?? []}
                  onCheckedItems={(items) => {
                    field.onChange(items);
                  }}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  defaultCheckedItems={
                    editClientVm.getValues("departmentUsers") ??
                    (defaultValues?.departmentUsers as NSDropdown.IDropdownItem[])
                  }
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <RoleBasedGuard permissionKey={PermissionsEnum.ADD_MEMBER}>
            <NSEditClientStyle.LinkButtonContainer>
              <LinkButton
                type="button"
                label={i18n.t("clients.createNewUser")}
                onClick={editClientVm.handleClickCreateNewUser}
              />
            </NSEditClientStyle.LinkButtonContainer>
          </RoleBasedGuard>
          <SolidButton
            label={i18n.t("clients.editDepartment")}
            type="submit"
            fullWidth
          />
        </NSEditClientStyle.EditClientForm>
      </NSEditClientStyle.Container>
    </Drawer>
  );
}
