import Modal2 from "../../../../components/Modal/Modal2";
import useVideoPreviewVM from "./Video-PreviewVm";
import Logo from "../../../../components/Logo/Logo";
import { NSVideoPreviewStyle } from "./Video-Preview.style";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";

export default function VideoPreview() {
  const videoPreviewVM = useVideoPreviewVM();

  return (
    <Modal2 onClose={videoPreviewVM.closeHandler} isFullWidth>
      <NSVideoPreviewStyle.Container>
        <NSVideoPreviewStyle.LogoContainer>
          <Logo type="onlyIcon" />
        </NSVideoPreviewStyle.LogoContainer>
        <NSVideoPreviewStyle.VideoContainer>
          <VideoPlayer
            playlistData={videoPreviewVM.videoFileInfos}
            urls={
              videoPreviewVM.videoData?.url
                ? [videoPreviewVM.videoData?.url]
                : []
            }
            accessToken={videoPreviewVM.videoData?.accessToken ?? ""}
            authToken={videoPreviewVM.videoData?.authToken ?? ""}
            onSelectVideo={videoPreviewVM.onSelectVideo}
            isLoading={
              !(videoPreviewVM.videoData && videoPreviewVM.videoData?.url)
            }
            error={videoPreviewVM.error}
          />
        </NSVideoPreviewStyle.VideoContainer>
        <NSVideoPreviewStyle.QuestionContainer>
          <NSVideoPreviewStyle.Content>
            {videoPreviewVM.interviewQuestions?.map((item, index) => {
              return (
                <NSVideoPreviewStyle.List key={item.id}>
                  <NSVideoPreviewStyle.ListItem>
                    {index + 1}
                    {". "}
                    {item.review_metric_question.question}{" "}
                  </NSVideoPreviewStyle.ListItem>
                </NSVideoPreviewStyle.List>
              );
            })}
          </NSVideoPreviewStyle.Content>
        </NSVideoPreviewStyle.QuestionContainer>
      </NSVideoPreviewStyle.Container>
    </Modal2>
  );
}
