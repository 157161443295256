import { useState } from "react";
import { BarChartHorizontal, ChartRadar, ToggleButton } from "ui";
import GeneralScore from "./GeneralScore/GeneralScore";
import ScoreDistribution from "./ScoreDistribution/ScoreDistribution";
import { NSScoreResultOverviewStyle } from "./ScoreResultOverview.style";
import Icon from "../../../../components/Icon/Icon";
import i18n from "../../../../library/i18next";
import useCandidateScoreResultVm from "../Candidate-Score-ResultVM";
import { NSCandidateListType } from "../../../Project/Detail/Candidate-List/Candidate-List.type";

const ScoreResultOverview = () => {
  const [activeChart, setActiveChart] = useState<"bar" | "radar">("bar");
  const {
    generalScoreData,
    scoreDistributionData,
    chartData,
    chartConfig,
    candidates,
    englishLevels,
    baseType,
  } = useCandidateScoreResultVm();

  return (
    <NSScoreResultOverviewStyle.Container>
      <NSScoreResultOverviewStyle.TopContent>
        <NSScoreResultOverviewStyle.TopContentLeftSide>
          <GeneralScore data={generalScoreData} />
          <ScoreDistribution items={scoreDistributionData} />
        </NSScoreResultOverviewStyle.TopContentLeftSide>
        <NSScoreResultOverviewStyle.TopContentRightSide>
          <NSScoreResultOverviewStyle.CardHeaderDiv>
            <NSScoreResultOverviewStyle.CardHeaderInfo>
              <NSScoreResultOverviewStyle.CardHeaderTitle>
                {baseType === NSCandidateListType.ScorePageBaseType.ENGLISH_TEST
                  ? i18n.t("candidate.languageAssessment")
                  : i18n.t("candidate.competencyAssessment")}
              </NSScoreResultOverviewStyle.CardHeaderTitle>
              <NSScoreResultOverviewStyle.CardHeaderSubtitle>
                {baseType === NSCandidateListType.ScorePageBaseType.ENGLISH_TEST
                  ? i18n.t("candidate.languageAssessmentSubtitle")
                  : i18n.t("candidate.competencyAssessmentSubtitle")}
              </NSScoreResultOverviewStyle.CardHeaderSubtitle>
            </NSScoreResultOverviewStyle.CardHeaderInfo>
            <ToggleButton
              buttons={[
                {
                  id: 0,
                  label: "Bar",
                  icon: <Icon name="BarChartIcon" />,
                  onClick: () => setActiveChart("bar"),
                },
                {
                  id: 1,
                  label: "Radar",
                  icon: <Icon name="Radar" />,
                  onClick: () => setActiveChart("radar"),
                },
              ]}
            />
          </NSScoreResultOverviewStyle.CardHeaderDiv>
          {activeChart === "bar" ? (
            <BarChartHorizontal
              chartConfig={chartConfig!}
              chartData={chartData}
              englishLevels={englishLevels!}
              candidates={candidates!}
              {...({
                averageText: i18n.t("candidate.average"),
                levelText: i18n.t("candidate.level"),
              } as any)}
              isCompareActive
            />
          ) : (
            <ChartRadar
              isCompareActive
              chartConfig={chartConfig!}
              candidates={candidates!}
              englishLevels={englishLevels!}
              chartData={chartData}
              {...({
                averageText: i18n.t("candidate.average"),
                levelText: i18n.t("candidate.level"),
              } as any)}
            />
          )}
        </NSScoreResultOverviewStyle.TopContentRightSide>
      </NSScoreResultOverviewStyle.TopContent>
      {/* <NSScoreResultOverviewStyle.BottomContent>
        <NSScoreResultOverviewStyle.BottomContentLeftSide>
          <NSScoreResultOverviewStyle.CardHeaderInfo>
            <NSScoreResultOverviewStyle.CardHeaderTitle>
              {i18n.t("candidate.interviewStartTime")}
            </NSScoreResultOverviewStyle.CardHeaderTitle>
            <NSScoreResultOverviewStyle.CardHeaderSubtitle>
              <Trans
                i18nKey="candidate.interviewStartTimeText"
                values={{
                  dateRate: "20 - 27 Ağustos",
                }}
              />
            </NSScoreResultOverviewStyle.CardHeaderSubtitle>
          </NSScoreResultOverviewStyle.CardHeaderInfo>
          <ChartArea />
        </NSScoreResultOverviewStyle.BottomContentLeftSide>
        <NSScoreResultOverviewStyle.BottomContentRightSide>
          <NSScoreResultOverviewStyle.CardHeaderInfo>
            <NSScoreResultOverviewStyle.CardHeaderTitle>
              {i18n.t("candidate.interviewDuration")}
            </NSScoreResultOverviewStyle.CardHeaderTitle>
          </NSScoreResultOverviewStyle.CardHeaderInfo>
          <ChartPie chartConfig={chartPieConfig} chartData={chartPieData} />
        </NSScoreResultOverviewStyle.BottomContentRightSide>
      </NSScoreResultOverviewStyle.BottomContent> */}
    </NSScoreResultOverviewStyle.Container>
  );
};

export default ScoreResultOverview;
