import styled from "styled-components";
import { NSTableListStyle } from "../../../../components/TableList/TableList.style";
import { NSDropdownStyle } from "../../../../components/Dropdown/Dropdown.style";
import { NSContextMenuHOCStyle } from "../../../../components/ContextMenuHOC/ContextMenuHOC.style";
import MainButton from "../../../../components/MainButton/MainButton";
import { NSMainButtonStyle } from "../../../../components/MainButton/MainButton.style";

export namespace NSHeatmapStyle {
  export const TableListContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: calc(100% - 32px);
    height: 100%;

    ${NSTableListStyle.HeaderColumn} {
      min-width: unset;

      :first-child {
        width: 80px;
      }
    }

    ${NSTableListStyle.Base} {
      margin-bottom: 42px;
    }
  `;

  export const StyledIcons = styled.div`
    display: flex;
    gap: 4px;

    svg {
      cursor: pointer;
      &:hover {
        path {
          stroke: ${(props) => props.theme.colors.primary.primary};
        }
      }
    }
  `;

  export const Badge = styled.div<{ color: string; backgroundColor: string }>`
    display: flex;
    border-radius: 32px;
    padding: 8px;
    min-width: 53px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const InputsContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin: 16px 0;

    ${NSDropdownStyle.Placeholder} {
      width: 180px;
    }
  `;

  export const IconContainer = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100px;
  `;

  export const NotFoundText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: absolute;
    bottom: 0px;
    right: 66px;
    ${NSDropdownStyle.DropdownItemsContainer} {
      padding: 8px 0;
    }
  `;

  export const DownloadListButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90px;

    ${NSContextMenuHOCStyle.Container} {
      justify-content: flex-end;
    }
  `;

  export const DownloadListButton = styled(MainButton)`
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 16px 8px;
  `;

  export const SeeDetailsButton = styled.div`
    ${NSMainButtonStyle.ButtonLabel} {
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    }
  `;
}
