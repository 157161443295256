import styled from "styled-components";

export namespace NSClientListStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `;

  export const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    height: calc(100% - 170px);
    overflow: auto;
    align-content: flex-start;
  `;

  export const PaginationContainer = styled.div`
    position: absolute;
    bottom: -24px;
    right: 0;
    padding: 0 0 32px;
  `;

  export const CardContainer = styled.div`
    width: 312px;
    height: 175px;
  `;

  export const EmptyContainer = styled.div`
    width: 100%;
    height: calc(100% - 90px);
  `;

  export const ModalContainer = styled.div`
    width: 312px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const ModalButtonContainer = styled.div`
    display: flex;
    gap: 10px;
  `;
}
