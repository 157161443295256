import React from "react";
import { usePermissions } from "../../context/PermissionContext/PermissionContext";
import { NSRoleBasedGuardType } from "./RoleBasedGuard.type";
import { PermissionsEnum } from "../../permissions/roles";

export const RoleBasedGuard: React.FC<
  NSRoleBasedGuardType.RoleBasedGuardProps
> = ({ permissionKey, fallback = null, children }) => {
  const permissions = usePermissions();

  if (!permissions.hasPermission(permissionKey as PermissionsEnum)) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
