import DropdownWithCaption from "../../../../components/DropdownWithCaption/DropdownWithCaption";
import Loading from "../../../../components/Loading/Loading";
import Pagination from "../../../../components/Pagination/Pagination";
import { RoleBasedGuard } from "../../../../components/RoleBasedGuard/RoleBasedGuard";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import TableList from "../../../../components/TableList/TableList";
import i18n from "../../../../library/i18next";
import { PermissionsEnum } from "../../../../permissions/roles";
import { PAGINATION_DEFAULT_LIMIT } from "../../../../utils/globalConstants";
import { NSHeatmapStyle } from "./Heatmap.style";
import useHeatmapVM from "./HeatmapVM";

const Heatmap = () => {
  const {
    heatmapTableData,
    headerItemList,
    interviewTypeOptions,
    defaultInterviewType,
    isLoading,
    searchString,
    pagination,
    totalItemCount,
    orderOptions,
    defaultSortOption,
    handleSortSelect,
    handleSetPagination,
    setSearchString,
    handleInterviewTypeSelect,
  } = useHeatmapVM();

  return (
    <NSHeatmapStyle.TableListContainer>
      <NSHeatmapStyle.InputsContainer>
        <RoleBasedGuard permissionKey={PermissionsEnum.SEARCH_CANDIDATES}>
          <SearchInput
            placeholder={i18n.t("projects.searchCandidate")}
            id={"search"}
            name={"search"}
            onChange={(value: string) => {
              setSearchString(value);
            }}
            value={searchString}
          />
        </RoleBasedGuard>
        <DropdownWithCaption
          prefixTitle={i18n.t("projects.interviewType")}
          id={1}
          options={interviewTypeOptions}
          type={"basic"}
          defaultCheckedItems={[defaultInterviewType]}
          selectedDropdownItem={defaultInterviewType}
          onSelect={handleInterviewTypeSelect}
        />
        <RoleBasedGuard permissionKey={PermissionsEnum.SORT_HEATMAP_CANDIDATES}>
          <DropdownWithCaption
            prefixTitle={i18n.t("projects.sortBy")}
            id={1}
            options={orderOptions}
            type={"basic"}
            defaultCheckedItems={[defaultSortOption]}
            selectedDropdownItem={defaultSortOption}
            onSelect={handleSortSelect}
          />
        </RoleBasedGuard>
      </NSHeatmapStyle.InputsContainer>
      {isLoading ? (
        <Loading />
      ) : heatmapTableData && heatmapTableData.length > 0 ? (
        <>
          <TableList
            tableItemList={heatmapTableData}
            headerItemList={headerItemList}
          />
          {totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
            <NSHeatmapStyle.PaginationContainer>
              <Pagination
                pagination={pagination}
                isLoading={isLoading}
                itemCount={totalItemCount}
                updateQueryLimit={(start, limit) =>
                  handleSetPagination(start, limit)
                }
              />
            </NSHeatmapStyle.PaginationContainer>
          )}
        </>
      ) : (
        <NSHeatmapStyle.NotFoundText>
          {i18n.t("general.noRecord")}
        </NSHeatmapStyle.NotFoundText>
      )}
    </NSHeatmapStyle.TableListContainer>
  );
};

export default Heatmap;
