import styled from "styled-components";
import { NSContextMenuStyle } from "../ContextMenu/ContextMenu.style";

export namespace NSClientInfoCardStyle {
  export const Container = styled.div`
    font-family: greycliff-cf, sans-serif;
    background-color: ${(props) => props.theme.colors.light.light};
    width: 100%;
    height: 100%;
    padding: 22px 10px 22px 22px;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;
    border: 2px solid ${(props) => props.theme.colors.light.light};
    :hover {
      border-color: ${(props) => props.theme.colors.primary.primary};
    }
    ${NSContextMenuStyle.ContextMenuContent} {
      right: 8px;
      left: unset;
      top: 36px;
    }
  `;

  export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const InnerText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    margin-bottom: 4px;
  `;

  export const HeaderLeft = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const DepartmentManager = styled.div``;

  export const DepartmentManagerTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    margin-bottom: 4px;
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const Location = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-top: 28px;
    height: 100%;
    align-items: flex-end;
  `;

  export const DefaultBadge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    padding: 5.5px 12px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 8px;
  `;
}
