/* c8 ignore start */
const typography = {
  titleh1: {
    size: "4rem",
    lineHeight: "110%",
  },
  titleh2: {
    size: "3rem",
    lineHeight: "110%",
  },
  titleh3: {
    size: "2rem",
    lineHeight: "118%",
  },
  titleh4: {
    size: "1.5rem",
    lineHeight: "130%",
  },
  pharagraph1: {
    size: "1.25rem",
    lineHeight: "130%",
  },
  pharagraph2: {
    size: "1rem",
    lineHeight: "130%",
  },
  smallText1: {
    size: "0.875rem",
    lineHeight: "130%",
  },
  smallText2: {
    size: "0.75rem",
    lineHeight: "132%",
  },
  preText: {
    size: "0.625rem",
    lineHeight: "132%",
  },
  fontWeights: {
    regular: "400",
    medium: "500",
    demiBold: "600",
    bold: "700",
  },
};

export default typography;
/* c8 ignore stop */
