import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { NSTabbedViewStyle } from "./TabbedView.style";
import { NSTabbedViewType } from "./TabbedView.type";

function TabbedView({
  tabItems,
  activeTab,
  onTabChange,
  useDropdown,
  dropdownOptions = [],
  selectedDropdownOption,
  onSelectDropdownOption,
}: NSTabbedViewType.ITabbedView) {
  const onClickTab = (id: number) => {
    onTabChange?.(id);
  };

  return (
    <NSTabbedViewStyle.Container>
      <NSTabbedViewStyle.HeaderContainer>
        {tabItems.map((headerItem) => (
          <NSTabbedViewStyle.Header key={headerItem.id}>
            {
              <NSTabbedViewStyle.HeaderItem
                selected={activeTab === headerItem.id}
                onClick={() => {
                  !(headerItem.isDropdown && useDropdown) &&
                    onClickTab(headerItem.id);
                }}
              >
                {headerItem.isDropdown && useDropdown ? (
                  <Dropdown
                    id={1}
                    type="basic"
                    options={dropdownOptions ?? []}
                    onSelect={onSelectDropdownOption}
                    selectedDropdownItem={selectedDropdownOption}
                    shouldBehaveLikeMenu
                  >
                    <NSTabbedViewStyle.DropdownLabel>
                      {headerItem.label} <Icon name="ChevronDown" />
                    </NSTabbedViewStyle.DropdownLabel>
                  </Dropdown>
                ) : (
                  headerItem.label
                )}
              </NSTabbedViewStyle.HeaderItem>
            }
            {headerItem.hasSeperator && (
              <NSTabbedViewStyle.Separator key="tab-items-separator" />
            )}
          </NSTabbedViewStyle.Header>
        ))}
      </NSTabbedViewStyle.HeaderContainer>
    </NSTabbedViewStyle.Container>
  );
}

export default TabbedView;
