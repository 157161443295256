import { useLocation, useNavigate } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { useMemo, useState } from "react";
import DashboardMenuSidebar from "../../components/DashboardMenuSidebar/DashboardMenuSidebar";
import { NSContextMenuType } from "../../components/ContextMenu/ContextMenu.type";
import { NSDashboardLayoutStyle } from "./DashboardLayout.style";
import { NSDashboardLayoutType } from "./DashboardLayout.type";
import { NSSideMenuItemType } from "../../components/SideMenuItem/SideMenuItem.type";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import i18n from "../../library/i18next";
import { usePermissions } from "../../context/PermissionContext/PermissionContext";
import { PermissionsEnum } from "../../permissions/roles";
import icons from "../../assets/icons";

function DashboardLayout({
  children,
  isOpen = true,
  user,
}: NSDashboardLayoutType.IDashboardLayout) {
  const { logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navbarTopItems: NSSideMenuItemType.ISideMenuItem[] = [
    {
      icon: "Project",
      name: i18n.t("general.projects"),
      path: "projects",
    },
    {
      icon: "News",
      name: i18n.t("general.jds"),
      path: "job-description",
    },
    ...(permissions.hasPermission(PermissionsEnum.VIEW_DEPARTMENT)
      ? [
          {
            icon: "Layer" as keyof typeof icons,
            name: i18n.t("general.departments"),
            path: "clients",
          },
        ]
      : []),
    {
      icon: "Users",
      name: i18n.t("general.team"),
      path: "team",
    },
  ];

  const navbarBottomItems: NSSideMenuItemType.ISideMenuItem[] = [
    ...(permissions.hasPermission(PermissionsEnum.SUPPORT)
      ? [
          {
            icon: "Help" as keyof typeof icons,
            name: i18n.t("general.support"),
            path: "mailto:info@hiringcycle.ai",
          },
        ]
      : []),
  ];

  const contextMenu: NSContextMenuType.IContextMenu = {
    type: "basic",
    onContextMenuOpen: (value) => setIsMenuOpen(value),
    items: [
      ...(permissions.hasPermission(PermissionsEnum.CREATE_PROJECT)
        ? [
            {
              label: i18n.t("general.createNewProject"),
              onClick: () => {
                navigate("/projects/create");
              },
            },
          ]
        : []),
      ...(permissions.hasPermission(PermissionsEnum.CREATE_JOB_POSTING)
        ? [
            {
              label: i18n.t("general.createJD"),
              onClick: () => {
                navigate("/job-description/create");
              },
            },
          ]
        : []),
      ...(permissions.hasPermission(PermissionsEnum.CREATE_DEPARTMENT)
        ? [
            {
              label: i18n.t("general.createNewDepartment"),
              onClick: () => {
                navigate("/clients/create");
              },
            },
          ]
        : []),
    ],
  };

  const closedPaths = useMemo(() => ["/projects/detail", "sdfsdf"], []);

  const isIncludeClosedPaths = useMemo(
    () => closedPaths.map((i) => location.pathname.includes(i)),
    [closedPaths, location.pathname]
  );

  return (
    <NSDashboardLayoutStyle.Container>
      <NSDashboardLayoutStyle.LeftContent
        isOpen={isIncludeClosedPaths.some((i) => i === true) ? false : isOpen}
      >
        <DashboardMenuSidebar
          activePath={location.pathname.split("/")[1]}
          onClickLogout={logout}
          isDashboardOpen={
            isIncludeClosedPaths.some((i) => i === true) ? false : isOpen
          }
          navbarTopItems={navbarTopItems}
          navbarBottomItems={navbarBottomItems}
          user={user!}
          contextMenu={contextMenu}
        />
      </NSDashboardLayoutStyle.LeftContent>
      <NSDashboardLayoutStyle.RightContent>
        {children}
        <Toaster position="bottom-right" />
      </NSDashboardLayoutStyle.RightContent>
      {isMenuOpen && <NSDashboardLayoutStyle.BGBlur />}
    </NSDashboardLayoutStyle.Container>
  );
}

export default DashboardLayout;
