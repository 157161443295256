import React, { createContext, useContext, useMemo, useState } from "react";
import { CandidateScoreResultContextType } from "./CandidateScoreResultContext.type";
import i18n from "../../../library/i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NSCandidateListType } from "../../../routes/Project/Detail/Candidate-List/Candidate-List.type";
import { useGetRecruitmentFormDetailByCcpId } from "../../../service/RecruitmentForm/getRecruitmentFormDetail";
import { useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetVideoInterviewByIdsDocument,
  Recruitment_Form_Question_Type_Enum,
} from "../../../gql/graphql";
import dayjs from "dayjs";
import { NSRecruitmentFormReportCardType } from "../../../routes/Candidate/Recruitment-Form-Report/RecruitmentFormReportCard/RecruitmentFormReportCard.type";

const CandidateScoreResultContext = createContext<
  CandidateScoreResultContextType | undefined
>(undefined);

export const CandidateScoreResultProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { baseType, projectId, interviewId } = useParams();
  const {
    secondInterviewId,
    hasCameFromHeatmap,
    selectedInterviewType,
    selectedOrder,
  } = location.state || {};
  const linkToTab: { [key: string]: number } = useMemo(
    () => ({
      overview: 1,
      details: 2,
    }),
    []
  );

  const getActiveTab = () => {
    const urlArray = window.location.href.split("/");
    const activeUrl = urlArray[urlArray.length - 1];
    return linkToTab[activeUrl] ?? 1;
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());
  const { data: videoInterviewData, isLoading } = useGraphQLQuery(
    GetVideoInterviewByIdsDocument,
    {},
    {
      ids: secondInterviewId ? [interviewId, secondInterviewId] : [interviewId],
    }
  );

  const clientCandidateProjectId =
    videoInterviewData?.video_interview?.[0]?.client_candidate_project.id;
  const { data: recruitmentFormData } = useGetRecruitmentFormDetailByCcpId(
    clientCandidateProjectId!,
    {
      enabled: !!clientCandidateProjectId,
    }
  );

  const hasRecruitmentForm =
    !!recruitmentFormData?.client_candidate_project?.[0]
      ?.recruitment_form_submissions?.length;

  const dropdownOptions = [
    {
      label:
        baseType === NSCandidateListType.ScorePageBaseType.ENGLISH_TEST
          ? i18n.t("projects.englishTest")
          : i18n.t("projects.competencyInterview"),
      id: 1,
    },
    ...(recruitmentFormData?.client_candidate_project?.[0]?.recruitment_form_submissions?.map(
      (submission, index) => ({
        label: submission?.recruitment_form?.title || `Form ${index + 1}`,
        id: index + 2,
      })
    ) || []),
  ];

  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);
  const [recruitmentFormScores, setRecruitmentFormScores] = useState<
    NSRecruitmentFormReportCardType.CalculatedScore[]
  >([]);

  const onSelectDropdownOption = (_index: number, item: any) => {
    setSelectedOption(item);
    setActiveTab(_index + 1);
    const baseUrl = `/projects/detail/${projectId}/candidate/candidate-score-result/${interviewId}/${baseType}/details`;

    const submission =
      recruitmentFormData?.client_candidate_project?.[0]?.recruitment_form_submissions?.find(
        (_sub, index) => index + 1 === item.id - 1
      );

    if (submission) {
      const calculatedScores =
        submission.recruitment_form.recruitment_form_questions.map(
          (question) => {
            const submissionAnswers =
              submission.recruitment_form_submission_answers.filter(
                (answer) => answer.recruitment_form_question_id === question.id
              );

            let answerValue = "";

            const selectedChoiceIds = submissionAnswers
              .filter(
                (answer) => answer.recruitment_form_question_choice_id !== null
              )
              .map((answer) => answer.recruitment_form_question_choice_id);

            submissionAnswers.forEach((answer) => {
              if (answer.recruitment_form_question_choice_id === null) {
                answerValue = answer.value!;
              }
            });

            const scoreInfo =
              submission.recruitment_form_submission_question_scores.find(
                (score) => score.recruitment_form_question_id === question.id
              );
            const weightedScore = scoreInfo ? scoreInfo.weighted_score : 0;

            const selectedChoices =
              question.recruitment_form_question_choices.reduce<
                {
                  choice_text: string;
                  id: string;
                  order_index: number;
                  score: number;
                }[]
              >((acc, choice) => {
                if (selectedChoiceIds.includes(choice.id)) {
                  acc.push({
                    choice_text: choice.choice_text,
                    id: String(choice.id),
                    order_index: choice.order_index,
                    score: choice.score,
                  });
                }
                return acc;
              }, []);

            const formattedValue =
              question.form_question_type ===
              Recruitment_Form_Question_Type_Enum.Date
                ? dayjs(answerValue).format("DD/MM/YYYY")
                : answerValue;

            return {
              id: question.id,
              questionTitle: question.title,
              weight: question.weight,
              totalScore: weightedScore,
              value: formattedValue || String(weightedScore),
              choices: selectedChoices,
              required: question.required,
              isScored:
                question.required &&
                selectedChoices.length > 0 &&
                question.weight !== 0,
              failed: false,
            };
          }
        );
      setRecruitmentFormScores(calculatedScores);
    }

    navigate(baseUrl, {
      replace: true,
      state: {
        projectId,
        interviewId,
        secondInterviewId,
        hasCameFromHeatmap,
        selectedInterviewType,
        selectedOrder,
      },
    });
  };

  return (
    <CandidateScoreResultContext.Provider
      value={{
        baseType,
        selectedOption,
        dropdownOptions,
        hasRecruitmentForm,
        videoInterviewData,
        isLoading,
        recruitmentFormScores,
        activeTab,
        setActiveTab,
        getActiveTab,
        onSelectDropdownOption,
      }}
    >
      {children}
    </CandidateScoreResultContext.Provider>
  );
};

export const useCandidateScoreResultContext =
  (): CandidateScoreResultContextType => {
    const context = useContext(CandidateScoreResultContext);
    if (!context) {
      throw new Error("please use in CandidateScoreResultProvider");
    }
    return context;
  };
