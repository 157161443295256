import { GenerateInterviewReportDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useGenerateInterviewReport() {
  const { mutateAsync, ...rest } = useGraphQLMutation(
    GenerateInterviewReportDocument
  );

  return {
    mutateAsync,
    rest,
  };
}

export default useGenerateInterviewReport;
