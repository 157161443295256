import styled from "styled-components";
import { NSVideoPlayerStyle } from "../../../components/VideoPlayer/VideoPlayer.style";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSCandidateVideoPreviewStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 32px 28px;
    overflow-y: auto;

    ${NSVideoPlayerStyle.Container} {
      top: 215px;
    }
  `;

  export const ContentContainer = styled.div`
    width: 1100px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `;

  export const NameContainer = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 8px;
  `;

  export const Description = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    width: 500px;
    text-align: center;
  `;

  export const VideoContainer = styled.div`
    flex: 1;
    height: 490px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 45px;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  `;

  export const LeftButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 27px;

    ${NSMainButtonStyle.ButtonLabel} {
      color: ${(props) => props.theme.colors.error.red01};
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    }

    ${NSMainButtonStyle.IconContainer} {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.error.red01};
        }
      }
    }
  `;
}
