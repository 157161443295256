import styled, { css } from "styled-components";

export namespace NSStatsOverviewLabel {
  const colorStyles = {
    default: css`
      background: ${({ theme }) => theme.colors.neutrals.grey02};
      color: ${({ theme }) => theme.colors.primary.primary};
    `,
    green: css`
      background: ${({ theme }) => theme.colors.tertiary.light};
      color: ${({ theme }) => theme.colors.tertiary.dark};
    `,
  };

  export const Label = styled.span<{ color?: "default" | "green" }>`
    padding: 4px 12px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
    min-width: 72px;
    font-size: ${({ theme }) => theme.typography.pharagraph1.size};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: ${({ theme }) => theme.typography.pharagraph1.lineHeight};
    ${({ color = "default" }) => colorStyles[color]}
  `;
}
