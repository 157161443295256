import LinkButton from "../../../components/LinkButton/LinkButton";
import Logo from "../../../components/Logo/Logo";
import Modal2 from "../../../components/Modal/Modal2";
import { NSCandidateVideoPreviewStyle } from "./Candidate-Video-Preview.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import useCandidateVideoPreviewVm from "./Candidate-Video-PreviewVM";
import i18n from "../../../library/i18next";

function CandidateVideoPreview() {
  const candidateVideoPreviewVM = useCandidateVideoPreviewVm();

  return (
    <Modal2 onClose={candidateVideoPreviewVM.closeHandler} isFullWidth>
      <NSCandidateVideoPreviewStyle.Container>
        <Logo type="onlyIcon" />
        <NSCandidateVideoPreviewStyle.ContentContainer>
          <NSCandidateVideoPreviewStyle.NameContainer>
            {candidateVideoPreviewVM.candidateName}
          </NSCandidateVideoPreviewStyle.NameContainer>
          <NSCandidateVideoPreviewStyle.Description>
            {candidateVideoPreviewVM.description}
          </NSCandidateVideoPreviewStyle.Description>
          <NSCandidateVideoPreviewStyle.VideoContainer>
            <VideoPlayer
              playlistData={candidateVideoPreviewVM.videoFileInfos}
              urls={
                candidateVideoPreviewVM.videoData?.url
                  ? [candidateVideoPreviewVM.videoData?.url]
                  : []
              }
              accessToken={candidateVideoPreviewVM.videoData?.accessToken ?? ""}
              authToken={candidateVideoPreviewVM.videoData?.authToken ?? ""}
              title={candidateVideoPreviewVM.candidateName}
              isLoading={
                !(
                  candidateVideoPreviewVM.videoData &&
                  candidateVideoPreviewVM.videoData?.url
                )
              }
              error={candidateVideoPreviewVM.error}
              onSelectVideo={candidateVideoPreviewVM.onSelectVideo}
            />
          </NSCandidateVideoPreviewStyle.VideoContainer>

          <NSCandidateVideoPreviewStyle.ButtonContainer>
            <NSCandidateVideoPreviewStyle.LeftButtons>
              <LinkButton
                prefixIcon="VideoOff"
                variant="label-w-icon-prefix"
                label={i18n.t("candidate.insufficientContent")}
                onClick={candidateVideoPreviewVM.onClickInsufficientContent}
              />

              <LinkButton
                prefixIcon="CommunicationDislike"
                variant="label-w-icon-prefix"
                label={i18n.t("candidate.inappropriateContent")}
                onClick={candidateVideoPreviewVM.onClickInappropriateContent}
              />
            </NSCandidateVideoPreviewStyle.LeftButtons>

            <SolidButton
              label={i18n.t("candidate.startEvaluating")}
              onClick={candidateVideoPreviewVM.onClickStartButton}
            />
          </NSCandidateVideoPreviewStyle.ButtonContainer>
        </NSCandidateVideoPreviewStyle.ContentContainer>
      </NSCandidateVideoPreviewStyle.Container>
    </Modal2>
  );
}

export default CandidateVideoPreview;
