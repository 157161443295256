import { Roles } from "../permissions/roles";
import { ACCESS_TOKEN, LOGIN_USER_INFO } from "./globalConstants";

export const getMappedRole = (userRole: string): Roles => {
  if (!Object.values(Roles).includes(userRole as unknown as Roles)) {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(LOGIN_USER_INFO);
  }
  return userRole as unknown as Roles;
};
